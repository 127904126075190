/* tslint:disable */
/* eslint-disable */
/**
 * revii APIs
 * revii API in the OpenAPI 3.0.3 specification
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@zenkigen.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";

