import { css, CSSObject, FlattenSimpleInterpolation, SimpleInterpolation } from 'styled-components';

export const media = {
  sp: (sp: TemplateStringsArray, ...interpolations: SimpleInterpolation[]): FlattenSimpleInterpolation => css`
    @media (max-width: 932px) {
      ${css(sp, ...interpolations)}
    }
  `,
  tb: (
    tb: CSSObject | TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ): FlattenSimpleInterpolation => css`
    @media (max-width: 1280px) {
      ${css(tb, ...interpolations)}
    }
  `,
};
