import React, { CSSProperties, MutableRefObject, ReactNode } from 'react';

import { rgba } from 'polished';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { IconName, iconElements } from '@Icon';

import { Icon } from '@/components/shared/Icon';
import {
  BorderRadius,
  ColorToken,
  ColorTokens,
  StyleShadow,
  TransitionDurations,
  TransitionEasings,
  TransitionSpeeds,
  TypographyTokens,
  space,
  square,
  ZIndexes,
} from '@/styles';
import { media } from '@/styles/media';

type WidthType = 'small' | 'regular-small' | 'regular' | 'regular-wide' | 'wide' | 'auto';

type HeightType = 'fit' | 'fullscreen';

export type SizeProps = {
  width: WidthType;
  height: HeightType;
};

export type CommonModalProps = {
  children?: ReactNode | ReactNode[];
  isOpen: boolean;
  setIsOpen?: (newValue: boolean) => void;
  keepOpen?: boolean;
  title?: ReactNode | ReactNode[];
  titleColor?: ColorToken;
  titleIcon?: IconName;
  titleIconColor?: ColorToken;
  showTopSeparationLine?: boolean;
  onModal?: boolean;
  size?: Partial<SizeProps>;
  maxHeight?: CSSProperties['height'];
  positionCss?: FlattenSimpleInterpolation;
  afterClosing?: () => void;
  portalTargetRef?: MutableRefObject<HTMLElement | null>;
  disableCloseButton?: boolean;
  headerContent?: ReactNode;
};

type Props = CommonModalProps &
  Partial<{
    titleBackground: ColorToken;
    titleColor: ColorToken;
    titleIcon: IconName;
    titleIconColor: ColorToken;
    centerTitle: boolean;
    baseBackground: 'black' | 'gray';
    noPaddingBottom: boolean;
  }>;

export const BasicModal = ({
  isOpen,
  setIsOpen,
  keepOpen = false,
  title,
  titleBackground,
  titleColor,
  titleIcon,
  titleIconColor,
  centerTitle = false,
  noPaddingBottom = false,
  children,
  afterClosing,
  showTopSeparationLine = true,
  onModal = false,
  baseBackground = 'gray',
  size,
  maxHeight,
  positionCss,
  portalTargetRef,
  disableCloseButton,
  headerContent,
}: Props) => {
  const handleClickCloseButton = () => {
    setIsOpen?.(false);
    if (afterClosing != null) {
      afterClosing();
    }
  };

  return createPortal(
    <Transition in={isOpen} timeout={{ enter: 0, exit: TransitionDurations.Slow }} unmountOnExit={!keepOpen}>
      {(state: TransitionStatus) => (
        <StyledModalBase aboveModal={onModal} baseBackground={baseBackground} state={state} positionCss={positionCss}>
          <StyledModalContent
            size={size}
            hasTitle={title != null}
            hasRef={portalTargetRef != null}
            state={state}
            maxHeight={maxHeight}
            noPaddingBottom={noPaddingBottom}
          >
            {title != null && (
              <StyledHeader
                showTopSeparationLine={showTopSeparationLine}
                centerTitle={centerTitle}
                titleBackground={titleBackground}
                titleColor={titleColor}
              >
                <StyledTitle hasHeaderContent={!!headerContent}>
                  {titleIcon !== undefined && (
                    <StyledTitleIcon titleIconColor={titleIconColor}>{iconElements[titleIcon]}</StyledTitleIcon>
                  )}
                  {title}
                </StyledTitle>
                {headerContent}
                {!disableCloseButton && (
                  <StyledIcon titleColor={titleColor}>
                    <Icon name="close" onClick={handleClickCloseButton} size="Large" />
                  </StyledIcon>
                )}
              </StyledHeader>
            )}
            <StyledBody>{children}</StyledBody>
          </StyledModalContent>
        </StyledModalBase>
      )}
    </Transition>,
    portalTargetRef == null || portalTargetRef.current == null ? document.body : portalTargetRef.current,
  );
};

export const BaseModalGutter = space(4);

const BaseTransition = `${TransitionSpeeds.Regular} ${TransitionEasings.Enter}`;

const StyledModalBase = styled.div<
  { aboveModal: boolean; state: TransitionStatus } & Pick<Props, 'baseBackground' | 'positionCss'>
>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  z-index: ${ZIndexes.Overlay};
  transition: opacity ${BaseTransition};
  ${({ baseBackground, positionCss }) =>
    positionCss == null
      ? css`
          ${square('100%')}
          position: fixed;
          top: 0;
          left: 0;
          background-color: ${baseBackground === 'black'
            ? rgba(0, 0, 0, 0.8)
            : rgba(ColorTokens.UiBackground01Dark, 0.9)};
        `
      : css`
          ${positionCss}
          box-shadow: ${StyleShadow.DropShadow24};
        `}
  ${({ state }) =>
    state === 'entered' &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
`;

const StyledModalContent = styled.div.attrs<
  { state: TransitionStatus; hasTitle: boolean; hasRef: boolean } & Pick<
    Props,
    'size' | 'maxHeight' | 'noPaddingBottom' | 'children'
  >
>(props => {
  let width;
  const spWidth = '320px';

  switch (props.size?.width) {
    case 'small':
      width = '320px';
      break;
    case 'regular-small':
      width = '480px';
      break;
    case 'regular':
      width = '640px';
      break;
    case 'regular-wide':
      width = '720px';
      break;
    case 'wide':
      width = '840px';
      break;
    case 'auto':
      width = 'auto';
      break;
    default:
      width = '640px';
      break;
  }

  return { width, spWidth, ...props };
})<
  { state: TransitionStatus; hasTitle: boolean; hasRef: boolean; width?: string; spWidth?: string } & Pick<
    Props,
    'size' | 'maxHeight' | 'noPaddingBottom' | 'children'
  >
>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: ${({ maxHeight }) => maxHeight ?? '80%'};
  overflow: hidden;
  background: ${ColorTokens.UiBackground01};
  border-radius: ${BorderRadius.Double};
  transition: transform ${BaseTransition};
  transform: translate3d(0, -24px, 0);
  height: ${({ size, maxHeight }) => (size?.height === 'fullscreen' ? '80%' : maxHeight ?? 'auto')};
  width: ${({ hasRef, width }) => (hasRef ? '100%' : width)};

  ${({ hasTitle, noPaddingBottom }) => css`
    padding-top: ${hasTitle ? 0 : BaseModalGutter};
    padding-bottom: ${noPaddingBottom === true ? 0 : BaseModalGutter};
  `}

  ${({ state }) =>
    state === 'entered' &&
    css`
      transform: translate3d(0, 0, 0);
    `}

    ${({ spWidth, maxHeight, size }) =>
    media.sp`
      width: ${spWidth};
      max-height: ${maxHeight ?? '90%'};
      height: ${size?.height === 'fullscreen' ? '90%' : maxHeight ?? 'auto'};
    `}
`;

const StyledBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ${TypographyTokens.Body1}
  color: ${ColorTokens.Text02};
  white-space: pre-wrap;
`;

const StyledHeader = styled.div<{
  showTopSeparationLine: boolean;
  centerTitle: boolean;
  titleBackground: ColorToken | undefined;
  titleColor: ColorToken | undefined;
}>`
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  padding: ${space(4)} ${space(6)};
  ${TypographyTokens.Label1}
  ${props =>
    props.centerTitle &&
    css`
      justify-content: center;
      text-align: center;
    `}
  color: ${props => (props.titleColor != null ? ColorTokens[props.titleColor] : ColorTokens.Text01)};
  background-color: ${props =>
    props.titleBackground != null ? ColorTokens[props.titleBackground] : ColorTokens.UiBackground01};
  ${props =>
    props.showTopSeparationLine !== false
      ? css`
          border-bottom: 1px solid ${ColorTokens.UiBorder01};
        `
      : null}
`;

const StyledTitle = styled.div<{ hasHeaderContent: boolean }>`
  ${({ hasHeaderContent }) =>
    hasHeaderContent &&
    css`
      margin-right: ${space(8)};
    `}
`;

const StyledTitleIcon = styled.span<{ titleIconColor: ColorToken | undefined }>`
  & svg {
    ${square(24)}
    margin-right: ${space(2)};
    fill: ${props => (props.titleIconColor != null ? ColorTokens[props.titleIconColor] : ColorTokens.Icon01)};
  }
`;

const StyledIcon = styled.span<{ titleColor: ColorToken | undefined }>`
  margin-left: auto;
  svg {
    fill: ${props => (props.titleColor != null ? ColorTokens[props.titleColor] : ColorTokens.Icon02)};
  }
  &:hover {
    cursor: pointer;
    background-color: ${ColorTokens.HoverUi};
    border-radius: ${BorderRadius.Default};
  }
`;
