/* tslint:disable */
/* eslint-disable */
/**
 * revii APIs
 * revii API in the OpenAPI 3.0.3 specification
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@zenkigen.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {number}
     * @memberof Account
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    updated_at: string;
    /**
     * 
     * @type {User}
     * @memberof Account
     */
    user?: User | null;
    /**
     * 
     * @type {Admin}
     * @memberof Account
     */
    admin?: Admin | null;
}
/**
 * 
 * @export
 * @interface ActionCsvImportError
 */
export interface ActionCsvImportError {
    /**
     * 
     * @type {ActionCsvImportErrorAttributes}
     * @memberof ActionCsvImportError
     */
    attributes: ActionCsvImportErrorAttributes;
    /**
     * 
     * @type {string}
     * @memberof ActionCsvImportError
     */
    messages: string;
    /**
     * 
     * @type {string}
     * @memberof ActionCsvImportError
     */
    keys?: string;
}
/**
 * 
 * @export
 * @interface ActionCsvImportErrorAttributes
 */
export interface ActionCsvImportErrorAttributes {
    /**
     * 
     * @type {string}
     * @memberof ActionCsvImportErrorAttributes
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvImportErrorAttributes
     */
    conversation_tendency?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvImportErrorAttributes
     */
    listening_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvImportErrorAttributes
     */
    approval_grade?: number;
}
/**
 * 
 * @export
 * @interface ActionCsvParseResult
 */
export interface ActionCsvParseResult {
    /**
     * 
     * @type {number}
     * @memberof ActionCsvParseResult
     */
    status: number;
    /**
     * 
     * @type {ActionCsvParseResultData}
     * @memberof ActionCsvParseResult
     */
    data: ActionCsvParseResultData;
    /**
     * 
     * @type {string}
     * @memberof ActionCsvParseResult
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ActionCsvParseResultData
 */
export interface ActionCsvParseResultData {
    /**
     * 
     * @type {Array<ActionCsvImportError>}
     * @memberof ActionCsvParseResultData
     */
    errors: Array<ActionCsvImportError>;
    /**
     * 
     * @type {ActionCsvParseResultDataCount}
     * @memberof ActionCsvParseResultData
     */
    count: ActionCsvParseResultDataCount;
}
/**
 * 
 * @export
 * @interface ActionCsvParseResultDataCount
 */
export interface ActionCsvParseResultDataCount {
    /**
     * 
     * @type {number}
     * @memberof ActionCsvParseResultDataCount
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvParseResultDataCount
     */
    updated?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvParseResultDataCount
     */
    deleted?: number;
}
/**
 * 
 * @export
 * @interface ActionCsvPostRequest
 */
export interface ActionCsvPostRequest {
    /**
     * 
     * @type {Array<ActionCsvRow>}
     * @memberof ActionCsvPostRequest
     */
    rows: Array<ActionCsvRow>;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvPostRequest
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvPostRequest
     */
    company_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionCsvPostRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ActionCsvRow
 */
export interface ActionCsvRow {
    /**
     * 
     * @type {string}
     * @memberof ActionCsvRow
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvRow
     */
    conversation_tendency?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvRow
     */
    approval_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvRow
     */
    listening_grade?: number;
}
/**
 * 
 * @export
 * @interface ActionCsvValidationRequest
 */
export interface ActionCsvValidationRequest {
    /**
     * 
     * @type {Array<ActionCsvRow>}
     * @memberof ActionCsvValidationRequest
     */
    rows: Array<ActionCsvRow>;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvValidationRequest
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActionCsvValidationRequest
     */
    company_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActionCsvValidationRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    account_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    updated_at: string;
    /**
     * 
     * @type {Account}
     * @memberof Admin
     */
    account: Account;
}
/**
 * 
 * @export
 * @interface AdminCompanyUsersResponse
 */
export interface AdminCompanyUsersResponse {
    /**
     * 
     * @type {Array<IndexUsersResponseUsers>}
     * @memberof AdminCompanyUsersResponse
     */
    users: Array<IndexUsersResponseUsers>;
}
/**
 * 
 * @export
 * @interface AdminCreateRequest
 */
export interface AdminCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateRequest
     */
    email: string;
}
/**
 * 
 * @export
 * @interface AdminInterviewResponse
 */
export interface AdminInterviewResponse {
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    recording_id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    transcoder_status: string;
    /**
     * 
     * @type {InterviewReportStatus}
     * @memberof AdminInterviewResponse
     */
    report_status: InterviewReportStatus;
    /**
     * 
     * @type {AdminInterviewResponseRoom}
     * @memberof AdminInterviewResponse
     */
    room?: AdminInterviewResponseRoom;
    /**
     * 
     * @type {Array<UserInterview>}
     * @memberof AdminInterviewResponse
     */
    user_interviews: Array<UserInterview>;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    analysis_finished_at?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    duration_time?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    interview_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    interview_finished_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponse
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface AdminInterviewResponseRoom
 */
export interface AdminInterviewResponseRoom {
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoom
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoom
     */
    invitation_code: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoom
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoom
     */
    updated_at?: string;
    /**
     * 
     * @type {AdminInterviewResponseRoomCurrentInterview}
     * @memberof AdminInterviewResponseRoom
     */
    current_interview?: AdminInterviewResponseRoomCurrentInterview;
    /**
     * 
     * @type {Array<AdminInterviewResponseRoomCurrentUserInterviews>}
     * @memberof AdminInterviewResponseRoom
     */
    current_user_interviews: Array<AdminInterviewResponseRoomCurrentUserInterviews>;
    /**
     * 
     * @type {User}
     * @memberof AdminInterviewResponseRoom
     */
    user: User;
    /**
     * 
     * @type {RoomLiveServer}
     * @memberof AdminInterviewResponseRoom
     */
    room_live_server?: RoomLiveServer | null;
}
/**
 * V1::InterviewArchiveSerializer
 * @export
 * @interface AdminInterviewResponseRoomCurrentInterview
 */
export interface AdminInterviewResponseRoomCurrentInterview {
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    id: number;
    /**
     * 
     * @type {InterviewStatus}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    status: InterviewStatus;
    /**
     * 
     * @type {InterviewTranscoderStatus}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    transcoder_status: InterviewTranscoderStatus;
    /**
     * 
     * @type {InterviewReportStatus}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    report_status: InterviewReportStatus;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    recording_id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    interview_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    interview_finished_at: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    owner_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    guest_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    aggression_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    openness_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    approval_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    listening_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    guest_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    owner_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    owner_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    owner_mask_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    guest_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    guest_mask_rate: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    advice_text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInterviewResponseRoomCurrentInterview
     */
    status_text?: string | null;
}
/**
 * 
 * @export
 * @interface AdminInterviewResponseRoomCurrentUserInterviews
 */
export interface AdminInterviewResponseRoomCurrentUserInterviews {
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentUserInterviews
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentUserInterviews
     */
    interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof AdminInterviewResponseRoomCurrentUserInterviews
     */
    user_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof AdminInterviewResponseRoomCurrentUserInterviews
     */
    before_survey_completed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminInterviewResponseRoomCurrentUserInterviews
     */
    after_survey_completed: boolean;
}
/**
 * 
 * @export
 * @interface AdminInterviews
 */
export interface AdminInterviews {
    /**
     * 
     * @type {Array<AdminInterviewResponse>}
     * @memberof AdminInterviews
     */
    interviews: Array<AdminInterviewResponse>;
    /**
     * 
     * @type {Meta}
     * @memberof AdminInterviews
     */
    meta: Meta;
}
/**
 * 
 * @export
 * @interface AdminUpdateRequest
 */
export interface AdminUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminUpdateRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AnalysisResult
 */
export interface AnalysisResult {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    owner_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    guest_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    aggression_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    openness_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    approval_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    listening_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    guest_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    owner_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResult
     */
    relax_score?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    advice_text?: string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisResult
     */
    status_text?: string;
}
/**
 * 
 * @export
 * @interface AnalysisResultTranscription
 */
export interface AnalysisResultTranscription {
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultTranscription
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisResultTranscription
     */
    analysis_result_id: number;
    /**
     * 
     * @type {Array<TranscriptionRow>}
     * @memberof AnalysisResultTranscription
     */
    rows: Array<TranscriptionRow>;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    user_interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    selection_id: number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    question_id: number;
    /**
     * 
     * @type {Selection}
     * @memberof Answer
     */
    selection: Selection;
    /**
     * 
     * @type {Question}
     * @memberof Answer
     */
    question: Question;
}
/**
 * 
 * @export
 * @interface AnswerForm
 */
export interface AnswerForm {
    /**
     * 
     * @type {number}
     * @memberof AnswerForm
     */
    question_id: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerForm
     */
    selection_id: number | null;
}
/**
 * 
 * @export
 * @interface Auth0Connection
 */
export interface Auth0Connection {
    /**
     * 
     * @type {string}
     * @memberof Auth0Connection
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Auth0Connection
     */
    name: string;
}
/**
 * 
 * @export
 * @interface Auth0Organization
 */
export interface Auth0Organization {
    /**
     * 
     * @type {string}
     * @memberof Auth0Organization
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof Auth0Organization
     */
    enable_mfa: boolean;
    /**
     * 
     * @type {string}
     * @memberof Auth0Organization
     */
    connection_id: string;
    /**
     * 
     * @type {string}
     * @memberof Auth0Organization
     */
    connection_name: string;
}
/**
 * 
 * @export
 * @interface Authority
 */
export interface Authority {
    /**
     * 
     * @type {string}
     * @memberof Authority
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Authority
     */
    name: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthorityStatus {
    Guest = 'Guest',
    Viewer = 'Viewer',
    Admin = 'Admin'
}

/**
 * 
 * @export
 * @interface AverageScore
 */
export interface AverageScore {
    /**
     * 
     * @type {number}
     * @memberof AverageScore
     */
    aggression_score: number;
    /**
     * 
     * @type {number}
     * @memberof AverageScore
     */
    openness_score: number;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    before_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    after_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    before_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    after_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    before_flat_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    after_flat_survey_id: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    physical_delete: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    deleted_at?: string | null;
    /**
     * 
     * @type {Auth0Organization}
     * @memberof Company
     */
    auth0_organization: Auth0Organization | null;
    /**
     * 
     * @type {CompanyPageSettings}
     * @memberof Company
     */
    company_page_settings: CompanyPageSettings;
    /**
     * 
     * @type {CompanyReportSetting}
     * @memberof Company
     */
    company_report_setting: CompanyReportSetting;
    /**
     * 
     * @type {CompanySlackCredential}
     * @memberof Company
     */
    company_slack_credential: CompanySlackCredential | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_ninebox_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_survey_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_sora_data_channel_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_sora_simulcast_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_notify_slack_enabled_default?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_notify_email_enabled_default?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_show_interview_analysis_error: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    unit_price: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_custom_mail_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_secure_live_movie_storage: boolean;
    /**
     * 
     * @type {LiveMovieStorageSwitchStatus}
     * @memberof Company
     */
    live_movie_storage_switch_status: LiveMovieStorageSwitchStatus;
    /**
     * 
     * @type {UserMigrateStatus}
     * @memberof Company
     */
    user_migrate_status: UserMigrateStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    can_reset_password: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    management_report_aggregation_started_at?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    management_report_aggregation_month_period?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_check_ip_addr?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_password_reset_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_one_on_one: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_show_live_checker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    is_show_not_using_one_on_one_entity_screen: boolean;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    analysis_model_version: number;
}
/**
 * 
 * @export
 * @interface CompanyAllowedIp
 */
export interface CompanyAllowedIp {
    /**
     * 
     * @type {number}
     * @memberof CompanyAllowedIp
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyAllowedIp
     */
    cidr: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAllowedIp
     */
    memo: string;
}
/**
 * 
 * @export
 * @interface CompanyAllowedIpsCreateRequest
 */
export interface CompanyAllowedIpsCreateRequest {
    /**
     * 
     * @type {Array<CompanyAllowedIpsCreateRequestCompanyAllowedIps>}
     * @memberof CompanyAllowedIpsCreateRequest
     */
    company_allowed_ips?: Array<CompanyAllowedIpsCreateRequestCompanyAllowedIps>;
}
/**
 * 
 * @export
 * @interface CompanyAllowedIpsCreateRequestCompanyAllowedIps
 */
export interface CompanyAllowedIpsCreateRequestCompanyAllowedIps {
    /**
     * 
     * @type {string}
     * @memberof CompanyAllowedIpsCreateRequestCompanyAllowedIps
     */
    cidr: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAllowedIpsCreateRequestCompanyAllowedIps
     */
    memo: string;
}
/**
 * 
 * @export
 * @interface CompanyIpAddressRestrictionSettings
 */
export interface CompanyIpAddressRestrictionSettings {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyIpAddressRestrictionSettings
     */
    is_restriction: boolean;
}
/**
 * 
 * @export
 * @interface CompanyPageSettings
 */
export interface CompanyPageSettings {
    /**
     * 
     * @type {number}
     * @memberof CompanyPageSettings
     */
    report: number;
}
/**
 * 
 * @export
 * @interface CompanyPageSettingsUpdateRequest
 */
export interface CompanyPageSettingsUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyPageSettingsUpdateRequest
     */
    report?: number;
}
/**
 * 
 * @export
 * @interface CompanyPasswordPolicies
 */
export interface CompanyPasswordPolicies {
    /**
     * 
     * @type {CompanyPasswordPoliciesCompanyPasswordPolicy}
     * @memberof CompanyPasswordPolicies
     */
    company_password_policy: CompanyPasswordPoliciesCompanyPasswordPolicy;
}
/**
 * 
 * @export
 * @interface CompanyPasswordPoliciesCompanyPasswordPolicy
 */
export interface CompanyPasswordPoliciesCompanyPasswordPolicy {
    /**
     * 
     * @type {number}
     * @memberof CompanyPasswordPoliciesCompanyPasswordPolicy
     */
    password_reset_duration_day: number;
}
/**
 * 
 * @export
 * @interface CompanyReportSetting
 */
export interface CompanyReportSetting {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_guest_report_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_guest_movie_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_guest_wordcloud_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_guest_live_transcription_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_owner_movie_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_owner_wordcloud_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyReportSetting
     */
    is_owner_live_transcription_enabled: boolean;
}
/**
 * 
 * @export
 * @interface CompanySlackCredential
 */
export interface CompanySlackCredential {
    /**
     * 
     * @type {number}
     * @memberof CompanySlackCredential
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySlackCredential
     */
    company_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySlackCredential
     */
    team_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySlackCredential
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySlackCredential
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    first_name: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    company_id?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    authority_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUser
     */
    section_fullnames: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    has_active_slack_notify: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    has_active_email_notify: boolean;
    /**
     * 
     * @type {Account}
     * @memberof CompanyUser
     */
    account: Account | null;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsers
 */
export interface CompareCompanyOneOnOneUsers {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsers
     */
    zip_file: string;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponse
 */
export interface CompareCompanyOneOnOneUsersResponse {
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseUsers}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    users: CompareCompanyOneOnOneUsersResponseUsers;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponsePositions}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    positions: CompareCompanyOneOnOneUsersResponsePositions;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseDepartments}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    departments: CompareCompanyOneOnOneUsersResponseDepartments;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseNetworkReportScopes}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    network_report_scopes: CompareCompanyOneOnOneUsersResponseNetworkReportScopes;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    status: CompareCompanyOneOnOneUsersResponseStatusEnum;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseError}
     * @memberof CompareCompanyOneOnOneUsersResponse
     */
    error: CompareCompanyOneOnOneUsersResponseError;
}

/**
    * @export
    * @enum {string}
    */
export enum CompareCompanyOneOnOneUsersResponseStatusEnum {
    Success = 'success',
    Failed = 'failed'
}

/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseDepartments
 */
export interface CompareCompanyOneOnOneUsersResponseDepartments {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartments
     */
    create?: Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseDepartmentsUpdate}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartments
     */
    update?: CompareCompanyOneOnOneUsersResponseDepartmentsUpdate;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartments
     */
    no_change?: Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartments
     */
    remove?: Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseDepartmentsCreate
 */
export interface CompareCompanyOneOnOneUsersResponseDepartmentsCreate {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartmentsCreate
     */
    department_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartmentsCreate
     */
    department_code: string;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseDepartmentsUpdate
 */
export interface CompareCompanyOneOnOneUsersResponseDepartmentsUpdate {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartmentsUpdate
     */
    before: Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseDepartmentsUpdate
     */
    after: Array<CompareCompanyOneOnOneUsersResponseDepartmentsCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseError
 */
export interface CompareCompanyOneOnOneUsersResponseError {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseError
     */
    file_type?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseError
     */
    unique_key?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseNetworkReportScopes
 */
export interface CompareCompanyOneOnOneUsersResponseNetworkReportScopes {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopes
     */
    create?: Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopes
     */
    update?: CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopes
     */
    no_change?: Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopes
     */
    remove?: Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate
 */
export interface CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate
     */
    position_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate
     */
    department_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate
     */
    scope_department_name: string;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate
 */
export interface CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate
     */
    before: Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseNetworkReportScopesUpdate
     */
    after: Array<CompareCompanyOneOnOneUsersResponseNetworkReportScopesCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponsePositions
 */
export interface CompareCompanyOneOnOneUsersResponsePositions {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponsePositions
     */
    create?: Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponsePositionsUpdate}
     * @memberof CompareCompanyOneOnOneUsersResponsePositions
     */
    update?: CompareCompanyOneOnOneUsersResponsePositionsUpdate;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponsePositions
     */
    no_change?: Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponsePositions
     */
    remove?: Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponsePositionsCreate
 */
export interface CompareCompanyOneOnOneUsersResponsePositionsCreate {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponsePositionsCreate
     */
    position_name: string;
    /**
     * 
     * @type {number}
     * @memberof CompareCompanyOneOnOneUsersResponsePositionsCreate
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompareCompanyOneOnOneUsersResponsePositionsCreate
     */
    is_manager: boolean;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponsePositionsUpdate
 */
export interface CompareCompanyOneOnOneUsersResponsePositionsUpdate {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponsePositionsUpdate
     */
    before: Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponsePositionsUpdate
     */
    after: Array<CompareCompanyOneOnOneUsersResponsePositionsCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseUsers
 */
export interface CompareCompanyOneOnOneUsersResponseUsers {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseUsersCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseUsers
     */
    create?: Array<CompareCompanyOneOnOneUsersResponseUsersCreate>;
    /**
     * 
     * @type {CompareCompanyOneOnOneUsersResponseUsersUpdate}
     * @memberof CompareCompanyOneOnOneUsersResponseUsers
     */
    update?: CompareCompanyOneOnOneUsersResponseUsersUpdate;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseUsersCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseUsers
     */
    no_change?: Array<CompareCompanyOneOnOneUsersResponseUsersCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseUsersCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseUsers
     */
    remove?: Array<CompareCompanyOneOnOneUsersResponseUsersCreate>;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseUsersCreate
 */
export interface CompareCompanyOneOnOneUsersResponseUsersCreate {
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    authority: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    department_name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    department_code?: string;
    /**
     * 
     * @type {string}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersCreate
     */
    position_name?: string;
}
/**
 * 
 * @export
 * @interface CompareCompanyOneOnOneUsersResponseUsersUpdate
 */
export interface CompareCompanyOneOnOneUsersResponseUsersUpdate {
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseUsersCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersUpdate
     */
    before: Array<CompareCompanyOneOnOneUsersResponseUsersCreate>;
    /**
     * 
     * @type {Array<CompareCompanyOneOnOneUsersResponseUsersCreate>}
     * @memberof CompareCompanyOneOnOneUsersResponseUsersUpdate
     */
    after: Array<CompareCompanyOneOnOneUsersResponseUsersCreate>;
}
/**
 * 
 * @export
 * @interface CompleteOneOnOneActionItemsResponse
 */
export interface CompleteOneOnOneActionItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof CompleteOneOnOneActionItemsResponse
     */
    one_on_one_action_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface CompleteOneOnOneMeetingAgendaItemsResponse
 */
export interface CompleteOneOnOneMeetingAgendaItemsResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem}
     * @memberof CompleteOneOnOneMeetingAgendaItemsResponse
     */
    one_on_one_meeting_agenda_item: CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem;
}
/**
 * 
 * @export
 * @interface CompleteOneOnOneMeetingsResponse
 */
export interface CompleteOneOnOneMeetingsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof CompleteOneOnOneMeetingsResponse
     */
    one_on_one_meeting: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface CreateNineboxAgendaAnswers
 */
export interface CreateNineboxAgendaAnswers {
    /**
     * 
     * @type {number}
     * @memberof CreateNineboxAgendaAnswers
     */
    interview_id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateNineboxAgendaAnswers
     */
    ninebox_agenda_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateOneOnOne
 */
export interface CreateOneOnOne {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOne
     */
    my_user_id?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOne
     */
    partner_user_id: number;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneActionItem
 */
export interface CreateOneOnOneActionItem {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneActionItem
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneActionItem
     */
    assignee_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneActionItem
     */
    due_on: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneActionItemOrder
 */
export interface CreateOneOnOneActionItemOrder {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneActionItemOrder
     */
    order: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneActionItemOrderResponse
 */
export interface CreateOneOnOneActionItemOrderResponse {
    /**
     * 
     * @type {CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder}
     * @memberof CreateOneOnOneActionItemOrderResponse
     */
    one_on_one_action_item_order: CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneActionItemsResponse
 */
export interface CreateOneOnOneActionItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof CreateOneOnOneActionItemsResponse
     */
    one_on_one_action_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneAgendaItem
 */
export interface CreateOneOnOneAgendaItem {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneAgendaItem
     */
    text: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneAgendaItemOrder
 */
export interface CreateOneOnOneAgendaItemOrder {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneAgendaItemOrder
     */
    order: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneAgendaItemOrderResponse
 */
export interface CreateOneOnOneAgendaItemOrderResponse {
    /**
     * 
     * @type {CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder}
     * @memberof CreateOneOnOneAgendaItemOrderResponse
     */
    one_on_one_agenda_item_order: CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder
 */
export interface CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder
     */
    order: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneAgendaItemsResponse
 */
export interface CreateOneOnOneAgendaItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof CreateOneOnOneAgendaItemsResponse
     */
    one_on_one_agenda_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingAgendaItem
 */
export interface CreateOneOnOneMeetingAgendaItem {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingAgendaItem
     */
    text: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingAgendaItemResponse
 */
export interface CreateOneOnOneMeetingAgendaItemResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem}
     * @memberof CreateOneOnOneMeetingAgendaItemResponse
     */
    one_on_one_meeting_agenda_item: CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem
 */
export interface CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem
     */
    one_on_one_agenda_item_id: number;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingNinebox
 */
export interface CreateOneOnOneMeetingNinebox {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOneOnOneMeetingNinebox
     */
    ninebox_unique_keys: Array<CreateOneOnOneMeetingNineboxNineboxUniqueKeysEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateOneOnOneMeetingNineboxNineboxUniqueKeysEnum {
    FutureCareer = 'future_career',
    WorklifeBalance = 'worklife_balance',
    Personality = 'personality',
    BusinessDesign = 'business_design',
    BusinessConcern = 'business_concern',
    Review = 'review',
    PolicyStrategy = 'policy_strategy',
    Team = 'team',
    VisionCulture = 'vision_culture'
}

/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingSurveyResults
 */
export interface CreateOneOnOneMeetingSurveyResults {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingSurveyResults
     */
    survey_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingSurveyResults
     */
    question_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingSurveyResults
     */
    selection_id: number;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingSurveyResultsResponse
 */
export interface CreateOneOnOneMeetingSurveyResultsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof CreateOneOnOneMeetingSurveyResultsResponse
     */
    one_on_one_meeting_survey_result: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsMemoRequestBody
 */
export interface CreateOneOnOneMeetingsMemoRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsMemoRequestBody
     */
    text: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsMemoResponse
 */
export interface CreateOneOnOneMeetingsMemoResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo}
     * @memberof CreateOneOnOneMeetingsMemoResponse
     */
    one_on_one_meeting_memo: CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo
 */
export interface CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo
     */
    one_on_one_meeting_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo
     */
    memo_id: number;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsPrivateMemo
 */
export interface CreateOneOnOneMeetingsPrivateMemo {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsPrivateMemo
     */
    text: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsPrivateMemoResponse
 */
export interface CreateOneOnOneMeetingsPrivateMemoResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo}
     * @memberof CreateOneOnOneMeetingsPrivateMemoResponse
     */
    one_on_one_meeting_private_memo: CreateOneOnOneMeetingsMemoResponseOneOnOneMeetingMemo;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsResponse
 */
export interface CreateOneOnOneMeetingsResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingsResponseOneOnOneMeeting}
     * @memberof CreateOneOnOneMeetingsResponse
     */
    one_on_one_meeting: CreateOneOnOneMeetingsResponseOneOnOneMeeting;
    /**
     * 
     * @type {CreateOneOnOneMeetingsResponseLiveServer}
     * @memberof CreateOneOnOneMeetingsResponse
     */
    live_server: CreateOneOnOneMeetingsResponseLiveServer;
    /**
     * 
     * @type {Array<CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems>}
     * @memberof CreateOneOnOneMeetingsResponse
     */
    one_on_one_meeting_agenda_items: Array<CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems>;
    /**
     * 
     * @type {ShowOneOnOneResponseUser}
     * @memberof CreateOneOnOneMeetingsResponse
     */
    target_user: ShowOneOnOneResponseUser;
    /**
     * 
     * @type {ShowOneOnOneResponseUser}
     * @memberof CreateOneOnOneMeetingsResponse
     */
    partner_user: ShowOneOnOneResponseUser;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsResponseLiveServer
 */
export interface CreateOneOnOneMeetingsResponseLiveServer {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsResponseLiveServer
     */
    url: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsResponseOneOnOneMeeting
 */
export interface CreateOneOnOneMeetingsResponseOneOnOneMeeting {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeeting
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeeting
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeeting
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeeting
     */
    last_participant_user_id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeeting
     */
    channel_id: string;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
 */
export interface CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems {
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
     */
    one_on_one_agenda_item_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
     */
    is_completed: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
     */
    completed_user_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
     */
    base64_encoded_text: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOneOnOneMeetingsResponseOneOnOneMeetingAgendaItems
     */
    created_user_id: number;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneResponse
 */
export interface CreateOneOnOneResponse {
    /**
     * 
     * @type {CreateOneOnOneResponseOneOnOne}
     * @memberof CreateOneOnOneResponse
     */
    one_on_one: CreateOneOnOneResponseOneOnOne;
}
/**
 * 
 * @export
 * @interface CreateOneOnOneResponseOneOnOne
 */
export interface CreateOneOnOneResponseOneOnOne {
    /**
     * 
     * @type {string}
     * @memberof CreateOneOnOneResponseOneOnOne
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateUserAppearanceSetting
 */
export interface CreateUserAppearanceSetting {
    /**
     * 
     * @type {string}
     * @memberof CreateUserAppearanceSetting
     */
    setting: string;
}
/**
 * 
 * @export
 * @interface CreateUserAppearanceSettingResponse
 */
export interface CreateUserAppearanceSettingResponse {
    /**
     * 
     * @type {CreateUserAppearanceSettingResponseUserAppearanceSetting}
     * @memberof CreateUserAppearanceSettingResponse
     */
    user_appearance_setting: CreateUserAppearanceSettingResponseUserAppearanceSetting;
}
/**
 * 
 * @export
 * @interface CreateUserAppearanceSettingResponseUserAppearanceSetting
 */
export interface CreateUserAppearanceSettingResponseUserAppearanceSetting {
    /**
     * 
     * @type {string}
     * @memberof CreateUserAppearanceSettingResponseUserAppearanceSetting
     */
    setting: string;
}
/**
 * 
 * @export
 * @interface CsvImportResponse
 */
export interface CsvImportResponse {
    /**
     * 
     * @type {number}
     * @memberof CsvImportResponse
     */
    status: number;
    /**
     * 
     * @type {string}
     * @memberof CsvImportResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface CsvUser
 */
export interface CsvUser {
    /**
     * 
     * @type {number}
     * @memberof CsvUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CsvUser
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof CsvUser
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof CsvUser
     */
    external_id?: string;
    /**
     * 
     * @type {AuthorityStatus}
     * @memberof CsvUser
     */
    authority: AuthorityStatus;
    /**
     * 
     * @type {Array<Array<Section>>}
     * @memberof CsvUser
     */
    section_objects: Array<Array<Section>>;
    /**
     * 
     * @type {Account}
     * @memberof CsvUser
     */
    account: Account | null;
}
/**
 * 
 * @export
 * @interface CsvValidationRequest
 */
export interface CsvValidationRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvValidationRequest
     */
    rows: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DayOfWeek {
    Monday = 'monday',
    Tuesday = 'tuesday',
    Wednesday = 'wednesday',
    Thursday = 'thursday',
    Friday = 'friday',
    Saturday = 'saturday',
    Sunday = 'sunday'
}

/**
 * 
 * @export
 * @interface DestroyOneOnOneActionItemsResponse
 */
export interface DestroyOneOnOneActionItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof DestroyOneOnOneActionItemsResponse
     */
    one_on_one_action_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface DestroyOneOnOneAgendaItemsResponse
 */
export interface DestroyOneOnOneAgendaItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof DestroyOneOnOneAgendaItemsResponse
     */
    one_on_one_agenda_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface DownloadZipCompanyOneOnOneUsersResponse
 */
export interface DownloadZipCompanyOneOnOneUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadZipCompanyOneOnOneUsersResponse
     */
    zip_file: string;
}
/**
 * 
 * @export
 * @interface ExportShortTermAction
 */
export interface ExportShortTermAction {
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermAction
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermAction
     */
    listening_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermAction
     */
    approval_grade: number;
    /**
     * 
     * @type {string}
     * @memberof ExportShortTermAction
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface ExportShortTermPartnerAction
 */
export interface ExportShortTermPartnerAction {
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermPartnerAction
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermPartnerAction
     */
    aggression_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermPartnerAction
     */
    openness_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermPartnerAction
     */
    concentration_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermPartnerAction
     */
    relax_grade: number;
    /**
     * 
     * @type {string}
     * @memberof ExportShortTermPartnerAction
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface ExportShortTermSolution
 */
export interface ExportShortTermSolution {
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    listening_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    approval_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    aggression_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    openness_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    concentration_grade: number;
    /**
     * 
     * @type {number}
     * @memberof ExportShortTermSolution
     */
    relax_grade: number;
    /**
     * 
     * @type {string}
     * @memberof ExportShortTermSolution
     */
    comment: string;
}
/**
 * 
 * @export
 * @interface FilterUserOneOnOneMeetingAnalysisResultsResponse
 */
export interface FilterUserOneOnOneMeetingAnalysisResultsResponse {
    /**
     * 
     * @type {Array<FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults>}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponse
     */
    one_on_one_meeting_analysis_results: Array<FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults>;
}
/**
 * 
 * @export
 * @interface FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
 */
export interface FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults {
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    one_on_one_meeting_id: number;
    /**
     * 
     * @type {FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    target_user: FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser;
    /**
     * 
     * @type {FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    partner_user: FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser;
}
/**
 * 
 * @export
 * @interface FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
 */
export interface FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser {
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    aggression_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    openness_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    approval_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    listening_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    relax_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    concentration_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    speech_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    mask_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser
     */
    face_rate: number | null;
}
/**
 * 
 * @export
 * @interface FilterUserOneOnOneMeetingsResponse
 */
export interface FilterUserOneOnOneMeetingsResponse {
    /**
     * 
     * @type {Array<FilterUserOneOnOneMeetingsResponseOneOnOneMeetings>}
     * @memberof FilterUserOneOnOneMeetingsResponse
     */
    one_on_one_meetings: Array<FilterUserOneOnOneMeetingsResponseOneOnOneMeetings>;
}
/**
 * 
 * @export
 * @interface FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
 */
export interface FilterUserOneOnOneMeetingsResponseOneOnOneMeetings {
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    last_participant_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_started_at: string;
    /**
     * 
     * @type {string}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_stopped_at: string;
    /**
     * 
     * @type {number}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_duration_sec: number;
    /**
     * 
     * @type {string}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    complete_kind: FilterUserOneOnOneMeetingsResponseOneOnOneMeetingsCompleteKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_recording: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_transcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FilterUserOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_analysis: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterUserOneOnOneMeetingsResponseOneOnOneMeetingsCompleteKindEnum {
    ShortMeeting = 'short_meeting',
    QualifiedMeeting = 'qualified_meeting'
}

/**
 * 
 * @export
 * @interface ImportCompanyOneOnOneUsers
 */
export interface ImportCompanyOneOnOneUsers {
    /**
     * 
     * @type {string}
     * @memberof ImportCompanyOneOnOneUsers
     */
    zip_file: string;
}
/**
 * 
 * @export
 * @interface ImportCompanyOneOnOneUsersResponse
 */
export interface ImportCompanyOneOnOneUsersResponse {
    /**
     * 
     * @type {ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory}
     * @memberof ImportCompanyOneOnOneUsersResponse
     */
    one_on_one_users_import_history: ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory;
}
/**
 * 
 * @export
 * @interface ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
 */
export interface ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory {
    /**
     * 
     * @type {string}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    company_id: number;
    /**
     * 
     * @type {number}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    account_id: number;
    /**
     * 
     * @type {number}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    admin_id: number;
    /**
     * 
     * @type {string}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    object_path: string;
    /**
     * 
     * @type {string}
     * @memberof ImportCompanyOneOnOneUsersResponseOneOnOneUsersImportHistory
     */
    error_result: string;
}
/**
 * 
 * @export
 * @interface IndexAdminCompanyUserNotifySettingsResponse
 */
export interface IndexAdminCompanyUserNotifySettingsResponse {
    /**
     * 
     * @type {Array<IndexUserNotifySettingsResponseUserNotifySettings>}
     * @memberof IndexAdminCompanyUserNotifySettingsResponse
     */
    user_notify_settings: Array<IndexUserNotifySettingsResponseUserNotifySettings>;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponse
 */
export interface IndexCompaniesResponse {
    /**
     * 
     * @type {IndexCompaniesResponseCompany}
     * @memberof IndexCompaniesResponse
     */
    company: IndexCompaniesResponseCompany;
    /**
     * 
     * @type {IndexCompaniesResponseAuth0Organization}
     * @memberof IndexCompaniesResponse
     */
    auth0_organization: IndexCompaniesResponseAuth0Organization | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanySlackCredential}
     * @memberof IndexCompaniesResponse
     */
    company_slack_credential: IndexCompaniesResponseCompanySlackCredential | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanyReportSetting}
     * @memberof IndexCompaniesResponse
     */
    company_report_setting: IndexCompaniesResponseCompanyReportSetting | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanyPageSettings}
     * @memberof IndexCompaniesResponse
     */
    company_page_settings: IndexCompaniesResponseCompanyPageSettings;
    /**
     * 
     * @type {Array<IndexCompaniesResponseSections>}
     * @memberof IndexCompaniesResponse
     */
    sections: Array<IndexCompaniesResponseSections>;
    /**
     * 
     * @type {Array<IndexCompaniesResponseDepartments>}
     * @memberof IndexCompaniesResponse
     */
    departments: Array<IndexCompaniesResponseDepartments>;
    /**
     * 
     * @type {Array<IndexCompaniesResponsePositions>}
     * @memberof IndexCompaniesResponse
     */
    positions: Array<IndexCompaniesResponsePositions>;
    /**
     * 
     * @type {Array<IndexCompaniesResponseNetworkReportScopes>}
     * @memberof IndexCompaniesResponse
     */
    network_report_scopes: Array<IndexCompaniesResponseNetworkReportScopes>;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseAuth0Organization
 */
export interface IndexCompaniesResponseAuth0Organization {
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseAuth0Organization
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseAuth0Organization
     */
    enable_mfa: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseAuth0Organization
     */
    connection_id: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseAuth0Organization
     */
    connection_name: string;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseCompany
 */
export interface IndexCompaniesResponseCompany {
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseCompany
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_ninebox_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_survey_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_sora_data_channel_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_sora_simulcast_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_show_interview_analysis_error: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_password_reset_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_check_ip_addr: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_notify_slack_enabled_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_notify_email_enabled_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_user_reset_password: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_one_on_one: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_show_live_checker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompany
     */
    is_show_not_using_one_on_one_entity_screen: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseCompany
     */
    user_migrate_status: IndexCompaniesResponseCompanyUserMigrateStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    before_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    after_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    before_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    after_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    before_flat_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    after_flat_survey_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseCompany
     */
    management_report_aggregation_started_at: string;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    management_report_aggregation_month_period: number;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompany
     */
    analysis_model_version: number;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexCompaniesResponseCompanyUserMigrateStatusEnum {
    Completed = 'completed',
    InProgress = 'in_progress'
}

/**
 * 
 * @export
 * @interface IndexCompaniesResponseCompanyPageSettings
 */
export interface IndexCompaniesResponseCompanyPageSettings {
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompanyPageSettings
     */
    report: number;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseCompanyReportSetting
 */
export interface IndexCompaniesResponseCompanyReportSetting {
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_guest_report_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_guest_movie_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_guest_wordcloud_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_guest_live_transcription_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_owner_movie_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_owner_wordcloud_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponseCompanyReportSetting
     */
    is_owner_live_transcription_enabled: boolean;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseCompanySlackCredential
 */
export interface IndexCompaniesResponseCompanySlackCredential {
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseCompanySlackCredential
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseCompanySlackCredential
     */
    team_name: string;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseDepartments
 */
export interface IndexCompaniesResponseDepartments {
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseDepartments
     */
    unique_key: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseDepartments
     */
    full_path_name: string;
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseDepartments
     */
    code?: number;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseNetworkReportScopes
 */
export interface IndexCompaniesResponseNetworkReportScopes {
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseNetworkReportScopes
     */
    position_unique_key: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseNetworkReportScopes
     */
    department_unique_key: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseNetworkReportScopes
     */
    scope_department_unique_key: string;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponsePathItems
 */
export interface IndexCompaniesResponsePathItems {
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponsePathItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponsePathItems
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponsePathItems
     */
    path?: string;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponsePositions
 */
export interface IndexCompaniesResponsePositions {
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponsePositions
     */
    unique_key: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponsePositions
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndexCompaniesResponsePositions
     */
    is_manager: boolean;
}
/**
 * 
 * @export
 * @interface IndexCompaniesResponseSections
 */
export interface IndexCompaniesResponseSections {
    /**
     * 
     * @type {number}
     * @memberof IndexCompaniesResponseSections
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseSections
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IndexCompaniesResponseSections
     */
    path?: string;
    /**
     * 
     * @type {Array<IndexCompaniesResponsePathItems>}
     * @memberof IndexCompaniesResponseSections
     */
    path_items: Array<IndexCompaniesResponsePathItems>;
}
/**
 * 
 * @export
 * @interface IndexNetworkReportsResponse
 */
export interface IndexNetworkReportsResponse {
    /**
     * 
     * @type {Array<IndexNetworkReportsResponseNetworkReports>}
     * @memberof IndexNetworkReportsResponse
     */
    network_reports: Array<IndexNetworkReportsResponseNetworkReports>;
}
/**
 * 
 * @export
 * @interface IndexNetworkReportsResponseNetworkReports
 */
export interface IndexNetworkReportsResponseNetworkReports {
    /**
     * 
     * @type {string}
     * @memberof IndexNetworkReportsResponseNetworkReports
     */
    begin_week: string;
    /**
     * 
     * @type {string}
     * @memberof IndexNetworkReportsResponseNetworkReports
     */
    end_week: string;
    /**
     * 
     * @type {Array<IndexNetworkReportsResponseReports>}
     * @memberof IndexNetworkReportsResponseNetworkReports
     */
    reports: Array<IndexNetworkReportsResponseReports>;
}
/**
 * 
 * @export
 * @interface IndexNetworkReportsResponseReports
 */
export interface IndexNetworkReportsResponseReports {
    /**
     * 
     * @type {number}
     * @memberof IndexNetworkReportsResponseReports
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexNetworkReportsResponseReports
     */
    bigger_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexNetworkReportsResponseReports
     */
    interview_count: number;
    /**
     * 
     * @type {number}
     * @memberof IndexNetworkReportsResponseReports
     */
    interview_duration_sec: number;
}
/**
 * 
 * @export
 * @interface IndexNineboxesResponse
 */
export interface IndexNineboxesResponse {
    /**
     * 
     * @type {Array<IndexNineboxesResponseNineboxes>}
     * @memberof IndexNineboxesResponse
     */
    nineboxes: Array<IndexNineboxesResponseNineboxes>;
}
/**
 * 
 * @export
 * @interface IndexNineboxesResponseNineboxes
 */
export interface IndexNineboxesResponseNineboxes {
    /**
     * 
     * @type {string}
     * @memberof IndexNineboxesResponseNineboxes
     */
    ninebox_unique_key: IndexNineboxesResponseNineboxesNineboxUniqueKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof IndexNineboxesResponseNineboxes
     */
    category_name: IndexNineboxesResponseNineboxesCategoryNameEnum;
    /**
     * 
     * @type {string}
     * @memberof IndexNineboxesResponseNineboxes
     */
    time_series_name: IndexNineboxesResponseNineboxesTimeSeriesNameEnum;
    /**
     * 
     * @type {string}
     * @memberof IndexNineboxesResponseNineboxes
     */
    title: string;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexNineboxesResponseNineboxesNineboxUniqueKeyEnum {
    FutureCareer = 'future_career',
    WorklifeBalance = 'worklife_balance',
    Personality = 'personality',
    BusinessDesign = 'business_design',
    BusinessConcern = 'business_concern',
    Review = 'review',
    PolicyStrategy = 'policy_strategy',
    Team = 'team',
    VisionCulture = 'vision_culture'
}
/**
    * @export
    * @enum {string}
    */
export enum IndexNineboxesResponseNineboxesCategoryNameEnum {
    Personal = 'personal',
    Business = 'business',
    Organization = 'organization'
}
/**
    * @export
    * @enum {string}
    */
export enum IndexNineboxesResponseNineboxesTimeSeriesNameEnum {
    Past = 'past',
    Present = 'present',
    Future = 'future'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneActionItemsResponse
 */
export interface IndexOneOnOneActionItemsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneActionItemsResponseOneOnOneActionItems>}
     * @memberof IndexOneOnOneActionItemsResponse
     */
    one_on_one_action_items: Array<IndexOneOnOneActionItemsResponseOneOnOneActionItems>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneActionItemsResponseOneOnOneActionItems
 */
export interface IndexOneOnOneActionItemsResponseOneOnOneActionItems {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneActionItemsResponseOneOnOneActionItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneActionItemsResponseOneOnOneActionItems
     */
    base64_encoded_text: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneActionItemsResponseOneOnOneActionItems
     */
    assignee_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneActionItemsResponseOneOnOneActionItems
     */
    due_on: string;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneActionItemsResponseOneOnOneActionItems
     */
    status: IndexOneOnOneActionItemsResponseOneOnOneActionItemsStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexOneOnOneActionItemsResponseOneOnOneActionItemsStatusEnum {
    Ready = 'ready',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneAgendaItemsResponse
 */
export interface IndexOneOnOneAgendaItemsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems>}
     * @memberof IndexOneOnOneAgendaItemsResponse
     */
    one_on_one_agenda_items: Array<IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems
 */
export interface IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems
     */
    base64_encoded_text: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAgendaItemsResponseOneOnOneAgendaItems
     */
    created_user_id: number;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneAnalysisSuccessfulMeetingsResponse
 */
export interface IndexOneOnOneAnalysisSuccessfulMeetingsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings>}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponse
     */
    one_on_one_meetings: Array<IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings>;
    /**
     * 
     * @type {PagingResponse}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponse
     */
    paging: PagingResponse;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
 */
export interface IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    last_participant_user_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    recording_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    recording_stopped_at: string | null;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneAnalysisSuccessfulMeetingsResponseOneOnOneMeetings
     */
    recording_duration_sec: number | null;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneInProgressMeetingsResponse
 */
export interface IndexOneOnOneInProgressMeetingsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings>}
     * @memberof IndexOneOnOneInProgressMeetingsResponse
     */
    one_on_one_meetings: Array<IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings
 */
export interface IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneInProgressMeetingsResponseOneOnOneMeetings
     */
    last_participant_user_id: number | null;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingAgendaItemsResponse
 */
export interface IndexOneOnOneMeetingAgendaItemsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems>}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponse
     */
    one_on_one_meeting_agenda_items: Array<IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
 */
export interface IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    one_on_one_agenda_item_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    is_completed: boolean;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    completed_user_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    base64_encoded_text: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    created_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems
     */
    status: IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItemsStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItemsStatusEnum {
    Ready = 'ready',
    Completed = 'completed',
    Disabled = 'disabled'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingAnalysisResultsResponse
 */
export interface IndexOneOnOneMeetingAnalysisResultsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults>}
     * @memberof IndexOneOnOneMeetingAnalysisResultsResponse
     */
    one_on_one_meeting_analysis_results: Array<IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults>;
    /**
     * 
     * @type {PagingResponse}
     * @memberof IndexOneOnOneMeetingAnalysisResultsResponse
     */
    paging: PagingResponse;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
 */
export interface IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    one_on_one_meeting_id: number;
    /**
     * 
     * @type {FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser}
     * @memberof IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    user: FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser;
    /**
     * 
     * @type {FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser}
     * @memberof IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults
     */
    bigger_user: FilterUserOneOnOneMeetingAnalysisResultsResponseTargetUser;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingNineboxesResponse
 */
export interface IndexOneOnOneMeetingNineboxesResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes>}
     * @memberof IndexOneOnOneMeetingNineboxesResponse
     */
    one_on_one_meeting_nineboxes: Array<IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes
 */
export interface IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes
     */
    one_on_one_meeting_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxes
     */
    ninebox_unique_key: IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxesNineboxUniqueKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexOneOnOneMeetingNineboxesResponseOneOnOneMeetingNineboxesNineboxUniqueKeyEnum {
    FutureCareer = 'future_career',
    WorklifeBalance = 'worklife_balance',
    Personality = 'personality',
    BusinessDesign = 'business_design',
    BusinessConcern = 'business_concern',
    Review = 'review',
    PolicyStrategy = 'policy_strategy',
    Team = 'team',
    VisionCulture = 'vision_culture'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingSurveyResultsResponse
 */
export interface IndexOneOnOneMeetingSurveyResultsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults>}
     * @memberof IndexOneOnOneMeetingSurveyResultsResponse
     */
    one_on_one_meeting_survey_results: Array<IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults
 */
export interface IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingSurveyResultsResponseOneOnOneMeetingSurveyResults
     */
    survey_id?: number;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingsResponse
 */
export interface IndexOneOnOneMeetingsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingsResponseOneOnOneMeetings>}
     * @memberof IndexOneOnOneMeetingsResponse
     */
    one_on_one_meetings: Array<IndexOneOnOneMeetingsResponseOneOnOneMeetings>;
    /**
     * 
     * @type {PagingResponse}
     * @memberof IndexOneOnOneMeetingsResponse
     */
    paging: PagingResponse;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneMeetingsResponseOneOnOneMeetings
 */
export interface IndexOneOnOneMeetingsResponseOneOnOneMeetings {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    last_participant_user_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_stopped_at: string | null;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    recording_duration_sec: number | null;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    complete_kind: IndexOneOnOneMeetingsResponseOneOnOneMeetingsCompleteKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_recording: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_transcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexOneOnOneMeetingsResponseOneOnOneMeetings
     */
    is_analysis: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexOneOnOneMeetingsResponseOneOnOneMeetingsCompleteKindEnum {
    ShortMeeting = 'short_meeting',
    QualifiedMeeting = 'qualified_meeting'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneNineboxesResponse
 */
export interface IndexOneOnOneNineboxesResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes>}
     * @memberof IndexOneOnOneNineboxesResponse
     */
    one_on_one_meeting_nineboxes: Array<IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes>;
    /**
     * 
     * @type {PagingResponse}
     * @memberof IndexOneOnOneNineboxesResponse
     */
    paging: PagingResponse;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneNineboxesResponseNineboxes
 */
export interface IndexOneOnOneNineboxesResponseNineboxes {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneNineboxesResponseNineboxes
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneNineboxesResponseNineboxes
     */
    ninebox_unique_key: IndexOneOnOneNineboxesResponseNineboxesNineboxUniqueKeyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum IndexOneOnOneNineboxesResponseNineboxesNineboxUniqueKeyEnum {
    FutureCareer = 'future_career',
    WorklifeBalance = 'worklife_balance',
    Personality = 'personality',
    BusinessDesign = 'business_design',
    BusinessConcern = 'business_concern',
    Review = 'review',
    PolicyStrategy = 'policy_strategy',
    Team = 'team',
    VisionCulture = 'vision_culture'
}

/**
 * 
 * @export
 * @interface IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes
 */
export interface IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes
     */
    one_on_one_meeting_id: number;
    /**
     * 
     * @type {Array<IndexOneOnOneNineboxesResponseNineboxes>}
     * @memberof IndexOneOnOneNineboxesResponseOneOnOneMeetingNineboxes
     */
    nineboxes: Array<IndexOneOnOneNineboxesResponseNineboxes> | null;
}
/**
 * 
 * @export
 * @interface IndexOneOnOnePartnersResponse
 */
export interface IndexOneOnOnePartnersResponse {
    /**
     * 
     * @type {Array<IndexUserOneOnOnePartnersResponseOneOnOnePartners>}
     * @memberof IndexOneOnOnePartnersResponse
     */
    one_on_one_partners: Array<IndexUserOneOnOnePartnersResponseOneOnOnePartners>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneReadyActionItemsResponse
 */
export interface IndexOneOnOneReadyActionItemsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems>}
     * @memberof IndexOneOnOneReadyActionItemsResponse
     */
    one_on_one_action_items: Array<IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems>;
}
/**
 * 
 * @export
 * @interface IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
 */
export interface IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems {
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    one_on_one_id: string;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    base64_encoded_text: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    assignee_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    due_on: string;
    /**
     * 
     * @type {number}
     * @memberof IndexOneOnOneReadyActionItemsResponseOneOnOneActionItems
     */
    one_on_one_meeting_partner_user_id: number;
}
/**
 * 
 * @export
 * @interface IndexSelfResponse
 */
export interface IndexSelfResponse {
    /**
     * 
     * @type {IndexSelfResponseUser}
     * @memberof IndexSelfResponse
     */
    user: IndexSelfResponseUser | null;
    /**
     * 
     * @type {IndexSelfResponseRoom}
     * @memberof IndexSelfResponse
     */
    room: IndexSelfResponseRoom | null;
    /**
     * 
     * @type {IndexSelfResponseAdmin}
     * @memberof IndexSelfResponse
     */
    admin: IndexSelfResponseAdmin | null;
}
/**
 * 
 * @export
 * @interface IndexSelfResponseAdmin
 */
export interface IndexSelfResponseAdmin {
    /**
     * 
     * @type {number}
     * @memberof IndexSelfResponseAdmin
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexSelfResponseAdmin
     */
    name: string;
}
/**
 * 
 * @export
 * @interface IndexSelfResponseRoom
 */
export interface IndexSelfResponseRoom {
    /**
     * 
     * @type {string}
     * @memberof IndexSelfResponseRoom
     */
    invitation_code: string;
}
/**
 * 
 * @export
 * @interface IndexSelfResponseUser
 */
export interface IndexSelfResponseUser {
    /**
     * 
     * @type {number}
     * @memberof IndexSelfResponseUser
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexSelfResponseUser
     */
    account_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexSelfResponseUser
     */
    live_client_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndexSelfResponseUser
     */
    is_agreement: boolean;
}
/**
 * 
 * @export
 * @interface IndexShortTermSuggestionsResponse
 */
export interface IndexShortTermSuggestionsResponse {
    /**
     * 
     * @type {Array<IndexShortTermSuggestionsResponseShortTermActions>}
     * @memberof IndexShortTermSuggestionsResponse
     */
    short_term_actions: Array<IndexShortTermSuggestionsResponseShortTermActions>;
    /**
     * 
     * @type {Array<IndexShortTermSuggestionsResponseShortTermPartnerActions>}
     * @memberof IndexShortTermSuggestionsResponse
     */
    short_term_partner_actions: Array<IndexShortTermSuggestionsResponseShortTermPartnerActions>;
    /**
     * 
     * @type {Array<IndexShortTermSuggestionsResponseShortTermSolutions>}
     * @memberof IndexShortTermSuggestionsResponse
     */
    short_term_solutions: Array<IndexShortTermSuggestionsResponseShortTermSolutions>;
}
/**
 * 
 * @export
 * @interface IndexShortTermSuggestionsResponseShortTermActions
 */
export interface IndexShortTermSuggestionsResponseShortTermActions {
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    suggestion_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    listening_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermActions
     */
    approval_grade: number;
}
/**
 * 
 * @export
 * @interface IndexShortTermSuggestionsResponseShortTermPartnerActions
 */
export interface IndexShortTermSuggestionsResponseShortTermPartnerActions {
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    suggestion_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    aggression_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    openness_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    concentration_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermPartnerActions
     */
    relax_grade: number;
}
/**
 * 
 * @export
 * @interface IndexShortTermSuggestionsResponseShortTermSolutions
 */
export interface IndexShortTermSuggestionsResponseShortTermSolutions {
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    suggestion_id: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    conversation_tendency: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    listening_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    approval_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    aggression_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    openness_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    concentration_grade: number;
    /**
     * 
     * @type {number}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    relax_grade: number;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    insight_1: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_1: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_detail_1: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    insight_2: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_2: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_detail_2: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    insight_3: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_3: string;
    /**
     * 
     * @type {string}
     * @memberof IndexShortTermSuggestionsResponseShortTermSolutions
     */
    solution_detail_3: string;
}
/**
 * 
 * @export
 * @interface IndexUserNotifySettingsResponse
 */
export interface IndexUserNotifySettingsResponse {
    /**
     * 
     * @type {Array<IndexUserNotifySettingsResponseUserNotifySettings>}
     * @memberof IndexUserNotifySettingsResponse
     */
    user_notify_settings: Array<IndexUserNotifySettingsResponseUserNotifySettings>;
}
/**
 * 
 * @export
 * @interface IndexUserNotifySettingsResponseUserNotifySettings
 */
export interface IndexUserNotifySettingsResponseUserNotifySettings {
    /**
     * 
     * @type {number}
     * @memberof IndexUserNotifySettingsResponseUserNotifySettings
     */
    user_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof IndexUserNotifySettingsResponseUserNotifySettings
     */
    is_active_slack: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IndexUserNotifySettingsResponseUserNotifySettings
     */
    is_active_email: boolean;
}
/**
 * 
 * @export
 * @interface IndexUserOneOnOnePartnersResponse
 */
export interface IndexUserOneOnOnePartnersResponse {
    /**
     * 
     * @type {Array<IndexUserOneOnOnePartnersResponseOneOnOnePartners>}
     * @memberof IndexUserOneOnOnePartnersResponse
     */
    one_on_one_partners: Array<IndexUserOneOnOnePartnersResponseOneOnOnePartners>;
}
/**
 * 
 * @export
 * @interface IndexUserOneOnOnePartnersResponseOneOnOnePartners
 */
export interface IndexUserOneOnOnePartnersResponseOneOnOnePartners {
    /**
     * 
     * @type {number}
     * @memberof IndexUserOneOnOnePartnersResponseOneOnOnePartners
     */
    partner_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexUserOneOnOnePartnersResponseOneOnOnePartners
     */
    one_on_one_id: string;
}
/**
 * 
 * @export
 * @interface IndexUsersResponse
 */
export interface IndexUsersResponse {
    /**
     * 
     * @type {Array<IndexUsersResponseUsers>}
     * @memberof IndexUsersResponse
     */
    users: Array<IndexUsersResponseUsers>;
}
/**
 * 
 * @export
 * @interface IndexUsersResponseUsers
 */
export interface IndexUsersResponseUsers {
    /**
     * 
     * @type {number}
     * @memberof IndexUsersResponseUsers
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof IndexUsersResponseUsers
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof IndexUsersResponseUsers
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof IndexUsersResponseUsers
     */
    email: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof IndexUsersResponseUsers
     */
    section_ids: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof IndexUsersResponseUsers
     */
    department_unique_keys: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof IndexUsersResponseUsers
     */
    position_unique_key: string | null;
    /**
     * 
     * @type {AuthorityStatus}
     * @memberof IndexUsersResponseUsers
     */
    authority: AuthorityStatus;
}
/**
 * 
 * @export
 * @interface InlineResponse400
 */
export interface InlineResponse400 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 6 - NOT_AUTHENTICATED 
     * @type {number}
     * @memberof InlineResponse400
     */
    code: InlineResponse400CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse400
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse400CodeEnum {
    NUMBER_1 = 1,
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface InlineResponse4001
 */
export interface InlineResponse4001 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT 
     * @type {number}
     * @memberof InlineResponse4001
     */
    code: InlineResponse4001CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4001
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4001CodeEnum {
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface InlineResponse40010
 */
export interface InlineResponse40010 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 13 - DUPLICATED_EXECUTION 
     * @type {number}
     * @memberof InlineResponse40010
     */
    code: InlineResponse40010CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse40010
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse40010CodeEnum {
    NUMBER_1 = 1,
    NUMBER_13 = 13
}

/**
 * 
 * @export
 * @interface InlineResponse40011
 */
export interface InlineResponse40011 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 4 - RESOURCE_NOT_FOUND  
     * @type {number}
     * @memberof InlineResponse40011
     */
    code: InlineResponse40011CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse40011
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse40011CodeEnum {
    NUMBER_1 = 1,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse4002
 */
export interface InlineResponse4002 {
    /**
     * Error Code:   * 4 - RESOURCE_NOT_FOUND 
     * @type {number}
     * @memberof InlineResponse4002
     */
    code: InlineResponse4002CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4002
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4002CodeEnum {
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse4003
 */
export interface InlineResponse4003 {
    /**
     * Error Code:   * 3 - ACCESS_DENIED   * 4 - RESOURCE_NOT_FOUND 
     * @type {number}
     * @memberof InlineResponse4003
     */
    code: InlineResponse4003CodeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4003CodeEnum {
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse4004
 */
export interface InlineResponse4004 {
    /**
     * Error Code:   * 3 - ACCESS_DENIED   * 4 - RESOURCE_NOT_FOUND 
     * @type {number}
     * @memberof InlineResponse4004
     */
    code: InlineResponse4004CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4004
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4004CodeEnum {
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse4005
 */
export interface InlineResponse4005 {
    /**
     * Error Code:   * 3 - ACCESS_DENIED 
     * @type {number}
     * @memberof InlineResponse4005
     */
    code: InlineResponse4005CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4005
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4005CodeEnum {
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface InlineResponse4006
 */
export interface InlineResponse4006 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 3 - ACCESS_DENIED 
     * @type {number}
     * @memberof InlineResponse4006
     */
    code: InlineResponse4006CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4006
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4006CodeEnum {
    NUMBER_1 = 1,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface InlineResponse4007
 */
export interface InlineResponse4007 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 11 - UPPER_LIMIT_EXCEEDED 
     * @type {number}
     * @memberof InlineResponse4007
     */
    code: InlineResponse4007CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4007
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4007CodeEnum {
    NUMBER_1 = 1,
    NUMBER_11 = 11
}

/**
 * 
 * @export
 * @interface InlineResponse4008
 */
export interface InlineResponse4008 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT   * 4 - RESOURCE_NOT_FOUND 
     * @type {number}
     * @memberof InlineResponse4008
     */
    code: InlineResponse4008CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4008
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4008CodeEnum {
    NUMBER_1 = 1,
    NUMBER_4 = 4
}

/**
 * 
 * @export
 * @interface InlineResponse4009
 */
export interface InlineResponse4009 {
    /**
     * Error Code:   * 1 - ILLEGAL_ARGUMENT 
     * @type {number}
     * @memberof InlineResponse4009
     */
    code: InlineResponse4009CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse4009
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse4009CodeEnum {
    NUMBER_1 = 1
}

/**
 * 
 * @export
 * @interface InlineResponse401
 */
export interface InlineResponse401 {
    /**
     * Error Code:   * 6 - NOT_AUTHENTICATED 
     * @type {number}
     * @memberof InlineResponse401
     */
    code: InlineResponse401CodeEnum;
    /**
     * エラーの詳細です。
     * @type {string}
     * @memberof InlineResponse401
     */
    message?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse401CodeEnum {
    NUMBER_6 = 6
}

/**
 * 
 * @export
 * @interface Interview
 */
export interface Interview {
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    id: number;
    /**
     * 
     * @type {InterviewStatus}
     * @memberof Interview
     */
    status: InterviewStatus;
    /**
     * 
     * @type {InterviewTranscoderStatus}
     * @memberof Interview
     */
    transcoder_status: InterviewTranscoderStatus;
    /**
     * 
     * @type {InterviewReportStatus}
     * @memberof Interview
     */
    report_status: InterviewReportStatus;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    recording_id: string;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    record_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    interview_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    interview_finished_at: string | null;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    owner_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    guest_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    aggression_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    openness_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    approval_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    listening_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    guest_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    owner_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    relax_score?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    owner_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    owner_mask_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    guest_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof Interview
     */
    guest_mask_rate: number | null;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    advice_text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Interview
     */
    status_text?: string | null;
    /**
     * 
     * @type {Array<UserInterview>}
     * @memberof Interview
     */
    user_interviews: Array<UserInterview>;
    /**
     * 
     * @type {UserInterview}
     * @memberof Interview
     */
    current_user_interview?: UserInterview | null;
    /**
     * 
     * @type {ShortTermSuggestion}
     * @memberof Interview
     */
    short_term_suggestion: ShortTermSuggestion;
    /**
     * 
     * @type {Array<NineboxAgendaAnswer>}
     * @memberof Interview
     */
    owner_ninebox_agenda_answers?: Array<NineboxAgendaAnswer>;
    /**
     * 
     * @type {Array<NineboxAgendaAnswer>}
     * @memberof Interview
     */
    guest_ninebox_agenda_answers?: Array<NineboxAgendaAnswer>;
}
/**
 * 
 * @export
 * @interface InterviewArchiveV2
 */
export interface InterviewArchiveV2 {
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    id: number;
    /**
     * 
     * @type {InterviewStatus}
     * @memberof InterviewArchiveV2
     */
    status: InterviewStatus;
    /**
     * 
     * @type {InterviewTranscoderStatus}
     * @memberof InterviewArchiveV2
     */
    transcoder_status: InterviewTranscoderStatus;
    /**
     * 
     * @type {InterviewReportStatus}
     * @memberof InterviewArchiveV2
     */
    report_status: InterviewReportStatus;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    recording_id: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    record_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    interview_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    interview_finished_at: string;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    owner_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    guest_speech_rate: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    aggression_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    openness_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    approval_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    listening_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    guest_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    owner_concentration_score: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    owner_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    owner_mask_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    guest_face_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof InterviewArchiveV2
     */
    guest_mask_rate: number | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    advice_text?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewArchiveV2
     */
    status_text?: string;
    /**
     * 
     * @type {Array<UserInterview>}
     * @memberof InterviewArchiveV2
     */
    user_interviews: Array<UserInterview>;
    /**
     * 
     * @type {UserInterview}
     * @memberof InterviewArchiveV2
     */
    current_user_interview?: UserInterview;
    /**
     * 
     * @type {Array<NineboxAgendaAnswer>}
     * @memberof InterviewArchiveV2
     */
    owner_ninebox_agenda_answers?: Array<NineboxAgendaAnswer>;
    /**
     * 
     * @type {Array<NineboxAgendaAnswer>}
     * @memberof InterviewArchiveV2
     */
    guest_ninebox_agenda_answers?: Array<NineboxAgendaAnswer>;
    /**
     * 
     * @type {ShortTermSuggestion}
     * @memberof InterviewArchiveV2
     */
    short_term_suggestion: ShortTermSuggestion;
}
/**
 * 
 * @export
 * @interface InterviewCreateRequest
 */
export interface InterviewCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof InterviewCreateRequest
     */
    room_id: number;
}
/**
 * 
 * @export
 * @interface InterviewMemoCreateRequest
 */
export interface InterviewMemoCreateRequest {
    /**
     * 
     * @type {MemoType}
     * @memberof InterviewMemoCreateRequest
     */
    memo_type?: MemoType;
    /**
     * JSON format string
     * @type {string}
     * @memberof InterviewMemoCreateRequest
     */
    memo?: string;
}
/**
 * 
 * @export
 * @interface InterviewMemoDetail
 */
export interface InterviewMemoDetail {
    /**
     * 
     * @type {number}
     * @memberof InterviewMemoDetail
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof InterviewMemoDetail
     */
    interview_id: number;
    /**
     * 
     * @type {MemoType}
     * @memberof InterviewMemoDetail
     */
    memo_type: MemoType;
    /**
     * JSON format string
     * @type {string}
     * @memberof InterviewMemoDetail
     */
    memo?: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewMemoDetail
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof InterviewMemoDetail
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewReportStatus {
    Uncompleted = 'uncompleted',
    InProgress = 'in_progress',
    Completed = 'completed',
    Error = 'error'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewStatus {
    Planning = 'planning',
    Uncompleted = 'uncompleted',
    Completed = 'completed'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewTranscoderStatus {
    Uncompleted = 'uncompleted',
    Completed = 'completed',
    ArchiveNotFound = 'archive_not_found',
    Error = 'error'
}

/**
 * 
 * @export
 * @interface InterviewUpdateRequest
 */
export interface InterviewUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof InterviewUpdateRequest
     */
    recording_id?: string;
    /**
     * 
     * @type {InterviewStatus}
     * @memberof InterviewUpdateRequest
     */
    status?: InterviewStatus;
}
/**
 * 
 * @export
 * @interface LiveBackgroundImage
 */
export interface LiveBackgroundImage {
    /**
     * 
     * @type {number}
     * @memberof LiveBackgroundImage
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LiveBackgroundImage
     */
    key: number;
    /**
     * 
     * @type {string}
     * @memberof LiveBackgroundImage
     */
    url: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LiveMovieStorageSwitchStatus {
    Completed = 'completed',
    InProgress = 'in_progress'
}

/**
 * 
 * @export
 * @interface LiveServer
 */
export interface LiveServer {
    /**
     * 
     * @type {number}
     * @memberof LiveServer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LiveServer
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof LiveServer
     */
    max_connections: number;
    /**
     * 
     * @type {string}
     * @memberof LiveServer
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof LiveServer
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MemoType {
    ShareMemo = 'share_memo',
    PrivateMemo = 'private_memo'
}

/**
 * 
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * 
     * @type {number}
     * @memberof Meta
     */
    total_count: number;
}
/**
 * 
 * @export
 * @interface MiddleSuggestion
 */
export interface MiddleSuggestion {
    /**
     * 
     * @type {Array<SuggestionContent>}
     * @memberof MiddleSuggestion
     */
    direction: Array<SuggestionContent>;
    /**
     * 
     * @type {MiddleSuggestionAgenda}
     * @memberof MiddleSuggestion
     */
    agenda: MiddleSuggestionAgenda;
}
/**
 * 
 * @export
 * @interface MiddleSuggestionAgenda
 */
export interface MiddleSuggestionAgenda {
    /**
     * 
     * @type {Array<string>}
     * @memberof MiddleSuggestionAgenda
     */
    headings: Array<string>;
    /**
     * 
     * @type {Array<SuggestionContent>}
     * @memberof MiddleSuggestionAgenda
     */
    items: Array<SuggestionContent>;
}
/**
 * 
 * @export
 * @interface MovieResponse
 */
export interface MovieResponse {
    /**
     * 
     * @type {InterviewStatus}
     * @memberof MovieResponse
     */
    status: InterviewStatus;
    /**
     * 
     * @type {string}
     * @memberof MovieResponse
     */
    recording_id: string;
    /**
     * 
     * @type {string}
     * @memberof MovieResponse
     */
    record_url: string | null;
}
/**
 * 
 * @export
 * @interface NineboxAgendaAnswer
 */
export interface NineboxAgendaAnswer {
    /**
     * 
     * @type {number}
     * @memberof NineboxAgendaAnswer
     */
    ninebox_agenda_id?: number;
    /**
     * 
     * @type {number}
     * @memberof NineboxAgendaAnswer
     */
    user_id?: number;
    /**
     * 
     * @type {string}
     * @memberof NineboxAgendaAnswer
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof NineboxAgendaAnswer
     */
    agenda_answer_title?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NineboxAgendaCategory {
    Personal = 'personal',
    Business = 'business',
    Organization = 'organization'
}

/**
 * 
 * @export
 * @interface NineboxAgendaIndex
 */
export interface NineboxAgendaIndex {
    /**
     * 
     * @type {number}
     * @memberof NineboxAgendaIndex
     */
    id: number;
    /**
     * 
     * @type {NineboxAgendaCategory}
     * @memberof NineboxAgendaIndex
     */
    category: NineboxAgendaCategory;
    /**
     * 
     * @type {NineboxAgendaTimeSeries}
     * @memberof NineboxAgendaIndex
     */
    time_series: NineboxAgendaTimeSeries;
    /**
     * 
     * @type {string}
     * @memberof NineboxAgendaIndex
     */
    title: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NineboxAgendaTimeSeries {
    PastAgenda = 'past_agenda',
    PresentAgenda = 'present_agenda',
    FutureAgenda = 'future_agenda'
}

/**
 * 
 * @export
 * @interface NotifyEnabledDefaultUpdateRequest
 */
export interface NotifyEnabledDefaultUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NotifyEnabledDefaultUpdateRequest
     */
    is_notify_slack_enabled_default: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotifyEnabledDefaultUpdateRequest
     */
    is_notify_email_enabled_default: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum NotifyType {
    Slack = 'slack',
    Email = 'email'
}

/**
 * 
 * @export
 * @interface OpenOneOnOneMeetingAgendaItemsResponse
 */
export interface OpenOneOnOneMeetingAgendaItemsResponse {
    /**
     * 
     * @type {CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem}
     * @memberof OpenOneOnOneMeetingAgendaItemsResponse
     */
    one_on_one_meeting_agenda_item: CreateOneOnOneMeetingAgendaItemResponseOneOnOneMeetingAgendaItem;
}
/**
 * 
 * @export
 * @interface PaginatedCompanyUsers
 */
export interface PaginatedCompanyUsers {
    /**
     * 
     * @type {Array<CompanyUser>}
     * @memberof PaginatedCompanyUsers
     */
    users: Array<CompanyUser>;
    /**
     * 
     * @type {Meta}
     * @memberof PaginatedCompanyUsers
     */
    meta: Meta;
}
/**
 * 
 * @export
 * @interface PagingResponse
 */
export interface PagingResponse {
    /**
     * 
     * @type {PagingResponsePrevious}
     * @memberof PagingResponse
     */
    previous: PagingResponsePrevious;
    /**
     * 
     * @type {PagingResponseNext}
     * @memberof PagingResponse
     */
    next: PagingResponseNext;
    /**
     * データの降順、昇順を指定します。
     * @type {string}
     * @memberof PagingResponse
     */
    order: PagingResponseOrderEnum;
    /**
     * 取得予定件数、指定した件数が必ず含まれるわけではありません。
     * @type {number}
     * @memberof PagingResponse
     */
    limit: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PagingResponseOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}

/**
 * データの前ページの起点になるカーソルです。含まれていない場合、これはデータの最初のページになります。
 * @export
 * @interface PagingResponseNext
 */
export interface PagingResponseNext {
    /**
     * ページネーションで使う
     * @type {string}
     * @memberof PagingResponseNext
     */
    field_name?: string;
    /**
     * カーソルの文字列
     * @type {string}
     * @memberof PagingResponseNext
     */
    cursor?: string;
}
/**
 * データの前ページの起点になるカーソルです。含まれていない場合、これはデータの最初のページになります。
 * @export
 * @interface PagingResponsePrevious
 */
export interface PagingResponsePrevious {
    /**
     * ページネーションで使う
     * @type {string}
     * @memberof PagingResponsePrevious
     */
    field_name?: string;
    /**
     * カーソルの文字列
     * @type {string}
     * @memberof PagingResponsePrevious
     */
    cursor?: string;
}
/**
 * 
 * @export
 * @interface PartnerActionCsvImportError
 */
export interface PartnerActionCsvImportError {
    /**
     * 
     * @type {PartnerActionCsvImportErrorAttributes}
     * @memberof PartnerActionCsvImportError
     */
    attributes: PartnerActionCsvImportErrorAttributes;
    /**
     * 
     * @type {string}
     * @memberof PartnerActionCsvImportError
     */
    messages: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerActionCsvImportError
     */
    keys?: string;
}
/**
 * 
 * @export
 * @interface PartnerActionCsvImportErrorAttributes
 */
export interface PartnerActionCsvImportErrorAttributes {
    /**
     * 
     * @type {string}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    conversation_tendency?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    aggression_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    concentration_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    openness_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvImportErrorAttributes
     */
    relax_grade?: number;
}
/**
 * 
 * @export
 * @interface PartnerActionCsvParseResult
 */
export interface PartnerActionCsvParseResult {
    /**
     * 
     * @type {number}
     * @memberof PartnerActionCsvParseResult
     */
    status: number;
    /**
     * 
     * @type {PartnerActionCsvParseResultData}
     * @memberof PartnerActionCsvParseResult
     */
    data: PartnerActionCsvParseResultData;
    /**
     * 
     * @type {string}
     * @memberof PartnerActionCsvParseResult
     */
    message: string;
}
/**
 * 
 * @export
 * @interface PartnerActionCsvParseResultData
 */
export interface PartnerActionCsvParseResultData {
    /**
     * 
     * @type {Array<PartnerActionCsvImportError>}
     * @memberof PartnerActionCsvParseResultData
     */
    errors: Array<PartnerActionCsvImportError>;
    /**
     * 
     * @type {ActionCsvParseResultDataCount}
     * @memberof PartnerActionCsvParseResultData
     */
    count: ActionCsvParseResultDataCount;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    survey_id?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<Selection>}
     * @memberof Question
     */
    selections: Array<Selection>;
}
/**
 * 
 * @export
 * @interface QuestionForm
 */
export interface QuestionForm {
    /**
     * 
     * @type {number}
     * @memberof QuestionForm
     */
    survey_id?: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionForm
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionForm
     */
    updated_at?: string;
    /**
     * 
     * @type {Array<SelectionForm>}
     * @memberof QuestionForm
     */
    selections: Array<SelectionForm>;
}
/**
 * 
 * @export
 * @interface ReadyOneOnOneActionItemsResponse
 */
export interface ReadyOneOnOneActionItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof ReadyOneOnOneActionItemsResponse
     */
    one_on_one_action_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface ReportV2
 */
export interface ReportV2 {
    /**
     * 
     * @type {User}
     * @memberof ReportV2
     */
    owner: User;
    /**
     * 
     * @type {User}
     * @memberof ReportV2
     */
    guest: User;
    /**
     * 
     * @type {Array<InterviewArchiveV2>}
     * @memberof ReportV2
     */
    interview_archives: Array<InterviewArchiveV2>;
    /**
     * 
     * @type {AverageScore}
     * @memberof ReportV2
     */
    average_scores: AverageScore;
    /**
     * 
     * @type {MiddleSuggestion}
     * @memberof ReportV2
     */
    middle_suggestion: MiddleSuggestion;
}
/**
 * 
 * @export
 * @interface ReportV2Detail
 */
export interface ReportV2Detail {
    /**
     * 
     * @type {User}
     * @memberof ReportV2Detail
     */
    owner: User;
    /**
     * 
     * @type {User}
     * @memberof ReportV2Detail
     */
    guest: User;
    /**
     * 
     * @type {Interview}
     * @memberof ReportV2Detail
     */
    interview: Interview;
    /**
     * 
     * @type {AnalysisResult}
     * @memberof ReportV2Detail
     */
    analysis_result?: AnalysisResult | null;
    /**
     * 
     * @type {AnalysisResultTranscription}
     * @memberof ReportV2Detail
     */
    analysis_result_transcription?: AnalysisResultTranscription | null;
}
/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    invitation_code: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    updated_at?: string;
    /**
     * 
     * @type {UserInterview}
     * @memberof Room
     */
    current_interview?: UserInterview;
    /**
     * 
     * @type {Array<UserInterview>}
     * @memberof Room
     */
    current_user_interviews: Array<UserInterview>;
    /**
     * 
     * @type {User}
     * @memberof Room
     */
    user: User;
    /**
     * 
     * @type {RoomLiveServer}
     * @memberof Room
     */
    room_live_server?: RoomLiveServer | null;
}
/**
 * 
 * @export
 * @interface RoomLiveServer
 */
export interface RoomLiveServer {
    /**
     * 
     * @type {number}
     * @memberof RoomLiveServer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RoomLiveServer
     */
    room_id: number;
    /**
     * 
     * @type {number}
     * @memberof RoomLiveServer
     */
    live_server_id: number;
    /**
     * 
     * @type {string}
     * @memberof RoomLiveServer
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof RoomLiveServer
     */
    updated_at: string;
    /**
     * 
     * @type {LiveServer}
     * @memberof RoomLiveServer
     */
    live_server?: LiveServer | null;
}
/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Section
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    parent_path?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Section
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface Selection
 */
export interface Selection {
    /**
     * 
     * @type {number}
     * @memberof Selection
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Selection
     */
    question_id?: number;
    /**
     * 
     * @type {number}
     * @memberof Selection
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof Selection
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface SelectionForm
 */
export interface SelectionForm {
    /**
     * 
     * @type {number}
     * @memberof SelectionForm
     */
    question_id?: number;
    /**
     * 
     * @type {number}
     * @memberof SelectionForm
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof SelectionForm
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof SelectionForm
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectionForm
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface ShortTermSuggestion
 */
export interface ShortTermSuggestion {
    /**
     * 
     * @type {ShortTermSuggestionAgenda}
     * @memberof ShortTermSuggestion
     */
    agenda?: ShortTermSuggestionAgenda;
    /**
     * 
     * @type {ShortTermSuggestionAction}
     * @memberof ShortTermSuggestion
     */
    action?: ShortTermSuggestionAction;
    /**
     * 
     * @type {ShortTermSuggestionSolution}
     * @memberof ShortTermSuggestion
     */
    solution?: ShortTermSuggestionSolution;
}
/**
 * 
 * @export
 * @interface ShortTermSuggestionAction
 */
export interface ShortTermSuggestionAction {
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionAction
     */
    your: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionAction
     */
    partner: string;
}
/**
 * 
 * @export
 * @interface ShortTermSuggestionAgenda
 */
export interface ShortTermSuggestionAgenda {
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionAgenda
     */
    conversation_tendency?: ShortTermSuggestionAgendaConversationTendencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ShortTermSuggestionAgendaConversationTendencyEnum {
    Hearing = 'hearing',
    Conversation = 'conversation',
    Inform = 'inform'
}

/**
 * 
 * @export
 * @interface ShortTermSuggestionSolution
 */
export interface ShortTermSuggestionSolution {
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionSolution
     */
    comment: string;
    /**
     * 
     * @type {Array<ShortTermSuggestionSolutionItems>}
     * @memberof ShortTermSuggestionSolution
     */
    items: Array<ShortTermSuggestionSolutionItems>;
}
/**
 * 
 * @export
 * @interface ShortTermSuggestionSolutionItems
 */
export interface ShortTermSuggestionSolutionItems {
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionSolutionItems
     */
    insight: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionSolutionItems
     */
    solution: string;
    /**
     * 
     * @type {string}
     * @memberof ShortTermSuggestionSolutionItems
     */
    solution_detail: string;
}
/**
 * 
 * @export
 * @interface ShowAdminCompaniesResponse
 */
export interface ShowAdminCompaniesResponse {
    /**
     * 
     * @type {IndexCompaniesResponseCompany}
     * @memberof ShowAdminCompaniesResponse
     */
    company: IndexCompaniesResponseCompany;
    /**
     * 
     * @type {IndexCompaniesResponseAuth0Organization}
     * @memberof ShowAdminCompaniesResponse
     */
    auth0_organization: IndexCompaniesResponseAuth0Organization | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanySlackCredential}
     * @memberof ShowAdminCompaniesResponse
     */
    company_slack_credential: IndexCompaniesResponseCompanySlackCredential | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanyReportSetting}
     * @memberof ShowAdminCompaniesResponse
     */
    company_report_setting: IndexCompaniesResponseCompanyReportSetting | null;
    /**
     * 
     * @type {IndexCompaniesResponseCompanyPageSettings}
     * @memberof ShowAdminCompaniesResponse
     */
    company_page_settings: IndexCompaniesResponseCompanyPageSettings;
    /**
     * 
     * @type {Array<IndexCompaniesResponseSections>}
     * @memberof ShowAdminCompaniesResponse
     */
    sections: Array<IndexCompaniesResponseSections>;
    /**
     * 
     * @type {Array<IndexCompaniesResponseDepartments>}
     * @memberof ShowAdminCompaniesResponse
     */
    departments: Array<IndexCompaniesResponseDepartments>;
    /**
     * 
     * @type {Array<IndexCompaniesResponsePositions>}
     * @memberof ShowAdminCompaniesResponse
     */
    positions: Array<IndexCompaniesResponsePositions>;
    /**
     * 
     * @type {Array<IndexCompaniesResponseNetworkReportScopes>}
     * @memberof ShowAdminCompaniesResponse
     */
    network_report_scopes: Array<IndexCompaniesResponseNetworkReportScopes>;
}
/**
 * 
 * @export
 * @interface ShowAdminSurveyTypesResponse
 */
export interface ShowAdminSurveyTypesResponse {
    /**
     * 
     * @type {Array<ShowAdminSurveyTypesResponseSurveyTypes>}
     * @memberof ShowAdminSurveyTypesResponse
     */
    survey_types: Array<ShowAdminSurveyTypesResponseSurveyTypes>;
}
/**
 * 
 * @export
 * @interface ShowAdminSurveyTypesResponseSurveyTypes
 */
export interface ShowAdminSurveyTypesResponseSurveyTypes {
    /**
     * 
     * @type {number}
     * @memberof ShowAdminSurveyTypesResponseSurveyTypes
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowAdminSurveyTypesResponseSurveyTypes
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof ShowAdminSurveyTypesResponseSurveyTypes
     */
    name: string;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponse
 */
export interface ShowManagementReportResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReport}
     * @memberof ShowManagementReportResponse
     */
    management_report: ShowManagementReportResponseManagementReport;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReport
 */
export interface ShowManagementReportResponseManagementReport {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReport
     */
    guest_count: number;
    /**
     * 
     * @type {Array<ShowManagementReportResponseManagementReportInterviewReports>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    interview_reports: Array<ShowManagementReportResponseManagementReportInterviewReports>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    owner_speech_rates: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    guest_concentration_scores: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    listening_scores: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    relax_scores: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    approval_scores: Array<number>;
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportCompanyScores}
     * @memberof ShowManagementReportResponseManagementReport
     */
    company_scores: ShowManagementReportResponseManagementReportCompanyScores;
    /**
     * 
     * @type {Array<ShowManagementReportResponseManagementReportGuestReports>}
     * @memberof ShowManagementReportResponseManagementReport
     */
    guest_reports: Array<ShowManagementReportResponseManagementReportGuestReports>;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportAnalysisResult
 */
export interface ShowManagementReportResponseManagementReportAnalysisResult {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    owner_speech_rate: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    aggression_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    openness_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    relax_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult
     */
    guest_concentration_score: number | null;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportAnalysisResult1
 */
export interface ShowManagementReportResponseManagementReportAnalysisResult1 {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult1
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult1
     */
    aggression_score: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportAnalysisResult1
     */
    openness_score: number | null;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportCompanyScores
 */
export interface ShowManagementReportResponseManagementReportCompanyScores {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    relax_score_sum: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    relax_score_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    guest_concentration_score_sum: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    guest_concentration_score_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    listening_score_sum: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    listening_score_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    approval_score_sum: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportCompanyScores
     */
    approval_score_count: number;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportGuestReports
 */
export interface ShowManagementReportResponseManagementReportGuestReports {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof ShowManagementReportResponseManagementReportGuestReports
     */
    user: ShowManagementReportResponseManagementReportUser;
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportLatestInterview}
     * @memberof ShowManagementReportResponseManagementReportGuestReports
     */
    latest_interview: ShowManagementReportResponseManagementReportLatestInterview;
    /**
     * 
     * @type {Array<ShowManagementReportResponseManagementReportReports>}
     * @memberof ShowManagementReportResponseManagementReportGuestReports
     */
    reports: Array<ShowManagementReportResponseManagementReportReports>;
    /**
     * 
     * @type {Array<ShowManagementReportResponseManagementReportLatestReports>}
     * @memberof ShowManagementReportResponseManagementReportGuestReports
     */
    latest_reports: Array<ShowManagementReportResponseManagementReportLatestReports>;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportInterview
 */
export interface ShowManagementReportResponseManagementReportInterview {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportInterview
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowManagementReportResponseManagementReportInterview
     */
    interview_finished_at: string;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportInterview
     */
    duration_sec: number;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportInterviewReports
 */
export interface ShowManagementReportResponseManagementReportInterviewReports {
    /**
     * 
     * @type {string}
     * @memberof ShowManagementReportResponseManagementReportInterviewReports
     */
    year_month: string;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportInterviewReports
     */
    total_count: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportInterviewReports
     */
    uu: number;
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportInterviewReports
     */
    total_duration_sec: number;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportLatestInterview
 */
export interface ShowManagementReportResponseManagementReportLatestInterview {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportLatestInterview
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowManagementReportResponseManagementReportLatestInterview
     */
    interview_finished_at: string;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportLatestReports
 */
export interface ShowManagementReportResponseManagementReportLatestReports {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof ShowManagementReportResponseManagementReportLatestReports
     */
    interview: ShowManagementReportResponseManagementReportUser;
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportAnalysisResult1}
     * @memberof ShowManagementReportResponseManagementReportLatestReports
     */
    analysis_result: ShowManagementReportResponseManagementReportAnalysisResult1;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportReports
 */
export interface ShowManagementReportResponseManagementReportReports {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportInterview}
     * @memberof ShowManagementReportResponseManagementReportReports
     */
    interview: ShowManagementReportResponseManagementReportInterview;
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportAnalysisResult}
     * @memberof ShowManagementReportResponseManagementReportReports
     */
    analysis_result: ShowManagementReportResponseManagementReportAnalysisResult;
}
/**
 * 
 * @export
 * @interface ShowManagementReportResponseManagementReportUser
 */
export interface ShowManagementReportResponseManagementReportUser {
    /**
     * 
     * @type {number}
     * @memberof ShowManagementReportResponseManagementReportUser
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneActionItemOrderResponse
 */
export interface ShowOneOnOneActionItemOrderResponse {
    /**
     * 
     * @type {ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder}
     * @memberof ShowOneOnOneActionItemOrderResponse
     */
    one_on_one_action_item_order: ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder | null;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneAgendaItemOrderResponse
 */
export interface ShowOneOnOneAgendaItemOrderResponse {
    /**
     * 
     * @type {ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder}
     * @memberof ShowOneOnOneAgendaItemOrderResponse
     */
    one_on_one_agenda_item_order: ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder | null;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder
 */
export interface ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder {
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneAgendaItemOrderResponseOneOnOneAgendaItemOrder
     */
    order: string;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneInvitationCodeResponse
 */
export interface ShowOneOnOneInvitationCodeResponse {
    /**
     * 
     * @type {ShowOneOnOneInvitationCodeResponseTarget}
     * @memberof ShowOneOnOneInvitationCodeResponse
     */
    target: ShowOneOnOneInvitationCodeResponseTarget;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneInvitationCodeResponseTarget
 */
export interface ShowOneOnOneInvitationCodeResponseTarget {
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneInvitationCodeResponseTarget
     */
    invitation_code_user_id: number;
    /**
     * 
     * @type {ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner}
     * @memberof ShowOneOnOneInvitationCodeResponseTarget
     */
    one_on_one_partner: ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner | null;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner
 */
export interface ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner {
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner
     */
    partner_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneInvitationCodeResponseTargetOneOnOnePartner
     */
    one_on_one_id: string;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingAnalysisResultResponse
 */
export interface ShowOneOnOneMeetingAnalysisResultResponse {
    /**
     * 
     * @type {IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults}
     * @memberof ShowOneOnOneMeetingAnalysisResultResponse
     */
    one_on_one_meeting_analysis_result: IndexOneOnOneMeetingAnalysisResultsResponseOneOnOneMeetingAnalysisResults;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingsMemoResponse
 */
export interface ShowOneOnOneMeetingsMemoResponse {
    /**
     * 
     * @type {ShowOneOnOneMeetingsMemoResponseMemo}
     * @memberof ShowOneOnOneMeetingsMemoResponse
     */
    memo: ShowOneOnOneMeetingsMemoResponseMemo | null;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingsMemoResponseMemo
 */
export interface ShowOneOnOneMeetingsMemoResponseMemo {
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneMeetingsMemoResponseMemo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsMemoResponseMemo
     */
    base64_encoded_text: string;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingsPrivateMemoResponse
 */
export interface ShowOneOnOneMeetingsPrivateMemoResponse {
    /**
     * 
     * @type {ShowOneOnOneMeetingsMemoResponseMemo}
     * @memberof ShowOneOnOneMeetingsPrivateMemoResponse
     */
    memo: ShowOneOnOneMeetingsMemoResponseMemo | null;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingsResponse
 */
export interface ShowOneOnOneMeetingsResponse {
    /**
     * 
     * @type {ShowOneOnOneMeetingsResponseOneOnOneMeeting}
     * @memberof ShowOneOnOneMeetingsResponse
     */
    one_on_one_meeting: ShowOneOnOneMeetingsResponseOneOnOneMeeting;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneMeetingsResponseOneOnOneMeeting
 */
export interface ShowOneOnOneMeetingsResponseOneOnOneMeeting {
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    one_on_one_id: string;
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    first_participant_user_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    last_participant_user_id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    channel_id: string;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    recording_url: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    recording_started_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    recording_stopped_at: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    recording_duration_sec: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    complete_kind: ShowOneOnOneMeetingsResponseOneOnOneMeetingCompleteKindEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    is_recording: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    is_transcode: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowOneOnOneMeetingsResponseOneOnOneMeeting
     */
    is_analysis: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ShowOneOnOneMeetingsResponseOneOnOneMeetingCompleteKindEnum {
    ShortMeeting = 'short_meeting',
    QualifiedMeeting = 'qualified_meeting'
}

/**
 * 
 * @export
 * @interface ShowOneOnOneResponse
 */
export interface ShowOneOnOneResponse {
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneResponse
     */
    one_on_one_id: string;
    /**
     * 
     * @type {ShowOneOnOneResponseUser}
     * @memberof ShowOneOnOneResponse
     */
    user: ShowOneOnOneResponseUser;
    /**
     * 
     * @type {ShowOneOnOneResponseUser}
     * @memberof ShowOneOnOneResponse
     */
    bigger_user: ShowOneOnOneResponseUser;
}
/**
 * 
 * @export
 * @interface ShowOneOnOneResponseUser
 */
export interface ShowOneOnOneResponseUser {
    /**
     * 
     * @type {number}
     * @memberof ShowOneOnOneResponseUser
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowOneOnOneResponseUser
     */
    relationship_type: ShowOneOnOneResponseUserRelationshipTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ShowOneOnOneResponseUserRelationshipTypeEnum {
    Boss = 'boss',
    Subordinate = 'subordinate',
    Flat = 'flat'
}

/**
 * 
 * @export
 * @interface ShowSurveyResponse
 */
export interface ShowSurveyResponse {
    /**
     * 
     * @type {ShowSurveyResponseSurvey}
     * @memberof ShowSurveyResponse
     */
    survey: ShowSurveyResponseSurvey;
    /**
     * 
     * @type {Array<ShowSurveyResponseQuestions>}
     * @memberof ShowSurveyResponse
     */
    questions: Array<ShowSurveyResponseQuestions>;
    /**
     * 
     * @type {Array<ShowSurveyResponseSelections>}
     * @memberof ShowSurveyResponse
     */
    selections: Array<ShowSurveyResponseSelections>;
}
/**
 * 
 * @export
 * @interface ShowSurveyResponseQuestions
 */
export interface ShowSurveyResponseQuestions {
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseQuestions
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseQuestions
     */
    survey_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseQuestions
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof ShowSurveyResponseQuestions
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ShowSurveyResponseSelections
 */
export interface ShowSurveyResponseSelections {
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseSelections
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseSelections
     */
    question_id: number;
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseSelections
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof ShowSurveyResponseSelections
     */
    content: string;
}
/**
 * 
 * @export
 * @interface ShowSurveyResponseSurvey
 */
export interface ShowSurveyResponseSurvey {
    /**
     * 
     * @type {number}
     * @memberof ShowSurveyResponseSurvey
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShowSurveyResponseSurvey
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShowSurveyResponseSurvey
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ShowSurveyResponseSurvey
     */
    survey_type: string;
}
/**
 * 
 * @export
 * @interface ShowUserAppearanceSettingResponse
 */
export interface ShowUserAppearanceSettingResponse {
    /**
     * 
     * @type {ShowUserAppearanceSettingResponseUserAppearanceSetting}
     * @memberof ShowUserAppearanceSettingResponse
     */
    user_appearance_setting: ShowUserAppearanceSettingResponseUserAppearanceSetting | null;
}
/**
 * 
 * @export
 * @interface ShowUserAppearanceSettingResponseUserAppearanceSetting
 */
export interface ShowUserAppearanceSettingResponseUserAppearanceSetting {
    /**
     * 
     * @type {string}
     * @memberof ShowUserAppearanceSettingResponseUserAppearanceSetting
     */
    setting: string;
}
/**
 * 
 * @export
 * @interface ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponse
 */
export interface ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponse {
    /**
     * 
     * @type {ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponse
     */
    one_on_one_company_analysis_monthly_score: ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore;
}
/**
 * 
 * @export
 * @interface ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
 */
export interface ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore {
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    member_relax_score_sum: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    member_relax_score_count: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    member_concentration_score_sum: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    member_concentration_score_count: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    manager_listening_score_sum: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    manager_listening_score_count: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    manager_approval_score_sum: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponseOneOnOneCompanyAnalysisMonthlyScore
     */
    manager_approval_score_count: number | null;
}
/**
 * 
 * @export
 * @interface SlackCreateRequest
 */
export interface SlackCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SlackCreateRequest
     */
    code: string;
}
/**
 * 
 * @export
 * @interface SolutionCsvImportError
 */
export interface SolutionCsvImportError {
    /**
     * 
     * @type {SolutionCsvImportErrorAttributes}
     * @memberof SolutionCsvImportError
     */
    attributes: SolutionCsvImportErrorAttributes;
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvImportError
     */
    messages: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvImportError
     */
    keys?: string;
}
/**
 * 
 * @export
 * @interface SolutionCsvImportErrorAttributes
 */
export interface SolutionCsvImportErrorAttributes {
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvImportErrorAttributes
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    conversation_tendency?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    listening_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    approval_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    aggression_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    openness_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    concentration_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvImportErrorAttributes
     */
    relax_grade?: number;
}
/**
 * 
 * @export
 * @interface SolutionCsvParseResult
 */
export interface SolutionCsvParseResult {
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvParseResult
     */
    status: number;
    /**
     * 
     * @type {SolutionCsvParseResultData}
     * @memberof SolutionCsvParseResult
     */
    data: SolutionCsvParseResultData;
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvParseResult
     */
    message: string;
}
/**
 * 
 * @export
 * @interface SolutionCsvParseResultData
 */
export interface SolutionCsvParseResultData {
    /**
     * 
     * @type {Array<SolutionCsvImportError>}
     * @memberof SolutionCsvParseResultData
     */
    errors: Array<SolutionCsvImportError>;
    /**
     * 
     * @type {ActionCsvParseResultDataCount}
     * @memberof SolutionCsvParseResultData
     */
    count: ActionCsvParseResultDataCount;
}
/**
 * 
 * @export
 * @interface SolutionCsvPostRequest
 */
export interface SolutionCsvPostRequest {
    /**
     * 
     * @type {Array<SolutionCsvRow>}
     * @memberof SolutionCsvPostRequest
     */
    rows: Array<SolutionCsvRow>;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvPostRequest
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvPostRequest
     */
    company_id: number | null;
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvPostRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface SolutionCsvRow
 */
export interface SolutionCsvRow {
    /**
     * 
     * @type {string}
     * @memberof SolutionCsvRow
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    conversation_tendency?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    listening_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    approval_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    aggression_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    openness_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    concentration_grade?: number;
    /**
     * 
     * @type {number}
     * @memberof SolutionCsvRow
     */
    relax_grade?: number;
}
/**
 * 
 * @export
 * @interface Suggestion
 */
export interface Suggestion {
    /**
     * 
     * @type {number}
     * @memberof Suggestion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    name: string;
    /**
     * 
     * @type {SuggestionTypes}
     * @memberof Suggestion
     */
    suggestion_type: SuggestionTypes;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    suggestion_type_label: string;
    /**
     * 
     * @type {string}
     * @memberof Suggestion
     */
    created_at: string;
    /**
     * 
     * @type {number}
     * @memberof Suggestion
     */
    company_id?: number | null;
    /**
     * 
     * @type {Company}
     * @memberof Suggestion
     */
    company: Company | null;
}
/**
 * 
 * @export
 * @interface SuggestionContent
 */
export interface SuggestionContent {
    /**
     * 
     * @type {string}
     * @memberof SuggestionContent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SuggestionContent
     */
    description: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SuggestionTypes {
    Action = 'action',
    PartnerAction = 'partner_action',
    Solution = 'solution'
}

/**
 * 
 * @export
 * @interface Survey
 */
export interface Survey {
    /**
     * 
     * @type {number}
     * @memberof Survey
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    survey_type: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    survey_type_label?: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Survey
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface SurveyCreateRequest
 */
export interface SurveyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveyCreateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyCreateRequest
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyCreateRequest
     */
    survey_type: number;
    /**
     * 
     * @type {Array<QuestionForm>}
     * @memberof SurveyCreateRequest
     */
    questions: Array<QuestionForm>;
}
/**
 * 
 * @export
 * @interface SurveyResponse
 */
export interface SurveyResponse {
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    survey_type: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyResponse
     */
    survey_type_id: number;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    survey_type_label: string;
    /**
     * 
     * @type {Array<Question>}
     * @memberof SurveyResponse
     */
    questions: Array<Question>;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyResponse
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface SurveyUpdateRequest
 */
export interface SurveyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SurveyUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUpdateRequest
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof SurveyUpdateRequest
     */
    survey_type: number;
    /**
     * 
     * @type {Array<QuestionForm>}
     * @memberof SurveyUpdateRequest
     */
    questions: Array<QuestionForm>;
}
/**
 * 
 * @export
 * @interface SyncOneOnOneMeetingAgendaItemsResponse
 */
export interface SyncOneOnOneMeetingAgendaItemsResponse {
    /**
     * 
     * @type {Array<IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems>}
     * @memberof SyncOneOnOneMeetingAgendaItemsResponse
     */
    one_on_one_meeting_agenda_items: Array<IndexOneOnOneMeetingAgendaItemsResponseOneOnOneMeetingAgendaItems>;
}
/**
 * 
 * @export
 * @interface SystemAdminCompanyCreateRequest
 */
export interface SystemAdminCompanyCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof SystemAdminCompanyCreateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    before_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    after_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    before_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    after_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    before_flat_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    after_flat_survey_id: number | null;
    /**
     * 
     * @type {CompanyPageSettingsUpdateRequest}
     * @memberof SystemAdminCompanyCreateRequest
     */
    company_page_settings?: CompanyPageSettingsUpdateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_ninebox_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_survey_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_sora_data_channel_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_sora_simulcast_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_show_interview_analysis_error: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_custom_mail_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_password_reset_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_one_on_one: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_show_live_checker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_show_not_using_one_on_one_entity_screen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    disabled: boolean;
    /**
     * 
     * @type {Auth0Organization}
     * @memberof SystemAdminCompanyCreateRequest
     */
    auth0_organization: Auth0Organization | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    unit_price: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_secure_live_movie_storage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyCreateRequest
     */
    is_check_ip_addr?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyCreateRequest
     */
    analysis_model_version: number;
}
/**
 * 
 * @export
 * @interface SystemAdminCompanyUpdateRequest
 */
export interface SystemAdminCompanyUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    before_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    after_owner_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    before_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    after_guest_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    before_flat_survey_id: number | null;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    after_flat_survey_id: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    physical_delete: boolean;
    /**
     * 
     * @type {Auth0Organization}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    auth0_organization: Auth0Organization | null;
    /**
     * 
     * @type {CompanyPageSettingsUpdateRequest}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    company_page_settings?: CompanyPageSettingsUpdateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_ninebox_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_survey_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_sora_data_channel_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_sora_simulcast_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_show_interview_analysis_error: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_custom_mail_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_password_reset_enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_one_on_one: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_show_live_checker: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_show_not_using_one_on_one_entity_screen: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    unit_price: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_secure_live_movie_storage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    is_check_ip_addr?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SystemAdminCompanyUpdateRequest
     */
    analysis_model_version: number;
}
/**
 * 
 * @export
 * @interface TranscriptionRow
 */
export interface TranscriptionRow {
    /**
     * 
     * @type {number}
     * @memberof TranscriptionRow
     */
    start_seconds: number;
    /**
     * 
     * @type {number}
     * @memberof TranscriptionRow
     */
    stop_seconds: number;
    /**
     * 
     * @type {string}
     * @memberof TranscriptionRow
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof TranscriptionRow
     */
    user_id: number;
}
/**
 * 
 * @export
 * @interface UpdateCompanyIpAddressRestrictionSettings
 */
export interface UpdateCompanyIpAddressRestrictionSettings {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyIpAddressRestrictionSettings
     */
    is_restriction: boolean;
}
/**
 * 
 * @export
 * @interface UpdateCompanyPasswordPolicies
 */
export interface UpdateCompanyPasswordPolicies {
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyPasswordPolicies
     */
    password_reset_duration_day: number;
}
/**
 * 
 * @export
 * @interface UpdateOneOnOneActionItem
 */
export interface UpdateOneOnOneActionItem {
    /**
     * 
     * @type {string}
     * @memberof UpdateOneOnOneActionItem
     */
    text: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOneOnOneActionItem
     */
    assignee_user_id: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateOneOnOneActionItem
     */
    due_on: string;
}
/**
 * 
 * @export
 * @interface UpdateOneOnOneActionItemsResponse
 */
export interface UpdateOneOnOneActionItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof UpdateOneOnOneActionItemsResponse
     */
    one_on_one_action_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface UpdateOneOnOneAgendaItem
 */
export interface UpdateOneOnOneAgendaItem {
    /**
     * 
     * @type {string}
     * @memberof UpdateOneOnOneAgendaItem
     */
    text: string;
}
/**
 * 
 * @export
 * @interface UpdateOneOnOneAgendaItemsResponse
 */
export interface UpdateOneOnOneAgendaItemsResponse {
    /**
     * 
     * @type {ShowManagementReportResponseManagementReportUser}
     * @memberof UpdateOneOnOneAgendaItemsResponse
     */
    one_on_one_agenda_item: ShowManagementReportResponseManagementReportUser;
}
/**
 * 
 * @export
 * @interface UpdateUserPasswordResetting
 */
export interface UpdateUserPasswordResetting {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordResetting
     */
    reset_at: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordResetting
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UpdateUserPasswordResettingResponse
 */
export interface UpdateUserPasswordResettingResponse {
    /**
     * 
     * @type {UpdateUserPasswordResettingResponseUserPasswordResetting}
     * @memberof UpdateUserPasswordResettingResponse
     */
    user_password_resetting: UpdateUserPasswordResettingResponseUserPasswordResetting;
}
/**
 * 
 * @export
 * @interface UpdateUserPasswordResettingResponseUserPasswordResetting
 */
export interface UpdateUserPasswordResettingResponseUserPasswordResetting {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordResettingResponseUserPasswordResetting
     */
    reset_at: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    fullname: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    live_client_id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    live_invitation_code: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    is_agreement?: boolean | null;
    /**
     * 
     * @type {AuthorityStatus}
     * @memberof User
     */
    authority: AuthorityStatus;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    authority_name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    section_fullnames: Array<string>;
    /**
     * 
     * @type {Array<Array<Array<Section>>>}
     * @memberof User
     */
    section_objects?: Array<Array<Array<Section>>>;
    /**
     * 
     * @type {Account}
     * @memberof User
     */
    account?: Account | null;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    company?: Company | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface UserAggregation
 */
export interface UserAggregation {
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    company_id: number;
    /**
     * 
     * @type {string}
     * @memberof UserAggregation
     */
    aggregate_type: string;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    month: number;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    number_of_users: number;
    /**
     * 
     * @type {string}
     * @memberof UserAggregation
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserAggregation
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    unit_price: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAggregation
     */
    billed_amount_excluding_tax: number;
}
/**
 * 
 * @export
 * @interface UserCompanyUpdateRequest
 */
export interface UserCompanyUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCompanyUpdateRequest
     */
    management_report_aggregation_started_at?: string;
    /**
     * 
     * @type {number}
     * @memberof UserCompanyUpdateRequest
     */
    management_report_aggregation_month_period?: number;
}
/**
 * 
 * @export
 * @interface UserCsvImportHistory
 */
export interface UserCsvImportHistory {
    /**
     * 
     * @type {number}
     * @memberof UserCsvImportHistory
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserCsvImportHistory
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof UserCsvImportHistory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserCsvImportHistory
     */
    updated_at: string;
    /**
     * 
     * @type {number}
     * @memberof UserCsvImportHistory
     */
    created_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserCsvImportHistory
     */
    updated_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserCsvImportHistory
     */
    disabled_count: number;
    /**
     * 
     * @type {string}
     * @memberof UserCsvImportHistory
     */
    status: string;
    /**
     * 
     * @type {User}
     * @memberof UserCsvImportHistory
     */
    user_name?: User;
    /**
     * 
     * @type {UserImportHistoryError}
     * @memberof UserCsvImportHistory
     */
    user_import_history_error?: UserImportHistoryError;
}
/**
 * 
 * @export
 * @interface UserCsvImportResponse
 */
export interface UserCsvImportResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCsvImportResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UserCsvImportResponse
     */
    message: string;
}
/**
 * 
 * @export
 * @interface UserCsvParseResult
 */
export interface UserCsvParseResult {
    /**
     * 
     * @type {number}
     * @memberof UserCsvParseResult
     */
    status: number;
    /**
     * 
     * @type {UserCsvParseResultData}
     * @memberof UserCsvParseResult
     */
    data: UserCsvParseResultData;
    /**
     * 
     * @type {string}
     * @memberof UserCsvParseResult
     */
    message: string;
}
/**
 * 
 * @export
 * @interface UserCsvParseResultData
 */
export interface UserCsvParseResultData {
    /**
     * 
     * @type {Array<UserImportError>}
     * @memberof UserCsvParseResultData
     */
    errors: Array<UserImportError>;
    /**
     * 
     * @type {Array<UserImportError>}
     * @memberof UserCsvParseResultData
     */
    warnings: Array<UserImportError>;
    /**
     * 
     * @type {UserCsvParseResultDataCount}
     * @memberof UserCsvParseResultData
     */
    count: UserCsvParseResultDataCount;
}
/**
 * 
 * @export
 * @interface UserCsvParseResultDataCount
 */
export interface UserCsvParseResultDataCount {
    /**
     * 
     * @type {number}
     * @memberof UserCsvParseResultDataCount
     */
    created_users?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCsvParseResultDataCount
     */
    updated_users?: number;
    /**
     * 
     * @type {number}
     * @memberof UserCsvParseResultDataCount
     */
    deleted_users?: number;
}
/**
 * 
 * @export
 * @interface UserCsvPostRequest
 */
export interface UserCsvPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UserCsvPostRequest
     */
    rows: string;
}
/**
 * 
 * @export
 * @interface UserImportError
 */
export interface UserImportError {
    /**
     * 
     * @type {UserImportErrorAttributes}
     * @memberof UserImportError
     */
    attributes: UserImportErrorAttributes;
    /**
     * 
     * @type {string}
     * @memberof UserImportError
     */
    messages: string;
    /**
     * 
     * @type {string}
     * @memberof UserImportError
     */
    keys?: string;
}
/**
 * 
 * @export
 * @interface UserImportErrorAttributes
 */
export interface UserImportErrorAttributes {
    /**
     * 
     * @type {string}
     * @memberof UserImportErrorAttributes
     */
    external_id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserImportErrorAttributes
     */
    fullname?: string;
}
/**
 * 
 * @export
 * @interface UserImportHistories
 */
export interface UserImportHistories {
    /**
     * 
     * @type {Array<UserImportHistory>}
     * @memberof UserImportHistories
     */
    histories: Array<UserImportHistory>;
    /**
     * 
     * @type {Meta}
     * @memberof UserImportHistories
     */
    meta: Meta;
}
/**
 * 
 * @export
 * @interface UserImportHistory
 */
export interface UserImportHistory {
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    admin_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    created_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    updated_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistory
     */
    disabled_count: number;
    /**
     * 
     * @type {string}
     * @memberof UserImportHistory
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof UserImportHistory
     */
    user_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserImportHistory
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserImportHistory
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface UserImportHistoryError
 */
export interface UserImportHistoryError {
    /**
     * 
     * @type {number}
     * @memberof UserImportHistoryError
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserImportHistoryError
     */
    user_import_history_id: number;
    /**
     * 
     * @type {Array<UserImportError>}
     * @memberof UserImportHistoryError
     */
    error_detail: Array<UserImportError>;
}
/**
 * 
 * @export
 * @interface UserInterview
 */
export interface UserInterview {
    /**
     * 
     * @type {number}
     * @memberof UserInterview
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterview
     */
    interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterview
     */
    user_id: number;
    /**
     * 
     * @type {User}
     * @memberof UserInterview
     */
    user: User | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterview
     */
    before_survey_completed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterview
     */
    after_survey_completed: boolean;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterview
     */
    before_survey?: UserInterviewShowResponseBeforeSurvey;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterview
     */
    after_survey?: UserInterviewShowResponseBeforeSurvey;
}
/**
 * 
 * @export
 * @interface UserInterviewResponse
 */
export interface UserInterviewResponse {
    /**
     * 
     * @type {number}
     * @memberof UserInterviewResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterviewResponse
     */
    interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterviewResponse
     */
    user_id: number;
    /**
     * 
     * @type {Interview}
     * @memberof UserInterviewResponse
     */
    interview: Interview;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewResponse
     */
    before_survey_completed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewResponse
     */
    after_survey_completed: boolean;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterviewResponse
     */
    before_survey?: UserInterviewShowResponseBeforeSurvey;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterviewResponse
     */
    after_survey?: UserInterviewShowResponseBeforeSurvey;
    /**
     * 
     * @type {User}
     * @memberof UserInterviewResponse
     */
    user: User;
    /**
     * 
     * @type {Answer}
     * @memberof UserInterviewResponse
     */
    answer: Answer;
    /**
     * 
     * @type {string}
     * @memberof UserInterviewResponse
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserInterviewResponse
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface UserInterviewShowResponse
 */
export interface UserInterviewShowResponse {
    /**
     * 
     * @type {number}
     * @memberof UserInterviewShowResponse
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterviewShowResponse
     */
    interview_id: number;
    /**
     * 
     * @type {number}
     * @memberof UserInterviewShowResponse
     */
    user_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewShowResponse
     */
    before_survey_completed: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewShowResponse
     */
    after_survey_completed: boolean;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterviewShowResponse
     */
    before_survey?: UserInterviewShowResponseBeforeSurvey;
    /**
     * 
     * @type {UserInterviewShowResponseBeforeSurvey}
     * @memberof UserInterviewShowResponse
     */
    after_survey?: UserInterviewShowResponseBeforeSurvey;
}
/**
 * 
 * @export
 * @interface UserInterviewShowResponseBeforeSurvey
 */
export interface UserInterviewShowResponseBeforeSurvey {
    /**
     * 
     * @type {Array<Answer>}
     * @memberof UserInterviewShowResponseBeforeSurvey
     */
    answer?: Array<Answer>;
}
/**
 * 
 * @export
 * @interface UserInterviewUpdateRequest
 */
export interface UserInterviewUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UserInterviewUpdateRequest
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewUpdateRequest
     */
    after_survey_completed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserInterviewUpdateRequest
     */
    before_survey_completed?: boolean;
    /**
     * 
     * @type {Array<AnswerForm>}
     * @memberof UserInterviewUpdateRequest
     */
    answers?: Array<AnswerForm>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserMigrateStatus {
    Completed = 'completed',
    InProgress = 'in_progress'
}

/**
 * 
 * @export
 * @interface UserNotifies
 */
export interface UserNotifies {
    /**
     * 
     * @type {Array<UserNotifySetting>}
     * @memberof UserNotifies
     */
    user_notify_settings: Array<UserNotifySetting>;
    /**
     * 
     * @type {Array<UserNotifySchedule>}
     * @memberof UserNotifies
     */
    user_notify_schedules: Array<UserNotifySchedule>;
}
/**
 * 
 * @export
 * @interface UserNotifySchedule
 */
export interface UserNotifySchedule {
    /**
     * 
     * @type {number}
     * @memberof UserNotifySchedule
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserNotifySchedule
     */
    user_id: number;
    /**
     * 
     * @type {NotifyType}
     * @memberof UserNotifySchedule
     */
    notify_type: NotifyType;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof UserNotifySchedule
     */
    day_of_week: DayOfWeek;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySchedule
     */
    start_time_str: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySchedule
     */
    end_time_str: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifySchedule
     */
    is_active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySchedule
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySchedule
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserNotifySetterType {
    Company = 'company',
    User = 'user'
}

/**
 * 
 * @export
 * @interface UserNotifySetting
 */
export interface UserNotifySetting {
    /**
     * 
     * @type {number}
     * @memberof UserNotifySetting
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserNotifySetting
     */
    user_id: number;
    /**
     * 
     * @type {UserNotifyType}
     * @memberof UserNotifySetting
     */
    notify_type: UserNotifyType;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifySetting
     */
    is_active_slack: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotifySetting
     */
    is_active_email: boolean;
    /**
     * 
     * @type {UserNotifySetterType}
     * @memberof UserNotifySetting
     */
    slack_setting_setter: UserNotifySetterType;
    /**
     * 
     * @type {UserNotifySetterType}
     * @memberof UserNotifySetting
     */
    email_setting_setter: UserNotifySetterType;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySetting
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotifySetting
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum UserNotifyType {
    AfterAnalysisCompleted = 'after_analysis_completed'
}

/**
 * 
 * @export
 * @interface UserRelation
 */
export interface UserRelation {
    /**
     * 
     * @type {number}
     * @memberof UserRelation
     */
    id: number;
    /**
     * 
     * @type {User}
     * @memberof UserRelation
     */
    owner: User;
    /**
     * 
     * @type {User}
     * @memberof UserRelation
     */
    guest: User;
    /**
     * 
     * @type {number}
     * @memberof UserRelation
     */
    count: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRelation
     */
    pinned_by_owner: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRelation
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof UserRelation
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface UserRelationUpdateRequest
 */
export interface UserRelationUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UserRelationUpdateRequest
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRelationUpdateRequest
     */
    pinned_by_owner: boolean;
}
/**
 * 
 * @export
 * @interface UserRelationsResponse
 */
export interface UserRelationsResponse {
    /**
     * 
     * @type {Array<UserRelationsResponseOwners>}
     * @memberof UserRelationsResponse
     */
    owners: Array<UserRelationsResponseOwners>;
    /**
     * 
     * @type {Array<UserRelationsResponseGuests>}
     * @memberof UserRelationsResponse
     */
    guests: Array<UserRelationsResponseGuests>;
}
/**
 * 
 * @export
 * @interface UserRelationsResponseGuests
 */
export interface UserRelationsResponseGuests {
    /**
     * 
     * @type {number}
     * @memberof UserRelationsResponseGuests
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserRelationsResponseGuests
     */
    guest_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRelationsResponseGuests
     */
    pinned_by_owner: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRelationsResponseGuests
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface UserRelationsResponseOwners
 */
export interface UserRelationsResponseOwners {
    /**
     * 
     * @type {number}
     * @memberof UserRelationsResponseOwners
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UserRelationsResponseOwners
     */
    owner_id: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserRelationsResponseOwners
     */
    pinned_by_owner: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRelationsResponseOwners
     */
    updated_at: string;
}
/**
 * 
 * @export
 * @interface UserResetPasswordUrl
 */
export interface UserResetPasswordUrl {
    /**
     * 
     * @type {string}
     * @memberof UserResetPasswordUrl
     */
    reset_password_url: string;
}
/**
 * 
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    last_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateRequest
     */
    authority: string;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Admin
         * @summary /admin/admins
         * @param {AdminCreateRequest} [adminCreateRequest] request body of admin create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin: async (adminCreateRequest?: AdminCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete admin account
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAdmin', 'id', id)
            const localVarPath = `/v1/admin/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Admin
         * @summary /v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmin: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdmin', 'id', id)
            const localVarPath = `/v1/admin/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Admin List
         * @summary /v1/admin/admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch Admin data
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {AdminUpdateRequest} [adminUpdateRequest] request body of admin update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdmin: async (id: number, adminUpdateRequest?: AdminUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAdmin', 'id', id)
            const localVarPath = `/v1/admin/admins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Admin
         * @summary /admin/admins
         * @param {AdminCreateRequest} [adminCreateRequest] request body of admin create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdmin(adminCreateRequest?: AdminCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAdmin(adminCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete admin account
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdmin(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Admin
         * @summary /v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdmin(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdmin(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Admin List
         * @summary /v1/admin/admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Admin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch Admin data
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {AdminUpdateRequest} [adminUpdateRequest] request body of admin update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAdmin(id: number, adminUpdateRequest?: AdminUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAdmin(id, adminUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * Create Admin
         * @summary /admin/admins
         * @param {AdminCreateRequest} [adminCreateRequest] request body of admin create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdmin(adminCreateRequest?: AdminCreateRequest, options?: any): AxiosPromise<Admin> {
            return localVarFp.createAdmin(adminCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * delete admin account
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdmin(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Admin
         * @summary /v1/admin/admins/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdmin(id: number, options?: any): AxiosPromise<Admin> {
            return localVarFp.getAdmin(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Admin List
         * @summary /v1/admin/admins
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminList(options?: any): AxiosPromise<Array<Admin>> {
            return localVarFp.getAdminList(options).then((request) => request(axios, basePath));
        },
        /**
         * Patch Admin data
         * @summary v1/admin/admins/{id}
         * @param {number} id 
         * @param {AdminUpdateRequest} [adminUpdateRequest] request body of admin update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdmin(id: number, adminUpdateRequest?: AdminUpdateRequest, options?: any): AxiosPromise<Admin> {
            return localVarFp.patchAdmin(id, adminUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * Create Admin
     * @summary /admin/admins
     * @param {AdminCreateRequest} [adminCreateRequest] request body of admin create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public createAdmin(adminCreateRequest?: AdminCreateRequest, options?: any) {
        return AdminApiFp(this.configuration).createAdmin(adminCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete admin account
     * @summary v1/admin/admins/{id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public deleteAdmin(id: number, options?: any) {
        return AdminApiFp(this.configuration).deleteAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Admin
     * @summary /v1/admin/admins/{id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdmin(id: number, options?: any) {
        return AdminApiFp(this.configuration).getAdmin(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Admin List
     * @summary /v1/admin/admins
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public getAdminList(options?: any) {
        return AdminApiFp(this.configuration).getAdminList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch Admin data
     * @summary v1/admin/admins/{id}
     * @param {number} id 
     * @param {AdminUpdateRequest} [adminUpdateRequest] request body of admin update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public patchAdmin(id: number, adminUpdateRequest?: AdminUpdateRequest, options?: any) {
        return AdminApiFp(this.configuration).patchAdmin(id, adminUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminInterviewApi - axios parameter creator
 * @export
 */
export const AdminInterviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search Interview In Admin
         * @summary /v1/admin/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewInAdmin: async (interviewId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('getInterviewInAdmin', 'interviewId', interviewId)
            const localVarPath = `/v1/admin/interviews/{interviewId}`
                .replace(`{${"interviewId"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInterviewApi - functional programming interface
 * @export
 */
export const AdminInterviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInterviewApiAxiosParamCreator(configuration)
    return {
        /**
         * Search Interview In Admin
         * @summary /v1/admin/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviewInAdmin(interviewId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInterviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviewInAdmin(interviewId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminInterviewApi - factory interface
 * @export
 */
export const AdminInterviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInterviewApiFp(configuration)
    return {
        /**
         * Search Interview In Admin
         * @summary /v1/admin/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewInAdmin(interviewId: number, options?: any): AxiosPromise<AdminInterviewResponse> {
            return localVarFp.getInterviewInAdmin(interviewId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminInterviewApi - object-oriented interface
 * @export
 * @class AdminInterviewApi
 * @extends {BaseAPI}
 */
export class AdminInterviewApi extends BaseAPI {
    /**
     * Search Interview In Admin
     * @summary /v1/admin/interviews/{interviewId}
     * @param {number} interviewId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInterviewApi
     */
    public getInterviewInAdmin(interviewId: number, options?: any) {
        return AdminInterviewApiFp(this.configuration).getInterviewInAdmin(interviewId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminInterviewsApi - axios parameter creator
 * @export
 */
export const AdminInterviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search Interviews In Admin
         * @summary /v1/admin/interviews
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewsInAdmin: async (companyId: number, page?: number, per?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getInterviewsInAdmin', 'companyId', companyId)
            const localVarPath = `/v1/admin/interviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['company_id'] = companyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (per !== undefined) {
                localVarQueryParameter['per'] = per;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminInterviewsApi - functional programming interface
 * @export
 */
export const AdminInterviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminInterviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * Search Interviews In Admin
         * @summary /v1/admin/interviews
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviewsInAdmin(companyId: number, page?: number, per?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminInterviews>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviewsInAdmin(companyId, page, per, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminInterviewsApi - factory interface
 * @export
 */
export const AdminInterviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminInterviewsApiFp(configuration)
    return {
        /**
         * Search Interviews In Admin
         * @summary /v1/admin/interviews
         * @param {number} companyId 
         * @param {number} [page] 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewsInAdmin(companyId: number, page?: number, per?: number, options?: any): AxiosPromise<AdminInterviews> {
            return localVarFp.getInterviewsInAdmin(companyId, page, per, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminInterviewsApi - object-oriented interface
 * @export
 * @class AdminInterviewsApi
 * @extends {BaseAPI}
 */
export class AdminInterviewsApi extends BaseAPI {
    /**
     * Search Interviews In Admin
     * @summary /v1/admin/interviews
     * @param {number} companyId 
     * @param {number} [page] 
     * @param {number} [per] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminInterviewsApi
     */
    public getInterviewsInAdmin(companyId: number, page?: number, per?: number, options?: any) {
        return AdminInterviewsApiFp(this.configuration).getInterviewsInAdmin(companyId, page, per, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUsersApi - axios parameter creator
 * @export
 */
export const AdminUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Admin User Import History
         * @summary /v1/admin/users/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserImportHistory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminUserImportHistory', 'id', id)
            const localVarPath = `/v1/admin/users/csv_import_history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUsersApi - functional programming interface
 * @export
 */
export const AdminUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Admin User Import History
         * @summary /v1/admin/users/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminUserImportHistory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCsvImportHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminUserImportHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUsersApi - factory interface
 * @export
 */
export const AdminUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUsersApiFp(configuration)
    return {
        /**
         * Get Admin User Import History
         * @summary /v1/admin/users/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminUserImportHistory(id: number, options?: any): AxiosPromise<UserCsvImportHistory> {
            return localVarFp.getAdminUserImportHistory(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUsersApi - object-oriented interface
 * @export
 * @class AdminUsersApi
 * @extends {BaseAPI}
 */
export class AdminUsersApi extends BaseAPI {
    /**
     * Get Admin User Import History
     * @summary /v1/admin/users/csv_import_history/{id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUsersApi
     */
    public getAdminUserImportHistory(id: number, options?: any) {
        return AdminUsersApiFp(this.configuration).getAdminUserImportHistory(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuditLogApi - axios parameter creator
 * @export
 */
export const AuditLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download audit logs
         * @summary /v1/audit_logs
         * @param {number} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogs: async (companyId: number, startDate: string, endDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('downloadAuditLogs', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('downloadAuditLogs', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('downloadAuditLogs', 'endDate', endDate)
            const localVarPath = `/v1/company/{companyId}/audit_logs`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuditLogApi - functional programming interface
 * @export
 */
export const AuditLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuditLogApiAxiosParamCreator(configuration)
    return {
        /**
         * Download audit logs
         * @summary /v1/audit_logs
         * @param {number} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAuditLogs(companyId: number, startDate: string, endDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadAuditLogs(companyId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuditLogApi - factory interface
 * @export
 */
export const AuditLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuditLogApiFp(configuration)
    return {
        /**
         * Download audit logs
         * @summary /v1/audit_logs
         * @param {number} companyId 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAuditLogs(companyId: number, startDate: string, endDate: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadAuditLogs(companyId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuditLogApi - object-oriented interface
 * @export
 * @class AuditLogApi
 * @extends {BaseAPI}
 */
export class AuditLogApi extends BaseAPI {
    /**
     * Download audit logs
     * @summary /v1/audit_logs
     * @param {number} companyId 
     * @param {string} startDate 
     * @param {string} endDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuditLogApi
     */
    public downloadAuditLogs(companyId: number, startDate: string, endDate: string, options?: any) {
        return AuditLogApiFp(this.configuration).downloadAuditLogs(companyId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Auth0OrganizationApi - axios parameter creator
 * @export
 */
export const Auth0OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Auth0 connections
         * @summary /v1/admin/auth0_organizarions/connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuth0Connections: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/auth0_organizations/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Auth0OrganizationApi - functional programming interface
 * @export
 */
export const Auth0OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Auth0OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Auth0 connections
         * @summary /v1/admin/auth0_organizarions/connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuth0Connections(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Auth0Connection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuth0Connections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Auth0OrganizationApi - factory interface
 * @export
 */
export const Auth0OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Auth0OrganizationApiFp(configuration)
    return {
        /**
         * Get Auth0 connections
         * @summary /v1/admin/auth0_organizarions/connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuth0Connections(options?: any): AxiosPromise<Array<Auth0Connection>> {
            return localVarFp.getAuth0Connections(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Auth0OrganizationApi - object-oriented interface
 * @export
 * @class Auth0OrganizationApi
 * @extends {BaseAPI}
 */
export class Auth0OrganizationApi extends BaseAPI {
    /**
     * Get Auth0 connections
     * @summary /v1/admin/auth0_organizarions/connections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Auth0OrganizationApi
     */
    public getAuth0Connections(options?: any) {
        return Auth0OrganizationApiFp(this.configuration).getAuth0Connections(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Auth0PasswordChangedCallbackApi - axios parameter creator
 * @export
 */
export const Auth0PasswordChangedCallbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update user password resetting data
         * @summary /v1/auth0_password_changed_callback
         * @param {UpdateUserPasswordResetting} [updateUserPasswordResetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPasswordResetting: async (updateUserPasswordResetting?: UpdateUserPasswordResetting, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth0_password_changed_callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPasswordResetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Auth0PasswordChangedCallbackApi - functional programming interface
 * @export
 */
export const Auth0PasswordChangedCallbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Auth0PasswordChangedCallbackApiAxiosParamCreator(configuration)
    return {
        /**
         * Update user password resetting data
         * @summary /v1/auth0_password_changed_callback
         * @param {UpdateUserPasswordResetting} [updateUserPasswordResetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPasswordResetting(updateUserPasswordResetting?: UpdateUserPasswordResetting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserPasswordResettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPasswordResetting(updateUserPasswordResetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Auth0PasswordChangedCallbackApi - factory interface
 * @export
 */
export const Auth0PasswordChangedCallbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Auth0PasswordChangedCallbackApiFp(configuration)
    return {
        /**
         * Update user password resetting data
         * @summary /v1/auth0_password_changed_callback
         * @param {UpdateUserPasswordResetting} [updateUserPasswordResetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPasswordResetting(updateUserPasswordResetting?: UpdateUserPasswordResetting, options?: any): AxiosPromise<UpdateUserPasswordResettingResponse> {
            return localVarFp.updateUserPasswordResetting(updateUserPasswordResetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Auth0PasswordChangedCallbackApi - object-oriented interface
 * @export
 * @class Auth0PasswordChangedCallbackApi
 * @extends {BaseAPI}
 */
export class Auth0PasswordChangedCallbackApi extends BaseAPI {
    /**
     * Update user password resetting data
     * @summary /v1/auth0_password_changed_callback
     * @param {UpdateUserPasswordResetting} [updateUserPasswordResetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Auth0PasswordChangedCallbackApi
     */
    public updateUserPasswordResetting(updateUserPasswordResetting?: UpdateUserPasswordResetting, options?: any) {
        return Auth0PasswordChangedCallbackApiFp(this.configuration).updateUserPasswordResetting(updateUserPasswordResetting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search Companies
         * @summary /v1/companies
         * @param {string} [name] fuzzy search for company name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies: async (name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user aggregations data
         * @summary /v1/companies/{companyId}/user_aggregations
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAggregations: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserAggregations', 'companyId', companyId)
            const localVarPath = `/v1/admin/companies/{companyId}/user_aggregations`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update company data
         * @param {number} companyId 
         * @param {SystemAdminCompanyUpdateRequest} [systemAdminCompanyUpdateRequest] request body of company update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompany: async (companyId: number, systemAdminCompanyUpdateRequest?: SystemAdminCompanyUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('patchCompany', 'companyId', companyId)
            const localVarPath = `/v1/admin/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemAdminCompanyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create company
         * @summary /v1/companies
         * @param {SystemAdminCompanyCreateRequest} [systemAdminCompanyCreateRequest] request body of company create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany: async (systemAdminCompanyCreateRequest?: SystemAdminCompanyCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemAdminCompanyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAdminCompanies: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showAdminCompanies', 'companyId', companyId)
            const localVarPath = `/v1/admin/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * Search Companies
         * @summary /v1/companies
         * @param {string} [name] fuzzy search for company name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanies(name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanies(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user aggregations data
         * @summary /v1/companies/{companyId}/user_aggregations
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAggregations(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAggregation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAggregations(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update company data
         * @param {number} companyId 
         * @param {SystemAdminCompanyUpdateRequest} [systemAdminCompanyUpdateRequest] request body of company update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCompany(companyId: number, systemAdminCompanyUpdateRequest?: SystemAdminCompanyUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCompany(companyId, systemAdminCompanyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create company
         * @summary /v1/companies
         * @param {SystemAdminCompanyCreateRequest} [systemAdminCompanyCreateRequest] request body of company create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompany(systemAdminCompanyCreateRequest?: SystemAdminCompanyCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompany(systemAdminCompanyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showAdminCompanies(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowAdminCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showAdminCompanies(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesApiFp(configuration)
    return {
        /**
         * Search Companies
         * @summary /v1/companies
         * @param {string} [name] fuzzy search for company name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanies(name?: string, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.getCompanies(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user aggregations data
         * @summary /v1/companies/{companyId}/user_aggregations
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAggregations(companyId: number, options?: any): AxiosPromise<Array<UserAggregation>> {
            return localVarFp.getUserAggregations(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update company data
         * @param {number} companyId 
         * @param {SystemAdminCompanyUpdateRequest} [systemAdminCompanyUpdateRequest] request body of company update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCompany(companyId: number, systemAdminCompanyUpdateRequest?: SystemAdminCompanyUpdateRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.patchCompany(companyId, systemAdminCompanyUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create company
         * @summary /v1/companies
         * @param {SystemAdminCompanyCreateRequest} [systemAdminCompanyCreateRequest] request body of company create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompany(systemAdminCompanyCreateRequest?: SystemAdminCompanyCreateRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.postCompany(systemAdminCompanyCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAdminCompanies(companyId: number, options?: any): AxiosPromise<ShowAdminCompaniesResponse> {
            return localVarFp.showAdminCompanies(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
    /**
     * Search Companies
     * @summary /v1/companies
     * @param {string} [name] fuzzy search for company name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getCompanies(name?: string, options?: any) {
        return CompaniesApiFp(this.configuration).getCompanies(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user aggregations data
     * @summary /v1/companies/{companyId}/user_aggregations
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public getUserAggregations(companyId: number, options?: any) {
        return CompaniesApiFp(this.configuration).getUserAggregations(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update company data
     * @param {number} companyId 
     * @param {SystemAdminCompanyUpdateRequest} [systemAdminCompanyUpdateRequest] request body of company update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public patchCompany(companyId: number, systemAdminCompanyUpdateRequest?: SystemAdminCompanyUpdateRequest, options?: any) {
        return CompaniesApiFp(this.configuration).patchCompany(companyId, systemAdminCompanyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create company
     * @summary /v1/companies
     * @param {SystemAdminCompanyCreateRequest} [systemAdminCompanyCreateRequest] request body of company create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public postCompany(systemAdminCompanyCreateRequest?: SystemAdminCompanyCreateRequest, options?: any) {
        return CompaniesApiFp(this.configuration).postCompany(systemAdminCompanyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesApi
     */
    public showAdminCompanies(companyId: number, options?: any) {
        return CompaniesApiFp(this.configuration).showAdminCompanies(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexCompanies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update company settings by admin user
         * @summary /v1/company/{companyId}
         * @param {number} companyId 
         * @param {UserCompanyUpdateRequest} [userCompanyUpdateRequest] Update company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany: async (companyId: number, userCompanyUpdateRequest?: UserCompanyUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCompanyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update notify enabled default
         * @summary /v1/company/{companyId}/notify_enabled_default
         * @param {number} companyId 
         * @param {NotifyEnabledDefaultUpdateRequest} [notifyEnabledDefaultUpdateRequest] Update notify enabled default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyEnabledDefault: async (companyId: number, notifyEnabledDefaultUpdateRequest?: NotifyEnabledDefaultUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateNotifyEnabledDefault', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/notify_enabled_default`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notifyEnabledDefaultUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexCompanies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update company settings by admin user
         * @summary /v1/company/{companyId}
         * @param {number} companyId 
         * @param {UserCompanyUpdateRequest} [userCompanyUpdateRequest] Update company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompany(companyId: number, userCompanyUpdateRequest?: UserCompanyUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompany(companyId, userCompanyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update notify enabled default
         * @summary /v1/company/{companyId}/notify_enabled_default
         * @param {number} companyId 
         * @param {NotifyEnabledDefaultUpdateRequest} [notifyEnabledDefaultUpdateRequest] Update notify enabled default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifyEnabledDefault(companyId: number, notifyEnabledDefaultUpdateRequest?: NotifyEnabledDefaultUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotifyEnabledDefault(companyId, notifyEnabledDefaultUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexCompanies(options?: any): AxiosPromise<IndexCompaniesResponse> {
            return localVarFp.indexCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * Update company settings by admin user
         * @summary /v1/company/{companyId}
         * @param {number} companyId 
         * @param {UserCompanyUpdateRequest} [userCompanyUpdateRequest] Update company settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompany(companyId: number, userCompanyUpdateRequest?: UserCompanyUpdateRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.updateCompany(companyId, userCompanyUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update notify enabled default
         * @summary /v1/company/{companyId}/notify_enabled_default
         * @param {number} companyId 
         * @param {NotifyEnabledDefaultUpdateRequest} [notifyEnabledDefaultUpdateRequest] Update notify enabled default
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifyEnabledDefault(companyId: number, notifyEnabledDefaultUpdateRequest?: NotifyEnabledDefaultUpdateRequest, options?: any): AxiosPromise<Company> {
            return localVarFp.updateNotifyEnabledDefault(companyId, notifyEnabledDefaultUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public indexCompanies(options?: any) {
        return CompanyApiFp(this.configuration).indexCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update company settings by admin user
     * @summary /v1/company/{companyId}
     * @param {number} companyId 
     * @param {UserCompanyUpdateRequest} [userCompanyUpdateRequest] Update company settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompany(companyId: number, userCompanyUpdateRequest?: UserCompanyUpdateRequest, options?: any) {
        return CompanyApiFp(this.configuration).updateCompany(companyId, userCompanyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update notify enabled default
     * @summary /v1/company/{companyId}/notify_enabled_default
     * @param {number} companyId 
     * @param {NotifyEnabledDefaultUpdateRequest} [notifyEnabledDefaultUpdateRequest] Update notify enabled default
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateNotifyEnabledDefault(companyId: number, notifyEnabledDefaultUpdateRequest?: NotifyEnabledDefaultUpdateRequest, options?: any) {
        return CompanyApiFp(this.configuration).updateNotifyEnabledDefault(companyId, notifyEnabledDefaultUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyAllowedIpsApi - axios parameter creator
 * @export
 */
export const CompanyAllowedIpsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create company allowed ips
         * @param {number} companyId 
         * @param {CompanyAllowedIpsCreateRequest} [companyAllowedIpsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyAllowedIps: async (companyId: number, companyAllowedIpsCreateRequest?: CompanyAllowedIpsCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCompanyAllowedIps', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_allowed_ips`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAllowedIpsCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company allowed ips
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAllowedIps: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyAllowedIps', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_allowed_ips`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyAllowedIpsApi - functional programming interface
 * @export
 */
export const CompanyAllowedIpsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyAllowedIpsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create company allowed ips
         * @param {number} companyId 
         * @param {CompanyAllowedIpsCreateRequest} [companyAllowedIpsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyAllowedIps(companyId: number, companyAllowedIpsCreateRequest?: CompanyAllowedIpsCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyAllowedIps(companyId, companyAllowedIpsCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company allowed ips
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyAllowedIps(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyAllowedIp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyAllowedIps(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyAllowedIpsApi - factory interface
 * @export
 */
export const CompanyAllowedIpsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyAllowedIpsApiFp(configuration)
    return {
        /**
         * Create company allowed ips
         * @param {number} companyId 
         * @param {CompanyAllowedIpsCreateRequest} [companyAllowedIpsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyAllowedIps(companyId: number, companyAllowedIpsCreateRequest?: CompanyAllowedIpsCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createCompanyAllowedIps(companyId, companyAllowedIpsCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company allowed ips
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyAllowedIps(companyId: number, options?: any): AxiosPromise<Array<CompanyAllowedIp>> {
            return localVarFp.getCompanyAllowedIps(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyAllowedIpsApi - object-oriented interface
 * @export
 * @class CompanyAllowedIpsApi
 * @extends {BaseAPI}
 */
export class CompanyAllowedIpsApi extends BaseAPI {
    /**
     * Create company allowed ips
     * @param {number} companyId 
     * @param {CompanyAllowedIpsCreateRequest} [companyAllowedIpsCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAllowedIpsApi
     */
    public createCompanyAllowedIps(companyId: number, companyAllowedIpsCreateRequest?: CompanyAllowedIpsCreateRequest, options?: any) {
        return CompanyAllowedIpsApiFp(this.configuration).createCompanyAllowedIps(companyId, companyAllowedIpsCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company allowed ips
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAllowedIpsApi
     */
    public getCompanyAllowedIps(companyId: number, options?: any) {
        return CompanyAllowedIpsApiFp(this.configuration).getCompanyAllowedIps(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyIpAddressRestrictionSettingsApi - axios parameter creator
 * @export
 */
export const CompanyIpAddressRestrictionSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get company ip address restriction setting
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyIpAddressRestrictionSettings: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyIpAddressRestrictionSettings', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_ip_address_restriction_settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update company ip address restriction setting
         * @param {number} companyId 
         * @param {UpdateCompanyIpAddressRestrictionSettings} [updateCompanyIpAddressRestrictionSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyIpAddressRestrictionSettings: async (companyId: number, updateCompanyIpAddressRestrictionSettings?: UpdateCompanyIpAddressRestrictionSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyIpAddressRestrictionSettings', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_ip_address_restriction_settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyIpAddressRestrictionSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyIpAddressRestrictionSettingsApi - functional programming interface
 * @export
 */
export const CompanyIpAddressRestrictionSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyIpAddressRestrictionSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get company ip address restriction setting
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyIpAddressRestrictionSettings(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyIpAddressRestrictionSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyIpAddressRestrictionSettings(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update company ip address restriction setting
         * @param {number} companyId 
         * @param {UpdateCompanyIpAddressRestrictionSettings} [updateCompanyIpAddressRestrictionSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyIpAddressRestrictionSettings(companyId: number, updateCompanyIpAddressRestrictionSettings?: UpdateCompanyIpAddressRestrictionSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyIpAddressRestrictionSettings(companyId, updateCompanyIpAddressRestrictionSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyIpAddressRestrictionSettingsApi - factory interface
 * @export
 */
export const CompanyIpAddressRestrictionSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyIpAddressRestrictionSettingsApiFp(configuration)
    return {
        /**
         * Get company ip address restriction setting
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyIpAddressRestrictionSettings(companyId: number, options?: any): AxiosPromise<CompanyIpAddressRestrictionSettings> {
            return localVarFp.getCompanyIpAddressRestrictionSettings(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update company ip address restriction setting
         * @param {number} companyId 
         * @param {UpdateCompanyIpAddressRestrictionSettings} [updateCompanyIpAddressRestrictionSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyIpAddressRestrictionSettings(companyId: number, updateCompanyIpAddressRestrictionSettings?: UpdateCompanyIpAddressRestrictionSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateCompanyIpAddressRestrictionSettings(companyId, updateCompanyIpAddressRestrictionSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyIpAddressRestrictionSettingsApi - object-oriented interface
 * @export
 * @class CompanyIpAddressRestrictionSettingsApi
 * @extends {BaseAPI}
 */
export class CompanyIpAddressRestrictionSettingsApi extends BaseAPI {
    /**
     * Get company ip address restriction setting
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyIpAddressRestrictionSettingsApi
     */
    public getCompanyIpAddressRestrictionSettings(companyId: number, options?: any) {
        return CompanyIpAddressRestrictionSettingsApiFp(this.configuration).getCompanyIpAddressRestrictionSettings(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update company ip address restriction setting
     * @param {number} companyId 
     * @param {UpdateCompanyIpAddressRestrictionSettings} [updateCompanyIpAddressRestrictionSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyIpAddressRestrictionSettingsApi
     */
    public updateCompanyIpAddressRestrictionSettings(companyId: number, updateCompanyIpAddressRestrictionSettings?: UpdateCompanyIpAddressRestrictionSettings, options?: any) {
        return CompanyIpAddressRestrictionSettingsApiFp(this.configuration).updateCompanyIpAddressRestrictionSettings(companyId, updateCompanyIpAddressRestrictionSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyPasswordPoliciesApi - axios parameter creator
 * @export
 */
export const CompanyPasswordPoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get company password policy
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPasswordPolicies: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyPasswordPolicies', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_password_policies`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateCompanyPasswordPolicies} [updateCompanyPasswordPolicies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPasswordPolicies: async (companyId: number, updateCompanyPasswordPolicies?: UpdateCompanyPasswordPolicies, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyPasswordPolicies', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_password_policies`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyPasswordPolicies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyPasswordPoliciesApi - functional programming interface
 * @export
 */
export const CompanyPasswordPoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyPasswordPoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get company password policy
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyPasswordPolicies(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPasswordPolicies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyPasswordPolicies(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateCompanyPasswordPolicies} [updateCompanyPasswordPolicies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyPasswordPolicies(companyId: number, updateCompanyPasswordPolicies?: UpdateCompanyPasswordPolicies, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPasswordPolicies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyPasswordPolicies(companyId, updateCompanyPasswordPolicies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyPasswordPoliciesApi - factory interface
 * @export
 */
export const CompanyPasswordPoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyPasswordPoliciesApiFp(configuration)
    return {
        /**
         * Get company password policy
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyPasswordPolicies(companyId: number, options?: any): AxiosPromise<CompanyPasswordPolicies> {
            return localVarFp.getCompanyPasswordPolicies(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {UpdateCompanyPasswordPolicies} [updateCompanyPasswordPolicies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyPasswordPolicies(companyId: number, updateCompanyPasswordPolicies?: UpdateCompanyPasswordPolicies, options?: any): AxiosPromise<CompanyPasswordPolicies> {
            return localVarFp.updateCompanyPasswordPolicies(companyId, updateCompanyPasswordPolicies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyPasswordPoliciesApi - object-oriented interface
 * @export
 * @class CompanyPasswordPoliciesApi
 * @extends {BaseAPI}
 */
export class CompanyPasswordPoliciesApi extends BaseAPI {
    /**
     * Get company password policy
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyPasswordPoliciesApi
     */
    public getCompanyPasswordPolicies(companyId: number, options?: any) {
        return CompanyPasswordPoliciesApiFp(this.configuration).getCompanyPasswordPolicies(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {UpdateCompanyPasswordPolicies} [updateCompanyPasswordPolicies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyPasswordPoliciesApi
     */
    public updateCompanyPasswordPolicies(companyId: number, updateCompanyPasswordPolicies?: UpdateCompanyPasswordPolicies, options?: any) {
        return CompanyPasswordPoliciesApiFp(this.configuration).updateCompanyPasswordPolicies(companyId, updateCompanyPasswordPolicies, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyReportSettingApi - axios parameter creator
 * @export
 */
export const CompanyReportSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportSetting: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyReportSetting', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_report_settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {CompanyReportSetting} [companyReportSetting] Update company report setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyReportSetting: async (companyId: number, companyReportSetting?: CompanyReportSetting, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateCompanyReportSetting', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/company_report_settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyReportSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyReportSettingApi - functional programming interface
 * @export
 */
export const CompanyReportSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyReportSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyReportSetting(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyReportSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyReportSetting(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {CompanyReportSetting} [companyReportSetting] Update company report setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyReportSetting(companyId: number, companyReportSetting?: CompanyReportSetting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyReportSetting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyReportSetting(companyId, companyReportSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyReportSettingApi - factory interface
 * @export
 */
export const CompanyReportSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyReportSettingApiFp(configuration)
    return {
        /**
         * Get company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyReportSetting(companyId: number, options?: any): AxiosPromise<CompanyReportSetting> {
            return localVarFp.getCompanyReportSetting(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update company report setting
         * @summary /v1/company/{companyId}/company_report_settings
         * @param {number} companyId 
         * @param {CompanyReportSetting} [companyReportSetting] Update company report setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyReportSetting(companyId: number, companyReportSetting?: CompanyReportSetting, options?: any): AxiosPromise<CompanyReportSetting> {
            return localVarFp.updateCompanyReportSetting(companyId, companyReportSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyReportSettingApi - object-oriented interface
 * @export
 * @class CompanyReportSettingApi
 * @extends {BaseAPI}
 */
export class CompanyReportSettingApi extends BaseAPI {
    /**
     * Get company report setting
     * @summary /v1/company/{companyId}/company_report_settings
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyReportSettingApi
     */
    public getCompanyReportSetting(companyId: number, options?: any) {
        return CompanyReportSettingApiFp(this.configuration).getCompanyReportSetting(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update company report setting
     * @summary /v1/company/{companyId}/company_report_settings
     * @param {number} companyId 
     * @param {CompanyReportSetting} [companyReportSetting] Update company report setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyReportSettingApi
     */
    public updateCompanyReportSetting(companyId: number, companyReportSetting?: CompanyReportSetting, options?: any) {
        return CompanyReportSettingApiFp(this.configuration).updateCompanyReportSetting(companyId, companyReportSetting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyUsersApi - axios parameter creator
 * @export
 */
export const CompanyUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get company users
         * @summary /v1/company/{companyId}/users
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyUsers: async (companyId: number, page: number, per?: number, keyword?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyUsers', 'companyId', companyId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCompanyUsers', 'page', page)
            const localVarPath = `/v1/company/{companyId}/users`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (per !== undefined) {
                localVarQueryParameter['per'] = per;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of export users
         * @summary /v1/company/{companyId}/export_users
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportedUsers: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getExportedUsers', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/export_users`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyUsersApi - functional programming interface
 * @export
 */
export const CompanyUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get company users
         * @summary /v1/company/{companyId}/users
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyUsers(companyId: number, page: number, per?: number, keyword?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCompanyUsers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyUsers(companyId, page, per, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of export users
         * @summary /v1/company/{companyId}/export_users
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportedUsers(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CsvUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportedUsers(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyUsersApi - factory interface
 * @export
 */
export const CompanyUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyUsersApiFp(configuration)
    return {
        /**
         * Get company users
         * @summary /v1/company/{companyId}/users
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyUsers(companyId: number, page: number, per?: number, keyword?: string, options?: any): AxiosPromise<PaginatedCompanyUsers> {
            return localVarFp.getCompanyUsers(companyId, page, per, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of export users
         * @summary /v1/company/{companyId}/export_users
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportedUsers(companyId: number, options?: any): AxiosPromise<Array<CsvUser>> {
            return localVarFp.getExportedUsers(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyUsersApi - object-oriented interface
 * @export
 * @class CompanyUsersApi
 * @extends {BaseAPI}
 */
export class CompanyUsersApi extends BaseAPI {
    /**
     * Get company users
     * @summary /v1/company/{companyId}/users
     * @param {number} companyId 
     * @param {number} page 
     * @param {number} [per] 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyUsersApi
     */
    public getCompanyUsers(companyId: number, page: number, per?: number, keyword?: string, options?: any) {
        return CompanyUsersApiFp(this.configuration).getCompanyUsers(companyId, page, per, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of export users
     * @summary /v1/company/{companyId}/export_users
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyUsersApi
     */
    public getExportedUsers(companyId: number, options?: any) {
        return CompanyUsersApiFp(this.configuration).getExportedUsers(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InterviewMemosApi - axios parameter creator
 * @export
 */
export const InterviewMemosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Interview Memo
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {InterviewMemoCreateRequest} [interviewMemoCreateRequest] request body of interview memo create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterviewMemo: async (interviewId: number, interviewMemoCreateRequest?: InterviewMemoCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('createInterviewMemo', 'interviewId', interviewId)
            const localVarPath = `/v1/interviews/{interview_id}/memos`
                .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interviewMemoCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Interview Memo by ID
         * @summary /v1/interviews/{interviewId}/memos/{id}
         * @param {number} interviewId 
         * @param {number} id interview memo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewMemoDetail: async (interviewId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('getInterviewMemoDetail', 'interviewId', interviewId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInterviewMemoDetail', 'id', id)
            const localVarPath = `/v1/interviews/{interviewId}/memos/{id}`
                .replace(`{${"interviewId"}}`, encodeURIComponent(String(interviewId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Interview memos
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {number} [guestId] 
         * @param {boolean} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewMemos: async (interviewId: number, guestId?: number, history?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('getInterviewMemos', 'interviewId', interviewId)
            const localVarPath = `/v1/interviews/{interview_id}/memos`
                .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guestId !== undefined) {
                localVarQueryParameter['guest_id'] = guestId;
            }

            if (history !== undefined) {
                localVarQueryParameter['history'] = history;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterviewMemosApi - functional programming interface
 * @export
 */
export const InterviewMemosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterviewMemosApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Interview Memo
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {InterviewMemoCreateRequest} [interviewMemoCreateRequest] request body of interview memo create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInterviewMemo(interviewId: number, interviewMemoCreateRequest?: InterviewMemoCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInterviewMemo(interviewId, interviewMemoCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Interview Memo by ID
         * @summary /v1/interviews/{interviewId}/memos/{id}
         * @param {number} interviewId 
         * @param {number} id interview memo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviewMemoDetail(interviewId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InterviewMemoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviewMemoDetail(interviewId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Interview memos
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {number} [guestId] 
         * @param {boolean} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviewMemos(interviewId: number, guestId?: number, history?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InterviewMemoDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviewMemos(interviewId, guestId, history, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterviewMemosApi - factory interface
 * @export
 */
export const InterviewMemosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterviewMemosApiFp(configuration)
    return {
        /**
         * Create Interview Memo
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {InterviewMemoCreateRequest} [interviewMemoCreateRequest] request body of interview memo create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterviewMemo(interviewId: number, interviewMemoCreateRequest?: InterviewMemoCreateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.createInterviewMemo(interviewId, interviewMemoCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Interview Memo by ID
         * @summary /v1/interviews/{interviewId}/memos/{id}
         * @param {number} interviewId 
         * @param {number} id interview memo id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewMemoDetail(interviewId: number, id: number, options?: any): AxiosPromise<InterviewMemoDetail> {
            return localVarFp.getInterviewMemoDetail(interviewId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Interview memos
         * @summary /v1/interviews/{interview_id}/memos
         * @param {number} interviewId 
         * @param {number} [guestId] 
         * @param {boolean} [history] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviewMemos(interviewId: number, guestId?: number, history?: boolean, options?: any): AxiosPromise<Array<InterviewMemoDetail>> {
            return localVarFp.getInterviewMemos(interviewId, guestId, history, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterviewMemosApi - object-oriented interface
 * @export
 * @class InterviewMemosApi
 * @extends {BaseAPI}
 */
export class InterviewMemosApi extends BaseAPI {
    /**
     * Create Interview Memo
     * @summary /v1/interviews/{interview_id}/memos
     * @param {number} interviewId 
     * @param {InterviewMemoCreateRequest} [interviewMemoCreateRequest] request body of interview memo create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewMemosApi
     */
    public createInterviewMemo(interviewId: number, interviewMemoCreateRequest?: InterviewMemoCreateRequest, options?: any) {
        return InterviewMemosApiFp(this.configuration).createInterviewMemo(interviewId, interviewMemoCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Interview Memo by ID
     * @summary /v1/interviews/{interviewId}/memos/{id}
     * @param {number} interviewId 
     * @param {number} id interview memo id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewMemosApi
     */
    public getInterviewMemoDetail(interviewId: number, id: number, options?: any) {
        return InterviewMemosApiFp(this.configuration).getInterviewMemoDetail(interviewId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Interview memos
     * @summary /v1/interviews/{interview_id}/memos
     * @param {number} interviewId 
     * @param {number} [guestId] 
     * @param {boolean} [history] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewMemosApi
     */
    public getInterviewMemos(interviewId: number, guestId?: number, history?: boolean, options?: any) {
        return InterviewMemosApiFp(this.configuration).getInterviewMemos(interviewId, guestId, history, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InterviewsApi - axios parameter creator
 * @export
 */
export const InterviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Interview
         * @summary /interviews
         * @param {InterviewCreateRequest} [interviewCreateRequest] request body of interview create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterview: async (interviewCreateRequest?: InterviewCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/interviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interviewCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch Interview by ID
         * @summary /v1/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterview: async (interviewId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('getInterview', 'interviewId', interviewId)
            const localVarPath = `/v1/interviews/{interviewId}`
                .replace(`{${"interviewId"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch logged in user\'s Interviews
         * @summary /v1/interviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviews: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/interviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Interview
         * @summary /interviews
         * @param {number} interviewId 
         * @param {InterviewUpdateRequest} [interviewUpdateRequest] request body of interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterview: async (interviewId: number, interviewUpdateRequest?: InterviewUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('updateInterview', 'interviewId', interviewId)
            const localVarPath = `/v1/interviews/{interviewId}`
                .replace(`{${"interviewId"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(interviewUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterviewsApi - functional programming interface
 * @export
 */
export const InterviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Interview
         * @summary /interviews
         * @param {InterviewCreateRequest} [interviewCreateRequest] request body of interview create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInterview(interviewCreateRequest?: InterviewCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Interview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInterview(interviewCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch Interview by ID
         * @summary /v1/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterview(interviewId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Interview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterview(interviewId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch logged in user\'s Interviews
         * @summary /v1/interviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterviews(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Interview>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInterviews(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Interview
         * @summary /interviews
         * @param {number} interviewId 
         * @param {InterviewUpdateRequest} [interviewUpdateRequest] request body of interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInterview(interviewId: number, interviewUpdateRequest?: InterviewUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Interview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInterview(interviewId, interviewUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InterviewsApi - factory interface
 * @export
 */
export const InterviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterviewsApiFp(configuration)
    return {
        /**
         * Create Interview
         * @summary /interviews
         * @param {InterviewCreateRequest} [interviewCreateRequest] request body of interview create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInterview(interviewCreateRequest?: InterviewCreateRequest, options?: any): AxiosPromise<Interview> {
            return localVarFp.createInterview(interviewCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch Interview by ID
         * @summary /v1/interviews/{interviewId}
         * @param {number} interviewId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterview(interviewId: number, options?: any): AxiosPromise<Interview> {
            return localVarFp.getInterview(interviewId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch logged in user\'s Interviews
         * @summary /v1/interviews
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterviews(options?: any): AxiosPromise<Array<Interview>> {
            return localVarFp.getInterviews(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Interview
         * @summary /interviews
         * @param {number} interviewId 
         * @param {InterviewUpdateRequest} [interviewUpdateRequest] request body of interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInterview(interviewId: number, interviewUpdateRequest?: InterviewUpdateRequest, options?: any): AxiosPromise<Interview> {
            return localVarFp.updateInterview(interviewId, interviewUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterviewsApi - object-oriented interface
 * @export
 * @class InterviewsApi
 * @extends {BaseAPI}
 */
export class InterviewsApi extends BaseAPI {
    /**
     * Create Interview
     * @summary /interviews
     * @param {InterviewCreateRequest} [interviewCreateRequest] request body of interview create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public createInterview(interviewCreateRequest?: InterviewCreateRequest, options?: any) {
        return InterviewsApiFp(this.configuration).createInterview(interviewCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch Interview by ID
     * @summary /v1/interviews/{interviewId}
     * @param {number} interviewId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public getInterview(interviewId: number, options?: any) {
        return InterviewsApiFp(this.configuration).getInterview(interviewId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch logged in user\'s Interviews
     * @summary /v1/interviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public getInterviews(options?: any) {
        return InterviewsApiFp(this.configuration).getInterviews(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Interview
     * @summary /interviews
     * @param {number} interviewId 
     * @param {InterviewUpdateRequest} [interviewUpdateRequest] request body of interview update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterviewsApi
     */
    public updateInterview(interviewId: number, interviewUpdateRequest?: InterviewUpdateRequest, options?: any) {
        return InterviewsApiFp(this.configuration).updateInterview(interviewId, interviewUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ManagementReportApi - axios parameter creator
 * @export
 */
export const ManagementReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} aggregateStartYearMonth 
         * @param {string} aggregateEndYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showManagementReport: async (userId: number, aggregateStartYearMonth: string, aggregateEndYearMonth: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('showManagementReport', 'userId', userId)
            // verify required parameter 'aggregateStartYearMonth' is not null or undefined
            assertParamExists('showManagementReport', 'aggregateStartYearMonth', aggregateStartYearMonth)
            // verify required parameter 'aggregateEndYearMonth' is not null or undefined
            assertParamExists('showManagementReport', 'aggregateEndYearMonth', aggregateEndYearMonth)
            const localVarPath = `/v1/user/{userId}/management_report`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (aggregateStartYearMonth !== undefined) {
                localVarQueryParameter['aggregate_start_year_month'] = aggregateStartYearMonth;
            }

            if (aggregateEndYearMonth !== undefined) {
                localVarQueryParameter['aggregate_end_year_month'] = aggregateEndYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagementReportApi - functional programming interface
 * @export
 */
export const ManagementReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagementReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} aggregateStartYearMonth 
         * @param {string} aggregateEndYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showManagementReport(userId: number, aggregateStartYearMonth: string, aggregateEndYearMonth: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowManagementReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showManagementReport(userId, aggregateStartYearMonth, aggregateEndYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManagementReportApi - factory interface
 * @export
 */
export const ManagementReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagementReportApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} aggregateStartYearMonth 
         * @param {string} aggregateEndYearMonth 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showManagementReport(userId: number, aggregateStartYearMonth: string, aggregateEndYearMonth: string, options?: any): AxiosPromise<ShowManagementReportResponse> {
            return localVarFp.showManagementReport(userId, aggregateStartYearMonth, aggregateEndYearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagementReportApi - object-oriented interface
 * @export
 * @class ManagementReportApi
 * @extends {BaseAPI}
 */
export class ManagementReportApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} aggregateStartYearMonth 
     * @param {string} aggregateEndYearMonth 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagementReportApi
     */
    public showManagementReport(userId: number, aggregateStartYearMonth: string, aggregateEndYearMonth: string, options?: any) {
        return ManagementReportApiFp(this.configuration).showManagementReport(userId, aggregateStartYearMonth, aggregateEndYearMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MoviesApi - axios parameter creator
 * @export
 */
export const MoviesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get movie data
         * @summary /v1/movies/{recordingId}
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieByRecordingId: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            assertParamExists('getMovieByRecordingId', 'recordingId', recordingId)
            const localVarPath = `/v1/movies/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MoviesApi - functional programming interface
 * @export
 */
export const MoviesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MoviesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get movie data
         * @summary /v1/movies/{recordingId}
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMovieByRecordingId(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovieResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMovieByRecordingId(recordingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MoviesApi - factory interface
 * @export
 */
export const MoviesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MoviesApiFp(configuration)
    return {
        /**
         * Get movie data
         * @summary /v1/movies/{recordingId}
         * @param {string} recordingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieByRecordingId(recordingId: string, options?: any): AxiosPromise<MovieResponse> {
            return localVarFp.getMovieByRecordingId(recordingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MoviesApi - object-oriented interface
 * @export
 * @class MoviesApi
 * @extends {BaseAPI}
 */
export class MoviesApi extends BaseAPI {
    /**
     * Get movie data
     * @summary /v1/movies/{recordingId}
     * @param {string} recordingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoviesApi
     */
    public getMovieByRecordingId(recordingId: string, options?: any) {
        return MoviesApiFp(this.configuration).getMovieByRecordingId(recordingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NetworkReportsApi - axios parameter creator
 * @export
 */
export const NetworkReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNetworkReports: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/network_reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NetworkReportsApi - functional programming interface
 * @export
 */
export const NetworkReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NetworkReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexNetworkReports(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexNetworkReportsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexNetworkReports(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NetworkReportsApi - factory interface
 * @export
 */
export const NetworkReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NetworkReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNetworkReports(options?: any): AxiosPromise<IndexNetworkReportsResponse> {
            return localVarFp.indexNetworkReports(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NetworkReportsApi - object-oriented interface
 * @export
 * @class NetworkReportsApi
 * @extends {BaseAPI}
 */
export class NetworkReportsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkReportsApi
     */
    public indexNetworkReports(options?: any) {
        return NetworkReportsApiFp(this.configuration).indexNetworkReports(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NineboxAgendaAnswersApi - axios parameter creator
 * @export
 */
export const NineboxAgendaAnswersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary /v1/ninebox_agenda_answers
         * @param {CreateNineboxAgendaAnswers} [createNineboxAgendaAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNineboxAgendaAnswers: async (createNineboxAgendaAnswers?: CreateNineboxAgendaAnswers, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ninebox_agenda_answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNineboxAgendaAnswers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NineboxAgendaAnswersApi - functional programming interface
 * @export
 */
export const NineboxAgendaAnswersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NineboxAgendaAnswersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary /v1/ninebox_agenda_answers
         * @param {CreateNineboxAgendaAnswers} [createNineboxAgendaAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNineboxAgendaAnswers(createNineboxAgendaAnswers?: CreateNineboxAgendaAnswers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNineboxAgendaAnswers(createNineboxAgendaAnswers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NineboxAgendaAnswersApi - factory interface
 * @export
 */
export const NineboxAgendaAnswersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NineboxAgendaAnswersApiFp(configuration)
    return {
        /**
         * 
         * @summary /v1/ninebox_agenda_answers
         * @param {CreateNineboxAgendaAnswers} [createNineboxAgendaAnswers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNineboxAgendaAnswers(createNineboxAgendaAnswers?: CreateNineboxAgendaAnswers, options?: any): AxiosPromise<void> {
            return localVarFp.createNineboxAgendaAnswers(createNineboxAgendaAnswers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NineboxAgendaAnswersApi - object-oriented interface
 * @export
 * @class NineboxAgendaAnswersApi
 * @extends {BaseAPI}
 */
export class NineboxAgendaAnswersApi extends BaseAPI {
    /**
     * 
     * @summary /v1/ninebox_agenda_answers
     * @param {CreateNineboxAgendaAnswers} [createNineboxAgendaAnswers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NineboxAgendaAnswersApi
     */
    public createNineboxAgendaAnswers(createNineboxAgendaAnswers?: CreateNineboxAgendaAnswers, options?: any) {
        return NineboxAgendaAnswersApiFp(this.configuration).createNineboxAgendaAnswers(createNineboxAgendaAnswers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NineboxAgendasApi - axios parameter creator
 * @export
 */
export const NineboxAgendasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get ninebox agendas
         * @summary /v1/ninebox_agendas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNineboxAgendas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/ninebox_agendas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NineboxAgendasApi - functional programming interface
 * @export
 */
export const NineboxAgendasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NineboxAgendasApiAxiosParamCreator(configuration)
    return {
        /**
         * Get ninebox agendas
         * @summary /v1/ninebox_agendas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNineboxAgendas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NineboxAgendaIndex>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNineboxAgendas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NineboxAgendasApi - factory interface
 * @export
 */
export const NineboxAgendasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NineboxAgendasApiFp(configuration)
    return {
        /**
         * Get ninebox agendas
         * @summary /v1/ninebox_agendas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNineboxAgendas(options?: any): AxiosPromise<Array<NineboxAgendaIndex>> {
            return localVarFp.getNineboxAgendas(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NineboxAgendasApi - object-oriented interface
 * @export
 * @class NineboxAgendasApi
 * @extends {BaseAPI}
 */
export class NineboxAgendasApi extends BaseAPI {
    /**
     * Get ninebox agendas
     * @summary /v1/ninebox_agendas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NineboxAgendasApi
     */
    public getNineboxAgendas(options?: any) {
        return NineboxAgendasApiFp(this.configuration).getNineboxAgendas(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NineboxesApi - axios parameter creator
 * @export
 */
export const NineboxesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNineboxes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/nineboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NineboxesApi - functional programming interface
 * @export
 */
export const NineboxesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NineboxesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexNineboxes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexNineboxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexNineboxes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NineboxesApi - factory interface
 * @export
 */
export const NineboxesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NineboxesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexNineboxes(options?: any): AxiosPromise<IndexNineboxesResponse> {
            return localVarFp.indexNineboxes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NineboxesApi - object-oriented interface
 * @export
 * @class NineboxesApi
 * @extends {BaseAPI}
 */
export class NineboxesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NineboxesApi
     */
    public indexNineboxes(options?: any) {
        return NineboxesApiFp(this.configuration).indexNineboxes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneApi - axios parameter creator
 * @export
 */
export const OneOnOneApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneActionItem: async (oneOnOneId: string, actionItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('completeOneOnOneActionItem', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'actionItemId' is not null or undefined
            assertParamExists('completeOneOnOneActionItem', 'actionItemId', actionItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items/{actionItemId}/complete`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"actionItemId"}}`, encodeURIComponent(String(actionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneMeetingAgendaItem: async (oneOnOneId: string, meetingId: number, agendaItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('completeOneOnOneMeetingAgendaItem', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('completeOneOnOneMeetingAgendaItem', 'meetingId', meetingId)
            // verify required parameter 'agendaItemId' is not null or undefined
            assertParamExists('completeOneOnOneMeetingAgendaItem', 'agendaItemId', agendaItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/agenda_items/{agendaItemId}/complete`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"agendaItemId"}}`, encodeURIComponent(String(agendaItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneMeetings: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('completeOneOnOneMeetings', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('completeOneOnOneMeetings', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/complete`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItemOrder} [createOneOnOneActionItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneActionItemOrder: async (oneOnOneId: string, createOneOnOneActionItemOrder?: CreateOneOnOneActionItemOrder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneActionItemOrder', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_item_order`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneActionItemOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItem} [createOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneActionItems: async (oneOnOneId: string, createOneOnOneActionItem?: CreateOneOnOneActionItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneActionItems', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneActionItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItemOrder} [createOneOnOneAgendaItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneAgendaItemOrder: async (oneOnOneId: string, createOneOnOneAgendaItemOrder?: CreateOneOnOneAgendaItemOrder, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneAgendaItemOrder', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_item_order`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneAgendaItemOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItem} [createOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneAgendaItems: async (oneOnOneId: string, createOneOnOneAgendaItem?: CreateOneOnOneAgendaItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneAgendaItems', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneAgendaItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingAgendaItem} [createOneOnOneMeetingAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingAgendaItem: async (oneOnOneId: string, meetingId: number, createOneOnOneMeetingAgendaItem?: CreateOneOnOneMeetingAgendaItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetingAgendaItem', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('createOneOnOneMeetingAgendaItem', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/agenda_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneMeetingAgendaItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingNinebox} [createOneOnOneMeetingNinebox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingNineboxes: async (oneOnOneId: string, meetingId: number, createOneOnOneMeetingNinebox?: CreateOneOnOneMeetingNinebox, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetingNineboxes', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('createOneOnOneMeetingNineboxes', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/nineboxes`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneMeetingNinebox, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingSurveyResults} [createOneOnOneMeetingSurveyResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingSurveyResults: async (oneOnOneId: string, meetingId: number, createOneOnOneMeetingSurveyResults?: CreateOneOnOneMeetingSurveyResults, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetingSurveyResults', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('createOneOnOneMeetingSurveyResults', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/survey_results`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneMeetingSurveyResults, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetings: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetings', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsMemoRequestBody} [createOneOnOneMeetingsMemoRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingsMemo: async (oneOnOneId: string, meetingId: number, createOneOnOneMeetingsMemoRequestBody?: CreateOneOnOneMeetingsMemoRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetingsMemo', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('createOneOnOneMeetingsMemo', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/memo`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneMeetingsMemoRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsPrivateMemo} [createOneOnOneMeetingsPrivateMemo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingsPrivateMemo: async (oneOnOneId: string, meetingId: number, createOneOnOneMeetingsPrivateMemo?: CreateOneOnOneMeetingsPrivateMemo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('createOneOnOneMeetingsPrivateMemo', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('createOneOnOneMeetingsPrivateMemo', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/private_memo`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOneMeetingsPrivateMemo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOneOnOneActionItems: async (oneOnOneId: string, actionItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('destroyOneOnOneActionItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'actionItemId' is not null or undefined
            assertParamExists('destroyOneOnOneActionItems', 'actionItemId', actionItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items/{actionItemId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"actionItemId"}}`, encodeURIComponent(String(actionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOneOnOneAgendaItems: async (oneOnOneId: string, agendaItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('destroyOneOnOneAgendaItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'agendaItemId' is not null or undefined
            assertParamExists('destroyOneOnOneAgendaItems', 'agendaItemId', agendaItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_items/{agendaItemId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"agendaItemId"}}`, encodeURIComponent(String(agendaItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneActionItems: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneActionItems', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneAgendaItems: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneAgendaItems', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneAnalysisSuccessfulMeetings: async (oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneAnalysisSuccessfulMeetings', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/analysis_successful_meetings`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingAgendaItems: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingAgendaItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingAgendaItems', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/agenda_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingAnalysisResults: async (oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingAnalysisResults', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meeting_analysis_results`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingNineboxes: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingNineboxes', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingNineboxes', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/nineboxes`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingSurveyResults: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingSurveyResults', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('indexOneOnOneMeetingSurveyResults', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/survey_results`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetings: async (oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneMeetings', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneNineboxes: async (oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('indexOneOnOneNineboxes', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/nineboxes`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOneOnOneMeetingAgendaItem: async (oneOnOneId: string, meetingId: number, agendaItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('openOneOnOneMeetingAgendaItem', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('openOneOnOneMeetingAgendaItem', 'meetingId', meetingId)
            // verify required parameter 'agendaItemId' is not null or undefined
            assertParamExists('openOneOnOneMeetingAgendaItem', 'agendaItemId', agendaItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/agenda_items/{agendaItemId}/ready`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)))
                .replace(`{${"agendaItemId"}}`, encodeURIComponent(String(agendaItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readyOneOnOneActionItem: async (oneOnOneId: string, actionItemId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('readyOneOnOneActionItem', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'actionItemId' is not null or undefined
            assertParamExists('readyOneOnOneActionItem', 'actionItemId', actionItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items/{actionItemId}/ready`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"actionItemId"}}`, encodeURIComponent(String(actionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOne: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOne', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneActionItemOrder: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneActionItemOrder', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_item_order`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneAgendaItemOrder: async (oneOnOneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneAgendaItemOrder', 'oneOnOneId', oneOnOneId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_item_order`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingAnalysisResult: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneMeetingAnalysisResult', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('showOneOnOneMeetingAnalysisResult', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/analysis_result`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetings: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneMeetings', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('showOneOnOneMeetings', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingsMemo: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneMeetingsMemo', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('showOneOnOneMeetingsMemo', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/memo`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingsPrivateMemo: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('showOneOnOneMeetingsPrivateMemo', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('showOneOnOneMeetingsPrivateMemo', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/private_memo`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOneOnOneMeetingAgendaItems: async (oneOnOneId: string, meetingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('syncOneOnOneMeetingAgendaItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'meetingId' is not null or undefined
            assertParamExists('syncOneOnOneMeetingAgendaItems', 'meetingId', meetingId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/meetings/{meetingId}/sync_agenda_items`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"meetingId"}}`, encodeURIComponent(String(meetingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {UpdateOneOnOneActionItem} [updateOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOnOneActionItems: async (oneOnOneId: string, actionItemId: number, updateOneOnOneActionItem?: UpdateOneOnOneActionItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('updateOneOnOneActionItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'actionItemId' is not null or undefined
            assertParamExists('updateOneOnOneActionItems', 'actionItemId', actionItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/action_items/{actionItemId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"actionItemId"}}`, encodeURIComponent(String(actionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOneOnOneActionItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {UpdateOneOnOneAgendaItem} [updateOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOnOneAgendaItems: async (oneOnOneId: string, agendaItemId: number, updateOneOnOneAgendaItem?: UpdateOneOnOneAgendaItem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'oneOnOneId' is not null or undefined
            assertParamExists('updateOneOnOneAgendaItems', 'oneOnOneId', oneOnOneId)
            // verify required parameter 'agendaItemId' is not null or undefined
            assertParamExists('updateOneOnOneAgendaItems', 'agendaItemId', agendaItemId)
            const localVarPath = `/v1/one_on_ones/{oneOnOneId}/agenda_items/{agendaItemId}`
                .replace(`{${"oneOnOneId"}}`, encodeURIComponent(String(oneOnOneId)))
                .replace(`{${"agendaItemId"}}`, encodeURIComponent(String(agendaItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOneOnOneAgendaItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneApi - functional programming interface
 * @export
 */
export const OneOnOneApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeOneOnOneActionItem(oneOnOneId, actionItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteOneOnOneMeetingAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteOneOnOneMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeOneOnOneMeetings(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItemOrder} [createOneOnOneActionItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneActionItemOrder(oneOnOneId: string, createOneOnOneActionItemOrder?: CreateOneOnOneActionItemOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneActionItemOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneActionItemOrder(oneOnOneId, createOneOnOneActionItemOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItem} [createOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneActionItems(oneOnOneId: string, createOneOnOneActionItem?: CreateOneOnOneActionItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneActionItems(oneOnOneId, createOneOnOneActionItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItemOrder} [createOneOnOneAgendaItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneAgendaItemOrder(oneOnOneId: string, createOneOnOneAgendaItemOrder?: CreateOneOnOneAgendaItemOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneAgendaItemOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneAgendaItemOrder(oneOnOneId, createOneOnOneAgendaItemOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItem} [createOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneAgendaItems(oneOnOneId: string, createOneOnOneAgendaItem?: CreateOneOnOneAgendaItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneAgendaItems(oneOnOneId, createOneOnOneAgendaItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingAgendaItem} [createOneOnOneMeetingAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, createOneOnOneMeetingAgendaItem?: CreateOneOnOneMeetingAgendaItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneMeetingAgendaItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, createOneOnOneMeetingAgendaItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingNinebox} [createOneOnOneMeetingNinebox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, createOneOnOneMeetingNinebox?: CreateOneOnOneMeetingNinebox, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetingNineboxes(oneOnOneId, meetingId, createOneOnOneMeetingNinebox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingSurveyResults} [createOneOnOneMeetingSurveyResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, createOneOnOneMeetingSurveyResults?: CreateOneOnOneMeetingSurveyResults, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneMeetingSurveyResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, createOneOnOneMeetingSurveyResults, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetings(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetings(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsMemoRequestBody} [createOneOnOneMeetingsMemoRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsMemoRequestBody?: CreateOneOnOneMeetingsMemoRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneMeetingsMemoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetingsMemo(oneOnOneId, meetingId, createOneOnOneMeetingsMemoRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsPrivateMemo} [createOneOnOneMeetingsPrivateMemo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsPrivateMemo?: CreateOneOnOneMeetingsPrivateMemo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneMeetingsPrivateMemoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, createOneOnOneMeetingsPrivateMemo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyOneOnOneActionItems(oneOnOneId: string, actionItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroyOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyOneOnOneActionItems(oneOnOneId, actionItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroyOneOnOneAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyOneOnOneAgendaItems(oneOnOneId, agendaItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneActionItems(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneActionItems(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneAgendaItems(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneAgendaItems(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneAnalysisSuccessfulMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId, before, after, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneMeetingAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneMeetingAnalysisResults(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneMeetingAnalysisResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneMeetingAnalysisResults(oneOnOneId, before, after, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneMeetingNineboxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneMeetingNineboxes(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneMeetingSurveyResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneMeetings(oneOnOneId, before, after, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneNineboxes(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneNineboxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneNineboxes(oneOnOneId, before, after, limit, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async openOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenOneOnOneMeetingAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.openOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readyOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadyOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readyOneOnOneActionItem(oneOnOneId, actionItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOne(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOne(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneActionItemOrder(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneActionItemOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneActionItemOrder(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneAgendaItemOrder(oneOnOneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneAgendaItemOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneAgendaItemOrder(oneOnOneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneMeetingAnalysisResult(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneMeetingAnalysisResultResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneMeetingAnalysisResult(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneMeetings(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneMeetingsMemoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneMeetingsMemo(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneMeetingsPrivateMemoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncOneOnOneMeetingAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {UpdateOneOnOneActionItem} [updateOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneOnOneActionItems(oneOnOneId: string, actionItemId: number, updateOneOnOneActionItem?: UpdateOneOnOneActionItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOneOnOneActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneOnOneActionItems(oneOnOneId, actionItemId, updateOneOnOneActionItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {UpdateOneOnOneAgendaItem} [updateOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, updateOneOnOneAgendaItem?: UpdateOneOnOneAgendaItem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateOneOnOneAgendaItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneOnOneAgendaItems(oneOnOneId, agendaItemId, updateOneOnOneAgendaItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneApi - factory interface
 * @export
 */
export const OneOnOneApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneApiFp(configuration)
    return {
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any): AxiosPromise<CompleteOneOnOneActionItemsResponse> {
            return localVarFp.completeOneOnOneActionItem(oneOnOneId, actionItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any): AxiosPromise<CompleteOneOnOneMeetingAgendaItemsResponse> {
            return localVarFp.completeOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<CompleteOneOnOneMeetingsResponse> {
            return localVarFp.completeOneOnOneMeetings(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItemOrder} [createOneOnOneActionItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneActionItemOrder(oneOnOneId: string, createOneOnOneActionItemOrder?: CreateOneOnOneActionItemOrder, options?: any): AxiosPromise<CreateOneOnOneActionItemOrderResponse> {
            return localVarFp.createOneOnOneActionItemOrder(oneOnOneId, createOneOnOneActionItemOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneActionItem} [createOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneActionItems(oneOnOneId: string, createOneOnOneActionItem?: CreateOneOnOneActionItem, options?: any): AxiosPromise<CreateOneOnOneActionItemsResponse> {
            return localVarFp.createOneOnOneActionItems(oneOnOneId, createOneOnOneActionItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItemOrder} [createOneOnOneAgendaItemOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneAgendaItemOrder(oneOnOneId: string, createOneOnOneAgendaItemOrder?: CreateOneOnOneAgendaItemOrder, options?: any): AxiosPromise<CreateOneOnOneAgendaItemOrderResponse> {
            return localVarFp.createOneOnOneAgendaItemOrder(oneOnOneId, createOneOnOneAgendaItemOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {CreateOneOnOneAgendaItem} [createOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneAgendaItems(oneOnOneId: string, createOneOnOneAgendaItem?: CreateOneOnOneAgendaItem, options?: any): AxiosPromise<CreateOneOnOneAgendaItemsResponse> {
            return localVarFp.createOneOnOneAgendaItems(oneOnOneId, createOneOnOneAgendaItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingAgendaItem} [createOneOnOneMeetingAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, createOneOnOneMeetingAgendaItem?: CreateOneOnOneMeetingAgendaItem, options?: any): AxiosPromise<CreateOneOnOneMeetingAgendaItemResponse> {
            return localVarFp.createOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, createOneOnOneMeetingAgendaItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingNinebox} [createOneOnOneMeetingNinebox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, createOneOnOneMeetingNinebox?: CreateOneOnOneMeetingNinebox, options?: any): AxiosPromise<void> {
            return localVarFp.createOneOnOneMeetingNineboxes(oneOnOneId, meetingId, createOneOnOneMeetingNinebox, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingSurveyResults} [createOneOnOneMeetingSurveyResults] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, createOneOnOneMeetingSurveyResults?: CreateOneOnOneMeetingSurveyResults, options?: any): AxiosPromise<CreateOneOnOneMeetingSurveyResultsResponse> {
            return localVarFp.createOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, createOneOnOneMeetingSurveyResults, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetings(oneOnOneId: string, options?: any): AxiosPromise<CreateOneOnOneMeetingsResponse> {
            return localVarFp.createOneOnOneMeetings(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsMemoRequestBody} [createOneOnOneMeetingsMemoRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsMemoRequestBody?: CreateOneOnOneMeetingsMemoRequestBody, options?: any): AxiosPromise<CreateOneOnOneMeetingsMemoResponse> {
            return localVarFp.createOneOnOneMeetingsMemo(oneOnOneId, meetingId, createOneOnOneMeetingsMemoRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {CreateOneOnOneMeetingsPrivateMemo} [createOneOnOneMeetingsPrivateMemo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsPrivateMemo?: CreateOneOnOneMeetingsPrivateMemo, options?: any): AxiosPromise<CreateOneOnOneMeetingsPrivateMemoResponse> {
            return localVarFp.createOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, createOneOnOneMeetingsPrivateMemo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOneOnOneActionItems(oneOnOneId: string, actionItemId: number, options?: any): AxiosPromise<DestroyOneOnOneActionItemsResponse> {
            return localVarFp.destroyOneOnOneActionItems(oneOnOneId, actionItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, options?: any): AxiosPromise<DestroyOneOnOneAgendaItemsResponse> {
            return localVarFp.destroyOneOnOneAgendaItems(oneOnOneId, agendaItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneActionItems(oneOnOneId: string, options?: any): AxiosPromise<IndexOneOnOneActionItemsResponse> {
            return localVarFp.indexOneOnOneActionItems(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneAgendaItems(oneOnOneId: string, options?: any): AxiosPromise<IndexOneOnOneAgendaItemsResponse> {
            return localVarFp.indexOneOnOneAgendaItems(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): AxiosPromise<IndexOneOnOneAnalysisSuccessfulMeetingsResponse> {
            return localVarFp.indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId, before, after, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<IndexOneOnOneMeetingAgendaItemsResponse> {
            return localVarFp.indexOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingAnalysisResults(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): AxiosPromise<IndexOneOnOneMeetingAnalysisResultsResponse> {
            return localVarFp.indexOneOnOneMeetingAnalysisResults(oneOnOneId, before, after, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<IndexOneOnOneMeetingNineboxesResponse> {
            return localVarFp.indexOneOnOneMeetingNineboxes(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<IndexOneOnOneMeetingSurveyResultsResponse> {
            return localVarFp.indexOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): AxiosPromise<IndexOneOnOneMeetingsResponse> {
            return localVarFp.indexOneOnOneMeetings(oneOnOneId, before, after, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {string} [before] 
         * @param {string} [after] 
         * @param {number} [limit] 取得件数
         * @param {'asc' | 'desc'} [order] 並び順
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneNineboxes(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any): AxiosPromise<IndexOneOnOneNineboxesResponse> {
            return localVarFp.indexOneOnOneNineboxes(oneOnOneId, before, after, limit, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {number} agendaItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        openOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any): AxiosPromise<OpenOneOnOneMeetingAgendaItemsResponse> {
            return localVarFp.openOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readyOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any): AxiosPromise<ReadyOneOnOneActionItemsResponse> {
            return localVarFp.readyOneOnOneActionItem(oneOnOneId, actionItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOne(oneOnOneId: string, options?: any): AxiosPromise<ShowOneOnOneResponse> {
            return localVarFp.showOneOnOne(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneActionItemOrder(oneOnOneId: string, options?: any): AxiosPromise<ShowOneOnOneActionItemOrderResponse> {
            return localVarFp.showOneOnOneActionItemOrder(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneAgendaItemOrder(oneOnOneId: string, options?: any): AxiosPromise<ShowOneOnOneAgendaItemOrderResponse> {
            return localVarFp.showOneOnOneAgendaItemOrder(oneOnOneId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingAnalysisResult(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<ShowOneOnOneMeetingAnalysisResultResponse> {
            return localVarFp.showOneOnOneMeetingAnalysisResult(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<ShowOneOnOneMeetingsResponse> {
            return localVarFp.showOneOnOneMeetings(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<ShowOneOnOneMeetingsMemoResponse> {
            return localVarFp.showOneOnOneMeetingsMemo(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<ShowOneOnOneMeetingsPrivateMemoResponse> {
            return localVarFp.showOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} meetingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any): AxiosPromise<SyncOneOnOneMeetingAgendaItemsResponse> {
            return localVarFp.syncOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} actionItemId 
         * @param {UpdateOneOnOneActionItem} [updateOneOnOneActionItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOnOneActionItems(oneOnOneId: string, actionItemId: number, updateOneOnOneActionItem?: UpdateOneOnOneActionItem, options?: any): AxiosPromise<UpdateOneOnOneActionItemsResponse> {
            return localVarFp.updateOneOnOneActionItems(oneOnOneId, actionItemId, updateOneOnOneActionItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} oneOnOneId 
         * @param {number} agendaItemId 
         * @param {UpdateOneOnOneAgendaItem} [updateOneOnOneAgendaItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, updateOneOnOneAgendaItem?: UpdateOneOnOneAgendaItem, options?: any): AxiosPromise<UpdateOneOnOneAgendaItemsResponse> {
            return localVarFp.updateOneOnOneAgendaItems(oneOnOneId, agendaItemId, updateOneOnOneAgendaItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneApi - object-oriented interface
 * @export
 * @class OneOnOneApi
 * @extends {BaseAPI}
 */
export class OneOnOneApi extends BaseAPI {
    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} actionItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public completeOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).completeOneOnOneActionItem(oneOnOneId, actionItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {number} agendaItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public completeOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).completeOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public completeOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).completeOneOnOneMeetings(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {CreateOneOnOneActionItemOrder} [createOneOnOneActionItemOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneActionItemOrder(oneOnOneId: string, createOneOnOneActionItemOrder?: CreateOneOnOneActionItemOrder, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneActionItemOrder(oneOnOneId, createOneOnOneActionItemOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {CreateOneOnOneActionItem} [createOneOnOneActionItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneActionItems(oneOnOneId: string, createOneOnOneActionItem?: CreateOneOnOneActionItem, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneActionItems(oneOnOneId, createOneOnOneActionItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {CreateOneOnOneAgendaItemOrder} [createOneOnOneAgendaItemOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneAgendaItemOrder(oneOnOneId: string, createOneOnOneAgendaItemOrder?: CreateOneOnOneAgendaItemOrder, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneAgendaItemOrder(oneOnOneId, createOneOnOneAgendaItemOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {CreateOneOnOneAgendaItem} [createOneOnOneAgendaItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneAgendaItems(oneOnOneId: string, createOneOnOneAgendaItem?: CreateOneOnOneAgendaItem, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneAgendaItems(oneOnOneId, createOneOnOneAgendaItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {CreateOneOnOneMeetingAgendaItem} [createOneOnOneMeetingAgendaItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, createOneOnOneMeetingAgendaItem?: CreateOneOnOneMeetingAgendaItem, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, createOneOnOneMeetingAgendaItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {CreateOneOnOneMeetingNinebox} [createOneOnOneMeetingNinebox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, createOneOnOneMeetingNinebox?: CreateOneOnOneMeetingNinebox, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetingNineboxes(oneOnOneId, meetingId, createOneOnOneMeetingNinebox, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {CreateOneOnOneMeetingSurveyResults} [createOneOnOneMeetingSurveyResults] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, createOneOnOneMeetingSurveyResults?: CreateOneOnOneMeetingSurveyResults, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, createOneOnOneMeetingSurveyResults, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetings(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetings(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {CreateOneOnOneMeetingsMemoRequestBody} [createOneOnOneMeetingsMemoRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsMemoRequestBody?: CreateOneOnOneMeetingsMemoRequestBody, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetingsMemo(oneOnOneId, meetingId, createOneOnOneMeetingsMemoRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {CreateOneOnOneMeetingsPrivateMemo} [createOneOnOneMeetingsPrivateMemo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public createOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, createOneOnOneMeetingsPrivateMemo?: CreateOneOnOneMeetingsPrivateMemo, options?: any) {
        return OneOnOneApiFp(this.configuration).createOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, createOneOnOneMeetingsPrivateMemo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} actionItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public destroyOneOnOneActionItems(oneOnOneId: string, actionItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).destroyOneOnOneActionItems(oneOnOneId, actionItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} agendaItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public destroyOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).destroyOneOnOneAgendaItems(oneOnOneId, agendaItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneActionItems(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneActionItems(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneAgendaItems(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneAgendaItems(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {string} [before] 
     * @param {string} [after] 
     * @param {number} [limit] 取得件数
     * @param {'asc' | 'desc'} [order] 並び順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneAnalysisSuccessfulMeetings(oneOnOneId, before, after, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {string} [before] 
     * @param {string} [after] 
     * @param {number} [limit] 取得件数
     * @param {'asc' | 'desc'} [order] 並び順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneMeetingAnalysisResults(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneMeetingAnalysisResults(oneOnOneId, before, after, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneMeetingNineboxes(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneMeetingNineboxes(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneMeetingSurveyResults(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneMeetingSurveyResults(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {string} [before] 
     * @param {string} [after] 
     * @param {number} [limit] 取得件数
     * @param {'asc' | 'desc'} [order] 並び順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneMeetings(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneMeetings(oneOnOneId, before, after, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {string} [before] 
     * @param {string} [after] 
     * @param {number} [limit] 取得件数
     * @param {'asc' | 'desc'} [order] 並び順
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public indexOneOnOneNineboxes(oneOnOneId: string, before?: string, after?: string, limit?: number, order?: 'asc' | 'desc', options?: any) {
        return OneOnOneApiFp(this.configuration).indexOneOnOneNineboxes(oneOnOneId, before, after, limit, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {number} agendaItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public openOneOnOneMeetingAgendaItem(oneOnOneId: string, meetingId: number, agendaItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).openOneOnOneMeetingAgendaItem(oneOnOneId, meetingId, agendaItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} actionItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public readyOneOnOneActionItem(oneOnOneId: string, actionItemId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).readyOneOnOneActionItem(oneOnOneId, actionItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOne(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOne(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneActionItemOrder(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneActionItemOrder(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneAgendaItemOrder(oneOnOneId: string, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneAgendaItemOrder(oneOnOneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneMeetingAnalysisResult(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneMeetingAnalysisResult(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneMeetings(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneMeetings(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneMeetingsMemo(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneMeetingsMemo(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public showOneOnOneMeetingsPrivateMemo(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).showOneOnOneMeetingsPrivateMemo(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} meetingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public syncOneOnOneMeetingAgendaItems(oneOnOneId: string, meetingId: number, options?: any) {
        return OneOnOneApiFp(this.configuration).syncOneOnOneMeetingAgendaItems(oneOnOneId, meetingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} actionItemId 
     * @param {UpdateOneOnOneActionItem} [updateOneOnOneActionItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public updateOneOnOneActionItems(oneOnOneId: string, actionItemId: number, updateOneOnOneActionItem?: UpdateOneOnOneActionItem, options?: any) {
        return OneOnOneApiFp(this.configuration).updateOneOnOneActionItems(oneOnOneId, actionItemId, updateOneOnOneActionItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} oneOnOneId 
     * @param {number} agendaItemId 
     * @param {UpdateOneOnOneAgendaItem} [updateOneOnOneAgendaItem] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneApi
     */
    public updateOneOnOneAgendaItems(oneOnOneId: string, agendaItemId: number, updateOneOnOneAgendaItem?: UpdateOneOnOneAgendaItem, options?: any) {
        return OneOnOneApiFp(this.configuration).updateOneOnOneAgendaItems(oneOnOneId, agendaItemId, updateOneOnOneAgendaItem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneActionItemApi - axios parameter creator
 * @export
 */
export const OneOnOneActionItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneReadyActionItems: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/one_on_one_ready_action_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneActionItemApi - functional programming interface
 * @export
 */
export const OneOnOneActionItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneActionItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneReadyActionItems(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneReadyActionItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneReadyActionItems(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneActionItemApi - factory interface
 * @export
 */
export const OneOnOneActionItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneActionItemApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneReadyActionItems(options?: any): AxiosPromise<IndexOneOnOneReadyActionItemsResponse> {
            return localVarFp.indexOneOnOneReadyActionItems(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneActionItemApi - object-oriented interface
 * @export
 * @class OneOnOneActionItemApi
 * @extends {BaseAPI}
 */
export class OneOnOneActionItemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneActionItemApi
     */
    public indexOneOnOneReadyActionItems(options?: any) {
        return OneOnOneActionItemApiFp(this.configuration).indexOneOnOneReadyActionItems(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneCompanyAnalysisMonthlyScoresApi - axios parameter creator
 * @export
 */
export const OneOnOneCompanyAnalysisMonthlyScoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserOneOnOneCompanyAnalysisMonthlyScore: async (userId: number, startYearMonth?: string, endYearMonth?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('showUserOneOnOneCompanyAnalysisMonthlyScore', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/one_on_one_company_analysis_monthly_score`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startYearMonth !== undefined) {
                localVarQueryParameter['start_year_month'] = startYearMonth;
            }

            if (endYearMonth !== undefined) {
                localVarQueryParameter['end_year_month'] = endYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneCompanyAnalysisMonthlyScoresApi - functional programming interface
 * @export
 */
export const OneOnOneCompanyAnalysisMonthlyScoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneCompanyAnalysisMonthlyScoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUserOneOnOneCompanyAnalysisMonthlyScore(userId: number, startYearMonth?: string, endYearMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUserOneOnOneCompanyAnalysisMonthlyScore(userId, startYearMonth, endYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneCompanyAnalysisMonthlyScoresApi - factory interface
 * @export
 */
export const OneOnOneCompanyAnalysisMonthlyScoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneCompanyAnalysisMonthlyScoresApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserOneOnOneCompanyAnalysisMonthlyScore(userId: number, startYearMonth?: string, endYearMonth?: string, options?: any): AxiosPromise<ShowUserOneOnOneCompanyAnalysisMonthlyScoreResponse> {
            return localVarFp.showUserOneOnOneCompanyAnalysisMonthlyScore(userId, startYearMonth, endYearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneCompanyAnalysisMonthlyScoresApi - object-oriented interface
 * @export
 * @class OneOnOneCompanyAnalysisMonthlyScoresApi
 * @extends {BaseAPI}
 */
export class OneOnOneCompanyAnalysisMonthlyScoresApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} [startYearMonth] 
     * @param {string} [endYearMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneCompanyAnalysisMonthlyScoresApi
     */
    public showUserOneOnOneCompanyAnalysisMonthlyScore(userId: number, startYearMonth?: string, endYearMonth?: string, options?: any) {
        return OneOnOneCompanyAnalysisMonthlyScoresApiFp(this.configuration).showUserOneOnOneCompanyAnalysisMonthlyScore(userId, startYearMonth, endYearMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneInProgressMeetingsApi - axios parameter creator
 * @export
 */
export const OneOnOneInProgressMeetingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneInProgressMeetings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/one_on_one_in_progress_meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneInProgressMeetingsApi - functional programming interface
 * @export
 */
export const OneOnOneInProgressMeetingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneInProgressMeetingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOneInProgressMeetings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOneInProgressMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOneInProgressMeetings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneInProgressMeetingsApi - factory interface
 * @export
 */
export const OneOnOneInProgressMeetingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneInProgressMeetingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOneInProgressMeetings(options?: any): AxiosPromise<IndexOneOnOneInProgressMeetingsResponse> {
            return localVarFp.indexOneOnOneInProgressMeetings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneInProgressMeetingsApi - object-oriented interface
 * @export
 * @class OneOnOneInProgressMeetingsApi
 * @extends {BaseAPI}
 */
export class OneOnOneInProgressMeetingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneInProgressMeetingsApi
     */
    public indexOneOnOneInProgressMeetings(options?: any) {
        return OneOnOneInProgressMeetingsApiFp(this.configuration).indexOneOnOneInProgressMeetings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneInvitationCodeApi - axios parameter creator
 * @export
 */
export const OneOnOneInvitationCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneInvitationCode: async (invitationCode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationCode' is not null or undefined
            assertParamExists('showOneOnOneInvitationCode', 'invitationCode', invitationCode)
            const localVarPath = `/v1/one_on_one_invitation_codes/{invitationCode}`
                .replace(`{${"invitationCode"}}`, encodeURIComponent(String(invitationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneInvitationCodeApi - functional programming interface
 * @export
 */
export const OneOnOneInvitationCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneInvitationCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneOnOneInvitationCode(invitationCode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowOneOnOneInvitationCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showOneOnOneInvitationCode(invitationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneInvitationCodeApi - factory interface
 * @export
 */
export const OneOnOneInvitationCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneInvitationCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneOnOneInvitationCode(invitationCode: string, options?: any): AxiosPromise<ShowOneOnOneInvitationCodeResponse> {
            return localVarFp.showOneOnOneInvitationCode(invitationCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneInvitationCodeApi - object-oriented interface
 * @export
 * @class OneOnOneInvitationCodeApi
 * @extends {BaseAPI}
 */
export class OneOnOneInvitationCodeApi extends BaseAPI {
    /**
     * 
     * @param {string} invitationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneInvitationCodeApi
     */
    public showOneOnOneInvitationCode(invitationCode: string, options?: any) {
        return OneOnOneInvitationCodeApiFp(this.configuration).showOneOnOneInvitationCode(invitationCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneMeetingAnalysisResultsApi - axios parameter creator
 * @export
 */
export const OneOnOneMeetingAnalysisResultsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterUserOneOnOneMeetingAnalysisResults: async (userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('filterUserOneOnOneMeetingAnalysisResults', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/one_on_one_meeting_analysis_results_filter`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentUniqueKey !== undefined) {
                localVarQueryParameter['department_unique_key'] = departmentUniqueKey;
            }

            if (startYearMonth !== undefined) {
                localVarQueryParameter['start_year_month'] = startYearMonth;
            }

            if (endYearMonth !== undefined) {
                localVarQueryParameter['end_year_month'] = endYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneMeetingAnalysisResultsApi - functional programming interface
 * @export
 */
export const OneOnOneMeetingAnalysisResultsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneMeetingAnalysisResultsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterUserOneOnOneMeetingAnalysisResults(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterUserOneOnOneMeetingAnalysisResultsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterUserOneOnOneMeetingAnalysisResults(userId, departmentUniqueKey, startYearMonth, endYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneMeetingAnalysisResultsApi - factory interface
 * @export
 */
export const OneOnOneMeetingAnalysisResultsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneMeetingAnalysisResultsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterUserOneOnOneMeetingAnalysisResults(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any): AxiosPromise<FilterUserOneOnOneMeetingAnalysisResultsResponse> {
            return localVarFp.filterUserOneOnOneMeetingAnalysisResults(userId, departmentUniqueKey, startYearMonth, endYearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneMeetingAnalysisResultsApi - object-oriented interface
 * @export
 * @class OneOnOneMeetingAnalysisResultsApi
 * @extends {BaseAPI}
 */
export class OneOnOneMeetingAnalysisResultsApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} [departmentUniqueKey] 
     * @param {string} [startYearMonth] 
     * @param {string} [endYearMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneMeetingAnalysisResultsApi
     */
    public filterUserOneOnOneMeetingAnalysisResults(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any) {
        return OneOnOneMeetingAnalysisResultsApiFp(this.configuration).filterUserOneOnOneMeetingAnalysisResults(userId, departmentUniqueKey, startYearMonth, endYearMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneMeetingsApi - axios parameter creator
 * @export
 */
export const OneOnOneMeetingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterUserOneOnOneMeetings: async (userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('filterUserOneOnOneMeetings', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/one_on_one_meetings_filter`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (departmentUniqueKey !== undefined) {
                localVarQueryParameter['department_unique_key'] = departmentUniqueKey;
            }

            if (startYearMonth !== undefined) {
                localVarQueryParameter['start_year_month'] = startYearMonth;
            }

            if (endYearMonth !== undefined) {
                localVarQueryParameter['end_year_month'] = endYearMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneMeetingsApi - functional programming interface
 * @export
 */
export const OneOnOneMeetingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneMeetingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filterUserOneOnOneMeetings(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterUserOneOnOneMeetingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filterUserOneOnOneMeetings(userId, departmentUniqueKey, startYearMonth, endYearMonth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneMeetingsApi - factory interface
 * @export
 */
export const OneOnOneMeetingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneMeetingsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {string} [departmentUniqueKey] 
         * @param {string} [startYearMonth] 
         * @param {string} [endYearMonth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filterUserOneOnOneMeetings(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any): AxiosPromise<FilterUserOneOnOneMeetingsResponse> {
            return localVarFp.filterUserOneOnOneMeetings(userId, departmentUniqueKey, startYearMonth, endYearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneMeetingsApi - object-oriented interface
 * @export
 * @class OneOnOneMeetingsApi
 * @extends {BaseAPI}
 */
export class OneOnOneMeetingsApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {string} [departmentUniqueKey] 
     * @param {string} [startYearMonth] 
     * @param {string} [endYearMonth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneMeetingsApi
     */
    public filterUserOneOnOneMeetings(userId: number, departmentUniqueKey?: string, startYearMonth?: string, endYearMonth?: string, options?: any) {
        return OneOnOneMeetingsApiFp(this.configuration).filterUserOneOnOneMeetings(userId, departmentUniqueKey, startYearMonth, endYearMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOnePartnersApi - axios parameter creator
 * @export
 */
export const OneOnOnePartnersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOnePartners: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/one_on_one_partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUserOneOnOnePartners: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('indexUserOneOnOnePartners', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/one_on_one_partners`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOnePartnersApi - functional programming interface
 * @export
 */
export const OneOnOnePartnersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOnePartnersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexOneOnOnePartners(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexOneOnOnePartnersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexOneOnOnePartners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexUserOneOnOnePartners(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexUserOneOnOnePartnersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexUserOneOnOnePartners(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOnePartnersApi - factory interface
 * @export
 */
export const OneOnOnePartnersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOnePartnersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexOneOnOnePartners(options?: any): AxiosPromise<IndexOneOnOnePartnersResponse> {
            return localVarFp.indexOneOnOnePartners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUserOneOnOnePartners(userId: number, options?: any): AxiosPromise<IndexUserOneOnOnePartnersResponse> {
            return localVarFp.indexUserOneOnOnePartners(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOnePartnersApi - object-oriented interface
 * @export
 * @class OneOnOnePartnersApi
 * @extends {BaseAPI}
 */
export class OneOnOnePartnersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOnePartnersApi
     */
    public indexOneOnOnePartners(options?: any) {
        return OneOnOnePartnersApiFp(this.configuration).indexOneOnOnePartners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOnePartnersApi
     */
    public indexUserOneOnOnePartners(userId: number, options?: any) {
        return OneOnOnePartnersApiFp(this.configuration).indexUserOneOnOnePartners(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOneUsersApi - axios parameter creator
 * @export
 */
export const OneOnOneUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZipCompanyOneOnOneUsers: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('downloadZipCompanyOneOnOneUsers', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/one_on_one_users_download_zip`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * compare user data
         * @param {number} companyId 
         * @param {CompareCompanyOneOnOneUsers} [compareCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOneOnOneUsersCompare: async (companyId: number, compareCompanyOneOnOneUsers?: CompareCompanyOneOnOneUsers, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompanyOneOnOneUsersCompare', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/one_on_one_users_compare`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(compareCompanyOneOnOneUsers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * import user data
         * @param {number} companyId 
         * @param {ImportCompanyOneOnOneUsers} [importCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOneOnOneUsersImport: async (companyId: number, importCompanyOneOnOneUsers?: ImportCompanyOneOnOneUsers, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCompanyOneOnOneUsersImport', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/one_on_one_users_import`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importCompanyOneOnOneUsers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOneUsersApi - functional programming interface
 * @export
 */
export const OneOnOneUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOneUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadZipCompanyOneOnOneUsers(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadZipCompanyOneOnOneUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadZipCompanyOneOnOneUsers(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * compare user data
         * @param {number} companyId 
         * @param {CompareCompanyOneOnOneUsers} [compareCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOneOnOneUsersCompare(companyId: number, compareCompanyOneOnOneUsers?: CompareCompanyOneOnOneUsers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompareCompanyOneOnOneUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOneOnOneUsersCompare(companyId, compareCompanyOneOnOneUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * import user data
         * @param {number} companyId 
         * @param {ImportCompanyOneOnOneUsers} [importCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompanyOneOnOneUsersImport(companyId: number, importCompanyOneOnOneUsers?: ImportCompanyOneOnOneUsers, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportCompanyOneOnOneUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompanyOneOnOneUsersImport(companyId, importCompanyOneOnOneUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOneUsersApi - factory interface
 * @export
 */
export const OneOnOneUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOneUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadZipCompanyOneOnOneUsers(companyId: number, options?: any): AxiosPromise<DownloadZipCompanyOneOnOneUsersResponse> {
            return localVarFp.downloadZipCompanyOneOnOneUsers(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * compare user data
         * @param {number} companyId 
         * @param {CompareCompanyOneOnOneUsers} [compareCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOneOnOneUsersCompare(companyId: number, compareCompanyOneOnOneUsers?: CompareCompanyOneOnOneUsers, options?: any): AxiosPromise<CompareCompanyOneOnOneUsersResponse> {
            return localVarFp.postCompanyOneOnOneUsersCompare(companyId, compareCompanyOneOnOneUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * import user data
         * @param {number} companyId 
         * @param {ImportCompanyOneOnOneUsers} [importCompanyOneOnOneUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompanyOneOnOneUsersImport(companyId: number, importCompanyOneOnOneUsers?: ImportCompanyOneOnOneUsers, options?: any): AxiosPromise<ImportCompanyOneOnOneUsersResponse> {
            return localVarFp.postCompanyOneOnOneUsersImport(companyId, importCompanyOneOnOneUsers, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOneUsersApi - object-oriented interface
 * @export
 * @class OneOnOneUsersApi
 * @extends {BaseAPI}
 */
export class OneOnOneUsersApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneUsersApi
     */
    public downloadZipCompanyOneOnOneUsers(companyId: number, options?: any) {
        return OneOnOneUsersApiFp(this.configuration).downloadZipCompanyOneOnOneUsers(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * compare user data
     * @param {number} companyId 
     * @param {CompareCompanyOneOnOneUsers} [compareCompanyOneOnOneUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneUsersApi
     */
    public postCompanyOneOnOneUsersCompare(companyId: number, compareCompanyOneOnOneUsers?: CompareCompanyOneOnOneUsers, options?: any) {
        return OneOnOneUsersApiFp(this.configuration).postCompanyOneOnOneUsersCompare(companyId, compareCompanyOneOnOneUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * import user data
     * @param {number} companyId 
     * @param {ImportCompanyOneOnOneUsers} [importCompanyOneOnOneUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOneUsersApi
     */
    public postCompanyOneOnOneUsersImport(companyId: number, importCompanyOneOnOneUsers?: ImportCompanyOneOnOneUsers, options?: any) {
        return OneOnOneUsersApiFp(this.configuration).postCompanyOneOnOneUsersImport(companyId, importCompanyOneOnOneUsers, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OneOnOnesApi - axios parameter creator
 * @export
 */
export const OneOnOnesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOneOnOne} [createOneOnOne] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOne: async (createOneOnOne?: CreateOneOnOne, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/one_on_ones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOneOnOne, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OneOnOnesApi - functional programming interface
 * @export
 */
export const OneOnOnesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OneOnOnesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOneOnOne} [createOneOnOne] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneOnOne(createOneOnOne?: CreateOneOnOne, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOneOnOneResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneOnOne(createOneOnOne, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OneOnOnesApi - factory interface
 * @export
 */
export const OneOnOnesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OneOnOnesApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateOneOnOne} [createOneOnOne] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneOnOne(createOneOnOne?: CreateOneOnOne, options?: any): AxiosPromise<CreateOneOnOneResponse> {
            return localVarFp.createOneOnOne(createOneOnOne, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OneOnOnesApi - object-oriented interface
 * @export
 * @class OneOnOnesApi
 * @extends {BaseAPI}
 */
export class OneOnOnesApi extends BaseAPI {
    /**
     * 
     * @param {CreateOneOnOne} [createOneOnOne] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OneOnOnesApi
     */
    public createOneOnOne(createOneOnOne?: CreateOneOnOne, options?: any) {
        return OneOnOnesApiFp(this.configuration).createOneOnOne(createOneOnOne, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get report
         * @summary /v1/user/{user_id}/reports/v2
         * @param {number} userId 
         * @param {number} guestId 
         * @param {number} [interviewId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportByIdV2: async (userId: number, guestId: number, interviewId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getReportByIdV2', 'userId', userId)
            // verify required parameter 'guestId' is not null or undefined
            assertParamExists('getReportByIdV2', 'guestId', guestId)
            const localVarPath = `/v1/user/{user_id}/reports/v2`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guestId !== undefined) {
                localVarQueryParameter['guest_id'] = guestId;
            }

            if (interviewId !== undefined) {
                localVarQueryParameter['interview_id'] = interviewId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get report detail
         * @summary /v1/user/{user_id}/reports/v2/interviews/{interview_id}
         * @param {number} userId 
         * @param {number} interviewId 
         * @param {number} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetailByIdV2: async (userId: number, interviewId: number, guestId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getReportDetailByIdV2', 'userId', userId)
            // verify required parameter 'interviewId' is not null or undefined
            assertParamExists('getReportDetailByIdV2', 'interviewId', interviewId)
            // verify required parameter 'guestId' is not null or undefined
            assertParamExists('getReportDetailByIdV2', 'guestId', guestId)
            const localVarPath = `/v1/user/{user_id}/reports/v2/interviews/{interview_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"interview_id"}}`, encodeURIComponent(String(interviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (guestId !== undefined) {
                localVarQueryParameter['guest_id'] = guestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get report
         * @summary /v1/user/{user_id}/reports/v2
         * @param {number} userId 
         * @param {number} guestId 
         * @param {number} [interviewId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportByIdV2(userId: number, guestId: number, interviewId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportByIdV2(userId, guestId, interviewId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get report detail
         * @summary /v1/user/{user_id}/reports/v2/interviews/{interview_id}
         * @param {number} userId 
         * @param {number} interviewId 
         * @param {number} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportDetailByIdV2(userId: number, interviewId: number, guestId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportV2Detail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportDetailByIdV2(userId, interviewId, guestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * Get report
         * @summary /v1/user/{user_id}/reports/v2
         * @param {number} userId 
         * @param {number} guestId 
         * @param {number} [interviewId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportByIdV2(userId: number, guestId: number, interviewId?: number, options?: any): AxiosPromise<ReportV2> {
            return localVarFp.getReportByIdV2(userId, guestId, interviewId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get report detail
         * @summary /v1/user/{user_id}/reports/v2/interviews/{interview_id}
         * @param {number} userId 
         * @param {number} interviewId 
         * @param {number} guestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportDetailByIdV2(userId: number, interviewId: number, guestId: number, options?: any): AxiosPromise<ReportV2Detail> {
            return localVarFp.getReportDetailByIdV2(userId, interviewId, guestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * Get report
     * @summary /v1/user/{user_id}/reports/v2
     * @param {number} userId 
     * @param {number} guestId 
     * @param {number} [interviewId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportByIdV2(userId: number, guestId: number, interviewId?: number, options?: any) {
        return ReportsApiFp(this.configuration).getReportByIdV2(userId, guestId, interviewId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get report detail
     * @summary /v1/user/{user_id}/reports/v2/interviews/{interview_id}
     * @param {number} userId 
     * @param {number} interviewId 
     * @param {number} guestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportDetailByIdV2(userId: number, interviewId: number, guestId: number, options?: any) {
        return ReportsApiFp(this.configuration).getReportDetailByIdV2(userId, interviewId, guestId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get room information from channelId
         * @summary /v1/rooms/{channelId}
         * @param {string | number} channelId Either channelId or roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom: async (channelId: string | number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('getRoom', 'channelId', channelId)
            const localVarPath = `/v1/rooms/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * Get room information from channelId
         * @summary /v1/rooms/{channelId}
         * @param {string | number} channelId Either channelId or roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoom(channelId: string | number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Room>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoom(channelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * Get room information from channelId
         * @summary /v1/rooms/{channelId}
         * @param {string | number} channelId Either channelId or roomId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoom(channelId: string | number, options?: any): AxiosPromise<Room> {
            return localVarFp.getRoom(channelId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * Get room information from channelId
     * @summary /v1/rooms/{channelId}
     * @param {string | number} channelId Either channelId or roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public getRoom(channelId: string | number, options?: any) {
        return RoomApiFp(this.configuration).getRoom(channelId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelfApi - axios parameter creator
 * @export
 */
export const SelfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSelf: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/self`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Agree to the terms
         * @summary /v1/self/agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAgreement: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/self/agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelfApi - functional programming interface
 * @export
 */
export const SelfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelfApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexSelf(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexSelfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexSelf(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Agree to the terms
         * @summary /v1/self/agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAgreement(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAgreement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelfApi - factory interface
 * @export
 */
export const SelfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelfApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexSelf(options?: any): AxiosPromise<IndexSelfResponse> {
            return localVarFp.indexSelf(options).then((request) => request(axios, basePath));
        },
        /**
         * Agree to the terms
         * @summary /v1/self/agreement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAgreement(options?: any): AxiosPromise<Account> {
            return localVarFp.postAgreement(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelfApi - object-oriented interface
 * @export
 * @class SelfApi
 * @extends {BaseAPI}
 */
export class SelfApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfApi
     */
    public indexSelf(options?: any) {
        return SelfApiFp(this.configuration).indexSelf(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Agree to the terms
     * @summary /v1/self/agreement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelfApi
     */
    public postAgreement(options?: any) {
        return SelfApiFp(this.configuration).postAgreement(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortTermActionsApi - axios parameter creator
 * @export
 */
export const ShortTermActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get action list for download
         * @summary /v1/admin/suggestions/{id}/export_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionsForExport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActionsForExport', 'id', id)
            const localVarPath = `/v1/admin/suggestions/{id}/export_actions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create action
         * @summary /v1/admin/suggestions/short_term_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAction: async (actionCsvPostRequest?: ActionCsvPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/short_term_actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionCsvPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/action_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionCsvValidation: async (actionCsvValidationRequest?: ActionCsvValidationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/action_csv_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionCsvValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTermActionsApi - functional programming interface
 * @export
 */
export const ShortTermActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTermActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * get action list for download
         * @summary /v1/admin/suggestions/{id}/export_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionsForExport(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportShortTermAction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActionsForExport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create action
         * @summary /v1/admin/suggestions/short_term_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAction(actionCsvPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/action_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActionCsvParseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActionCsvValidation(actionCsvValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortTermActionsApi - factory interface
 * @export
 */
export const ShortTermActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTermActionsApiFp(configuration)
    return {
        /**
         * get action list for download
         * @summary /v1/admin/suggestions/{id}/export_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionsForExport(id: number, options?: any): AxiosPromise<Array<ExportShortTermAction>> {
            return localVarFp.getActionsForExport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create action
         * @summary /v1/admin/suggestions/short_term_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any): AxiosPromise<CsvImportResponse> {
            return localVarFp.postAction(actionCsvPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/action_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any): AxiosPromise<ActionCsvParseResult> {
            return localVarFp.postActionCsvValidation(actionCsvValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTermActionsApi - object-oriented interface
 * @export
 * @class ShortTermActionsApi
 * @extends {BaseAPI}
 */
export class ShortTermActionsApi extends BaseAPI {
    /**
     * get action list for download
     * @summary /v1/admin/suggestions/{id}/export_actions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermActionsApi
     */
    public getActionsForExport(id: number, options?: any) {
        return ShortTermActionsApiFp(this.configuration).getActionsForExport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create action
     * @summary /v1/admin/suggestions/short_term_actions
     * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermActionsApi
     */
    public postAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any) {
        return ShortTermActionsApiFp(this.configuration).postAction(actionCsvPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate action csv
     * @summary /v1/admin/suggestions/action_csv_validation
     * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermActionsApi
     */
    public postActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any) {
        return ShortTermActionsApiFp(this.configuration).postActionCsvValidation(actionCsvValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortTermPartnerActionsApi - axios parameter creator
 * @export
 */
export const ShortTermPartnerActionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get partner action list for download
         * @summary /v1/admin/suggestions/{id}/export_partner_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerActionsForExport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPartnerActionsForExport', 'id', id)
            const localVarPath = `/v1/admin/suggestions/{id}/export_partner_actions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create partner action
         * @summary /v1/admin/suggestions/short_term_partner_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerAction: async (actionCsvPostRequest?: ActionCsvPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/short_term_partner_actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionCsvPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/partner_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerActionCsvValidation: async (actionCsvValidationRequest?: ActionCsvValidationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/partner_csv_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(actionCsvValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTermPartnerActionsApi - functional programming interface
 * @export
 */
export const ShortTermPartnerActionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTermPartnerActionsApiAxiosParamCreator(configuration)
    return {
        /**
         * get partner action list for download
         * @summary /v1/admin/suggestions/{id}/export_partner_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerActionsForExport(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportShortTermPartnerAction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerActionsForExport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create partner action
         * @summary /v1/admin/suggestions/short_term_partner_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPartnerAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPartnerAction(actionCsvPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/partner_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPartnerActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerActionCsvParseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPartnerActionCsvValidation(actionCsvValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortTermPartnerActionsApi - factory interface
 * @export
 */
export const ShortTermPartnerActionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTermPartnerActionsApiFp(configuration)
    return {
        /**
         * get partner action list for download
         * @summary /v1/admin/suggestions/{id}/export_partner_actions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerActionsForExport(id: number, options?: any): AxiosPromise<Array<ExportShortTermPartnerAction>> {
            return localVarFp.getPartnerActionsForExport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create partner action
         * @summary /v1/admin/suggestions/short_term_partner_actions
         * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any): AxiosPromise<CsvImportResponse> {
            return localVarFp.postPartnerAction(actionCsvPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate action csv
         * @summary /v1/admin/suggestions/partner_csv_validation
         * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any): AxiosPromise<PartnerActionCsvParseResult> {
            return localVarFp.postPartnerActionCsvValidation(actionCsvValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTermPartnerActionsApi - object-oriented interface
 * @export
 * @class ShortTermPartnerActionsApi
 * @extends {BaseAPI}
 */
export class ShortTermPartnerActionsApi extends BaseAPI {
    /**
     * get partner action list for download
     * @summary /v1/admin/suggestions/{id}/export_partner_actions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPartnerActionsApi
     */
    public getPartnerActionsForExport(id: number, options?: any) {
        return ShortTermPartnerActionsApiFp(this.configuration).getPartnerActionsForExport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create partner action
     * @summary /v1/admin/suggestions/short_term_partner_actions
     * @param {ActionCsvPostRequest} [actionCsvPostRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPartnerActionsApi
     */
    public postPartnerAction(actionCsvPostRequest?: ActionCsvPostRequest, options?: any) {
        return ShortTermPartnerActionsApiFp(this.configuration).postPartnerAction(actionCsvPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate action csv
     * @summary /v1/admin/suggestions/partner_csv_validation
     * @param {ActionCsvValidationRequest} [actionCsvValidationRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermPartnerActionsApi
     */
    public postPartnerActionCsvValidation(actionCsvValidationRequest?: ActionCsvValidationRequest, options?: any) {
        return ShortTermPartnerActionsApiFp(this.configuration).postPartnerActionCsvValidation(actionCsvValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortTermSolutionsApi - axios parameter creator
 * @export
 */
export const ShortTermSolutionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get solution list for download
         * @summary /v1/admin/suggestions/{id}/export_solutions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutionsForExport: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolutionsForExport', 'id', id)
            const localVarPath = `/v1/admin/suggestions/{id}/export_solutions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create solution
         * @summary /v1/admin/suggestions/short_term_solutions
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of solution post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSolution: async (solutionCsvPostRequest?: SolutionCsvPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/short_term_solutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solutionCsvPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate solution csv
         * @summary /v1/admin/suggestions/solution_csv_validation
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSolutionCsvValidation: async (solutionCsvPostRequest?: SolutionCsvPostRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions/solution_csv_validation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solutionCsvPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTermSolutionsApi - functional programming interface
 * @export
 */
export const ShortTermSolutionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTermSolutionsApiAxiosParamCreator(configuration)
    return {
        /**
         * get solution list for download
         * @summary /v1/admin/suggestions/{id}/export_solutions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolutionsForExport(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExportShortTermSolution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolutionsForExport(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create solution
         * @summary /v1/admin/suggestions/short_term_solutions
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of solution post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSolution(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CsvImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSolution(solutionCsvPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate solution csv
         * @summary /v1/admin/suggestions/solution_csv_validation
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSolutionCsvValidation(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolutionCsvParseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSolutionCsvValidation(solutionCsvPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortTermSolutionsApi - factory interface
 * @export
 */
export const ShortTermSolutionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTermSolutionsApiFp(configuration)
    return {
        /**
         * get solution list for download
         * @summary /v1/admin/suggestions/{id}/export_solutions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolutionsForExport(id: number, options?: any): AxiosPromise<Array<ExportShortTermSolution>> {
            return localVarFp.getSolutionsForExport(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create solution
         * @summary /v1/admin/suggestions/short_term_solutions
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of solution post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSolution(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any): AxiosPromise<CsvImportResponse> {
            return localVarFp.postSolution(solutionCsvPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate solution csv
         * @summary /v1/admin/suggestions/solution_csv_validation
         * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSolutionCsvValidation(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any): AxiosPromise<SolutionCsvParseResult> {
            return localVarFp.postSolutionCsvValidation(solutionCsvPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTermSolutionsApi - object-oriented interface
 * @export
 * @class ShortTermSolutionsApi
 * @extends {BaseAPI}
 */
export class ShortTermSolutionsApi extends BaseAPI {
    /**
     * get solution list for download
     * @summary /v1/admin/suggestions/{id}/export_solutions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermSolutionsApi
     */
    public getSolutionsForExport(id: number, options?: any) {
        return ShortTermSolutionsApiFp(this.configuration).getSolutionsForExport(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create solution
     * @summary /v1/admin/suggestions/short_term_solutions
     * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of solution post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermSolutionsApi
     */
    public postSolution(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any) {
        return ShortTermSolutionsApiFp(this.configuration).postSolution(solutionCsvPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate solution csv
     * @summary /v1/admin/suggestions/solution_csv_validation
     * @param {SolutionCsvPostRequest} [solutionCsvPostRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermSolutionsApi
     */
    public postSolutionCsvValidation(solutionCsvPostRequest?: SolutionCsvPostRequest, options?: any) {
        return ShortTermSolutionsApiFp(this.configuration).postSolutionCsvValidation(solutionCsvPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShortTermSuggestionApi - axios parameter creator
 * @export
 */
export const ShortTermSuggestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexShortTermSuggestions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/short_term_suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortTermSuggestionApi - functional programming interface
 * @export
 */
export const ShortTermSuggestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortTermSuggestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexShortTermSuggestions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexShortTermSuggestionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexShortTermSuggestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortTermSuggestionApi - factory interface
 * @export
 */
export const ShortTermSuggestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortTermSuggestionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexShortTermSuggestions(options?: any): AxiosPromise<IndexShortTermSuggestionsResponse> {
            return localVarFp.indexShortTermSuggestions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortTermSuggestionApi - object-oriented interface
 * @export
 * @class ShortTermSuggestionApi
 * @extends {BaseAPI}
 */
export class ShortTermSuggestionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortTermSuggestionApi
     */
    public indexShortTermSuggestions(options?: any) {
        return ShortTermSuggestionApiFp(this.configuration).indexShortTermSuggestions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SlackApi - axios parameter creator
 * @export
 */
export const SlackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Connect Slack to company
         * @param {number} companyId 
         * @param {SlackCreateRequest} [slackCreateRequest] request body of slack create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectSlackToCompany: async (companyId: number, slackCreateRequest?: SlackCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('connectSlackToCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/slack_credentials`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(slackCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Disconnect Slack to company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectSlackToCompany: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('disconnectSlackToCompany', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/slack_credentials`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlackApi - functional programming interface
 * @export
 */
export const SlackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlackApiAxiosParamCreator(configuration)
    return {
        /**
         * Connect Slack to company
         * @param {number} companyId 
         * @param {SlackCreateRequest} [slackCreateRequest] request body of slack create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectSlackToCompany(companyId: number, slackCreateRequest?: SlackCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectSlackToCompany(companyId, slackCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Disconnect Slack to company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnectSlackToCompany(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnectSlackToCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlackApi - factory interface
 * @export
 */
export const SlackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlackApiFp(configuration)
    return {
        /**
         * Connect Slack to company
         * @param {number} companyId 
         * @param {SlackCreateRequest} [slackCreateRequest] request body of slack create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectSlackToCompany(companyId: number, slackCreateRequest?: SlackCreateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.connectSlackToCompany(companyId, slackCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Disconnect Slack to company
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnectSlackToCompany(companyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.disconnectSlackToCompany(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlackApi - object-oriented interface
 * @export
 * @class SlackApi
 * @extends {BaseAPI}
 */
export class SlackApi extends BaseAPI {
    /**
     * Connect Slack to company
     * @param {number} companyId 
     * @param {SlackCreateRequest} [slackCreateRequest] request body of slack create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public connectSlackToCompany(companyId: number, slackCreateRequest?: SlackCreateRequest, options?: any) {
        return SlackApiFp(this.configuration).connectSlackToCompany(companyId, slackCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Disconnect Slack to company
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackApi
     */
    public disconnectSlackToCompany(companyId: number, options?: any) {
        return SlackApiFp(this.configuration).disconnectSlackToCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
export const SuggestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete Suggestion
         * @summary /v1/admin/suggestions#destroy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSuggestion: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSuggestion', 'id', id)
            const localVarPath = `/v1/admin/suggestions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get suggestion data
         * @summary /v1/admin/suggestions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestion: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSuggestion', 'id', id)
            const localVarPath = `/v1/admin/suggestions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get suggestion list
         * @summary /v1/admin/suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/suggestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
export const SuggestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuggestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete Suggestion
         * @summary /v1/admin/suggestions#destroy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSuggestion(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSuggestion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get suggestion data
         * @summary /v1/admin/suggestions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestion(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Suggestion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestion(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get suggestion list
         * @summary /v1/admin/suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestions(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Suggestion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuggestionsApi - factory interface
 * @export
 */
export const SuggestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuggestionsApiFp(configuration)
    return {
        /**
         * Delete Suggestion
         * @summary /v1/admin/suggestions#destroy
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSuggestion(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSuggestion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get suggestion data
         * @summary /v1/admin/suggestions
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestion(id: number, options?: any): AxiosPromise<Suggestion> {
            return localVarFp.getSuggestion(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get suggestion list
         * @summary /v1/admin/suggestions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions(options?: any): AxiosPromise<Array<Suggestion>> {
            return localVarFp.getSuggestions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuggestionsApi - object-oriented interface
 * @export
 * @class SuggestionsApi
 * @extends {BaseAPI}
 */
export class SuggestionsApi extends BaseAPI {
    /**
     * Delete Suggestion
     * @summary /v1/admin/suggestions#destroy
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public deleteSuggestion(id: number, options?: any) {
        return SuggestionsApiFp(this.configuration).deleteSuggestion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get suggestion data
     * @summary /v1/admin/suggestions
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getSuggestion(id: number, options?: any) {
        return SuggestionsApiFp(this.configuration).getSuggestion(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get suggestion list
     * @summary /v1/admin/suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getSuggestions(options?: any) {
        return SuggestionsApiFp(this.configuration).getSuggestions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SurveyTypesApi - axios parameter creator
 * @export
 */
export const SurveyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAdminSurveyTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/survey_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyTypesApi - functional programming interface
 * @export
 */
export const SurveyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showAdminSurveyTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowAdminSurveyTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showAdminSurveyTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveyTypesApi - factory interface
 * @export
 */
export const SurveyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveyTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showAdminSurveyTypes(options?: any): AxiosPromise<ShowAdminSurveyTypesResponse> {
            return localVarFp.showAdminSurveyTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveyTypesApi - object-oriented interface
 * @export
 * @class SurveyTypesApi
 * @extends {BaseAPI}
 */
export class SurveyTypesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyTypesApi
     */
    public showAdminSurveyTypes(options?: any) {
        return SurveyTypesApiFp(this.configuration).showAdminSurveyTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SurveysApi - axios parameter creator
 * @export
 */
export const SurveysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Search AdminSurvey
         * @summary /v1/surveys/${id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurvey: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAdminSurvey', 'id', id)
            const localVarPath = `/v1/admin/surveys/${id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search AdminSurveys
         * @summary /v1/surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update survey data
         * @summary /surveys/${id}
         * @param {number} id 
         * @param {SurveyUpdateRequest} [surveyUpdateRequest] request body of admin survey update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdminSurvey: async (id: number, surveyUpdateRequest?: SurveyUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAdminSurvey', 'id', id)
            const localVarPath = `/v1/admin/surveys/${id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create survey
         * @summary /v1/surveys
         * @param {SurveyCreateRequest} [surveyCreateRequest] request body of survey create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSurvey: async (surveyCreateRequest?: SurveyCreateRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/surveys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(surveyCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat'} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSurvey: async (id: 'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('showSurvey', 'id', id)
            const localVarPath = `/v1/surveys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveysApi - functional programming interface
 * @export
 */
export const SurveysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SurveysApiAxiosParamCreator(configuration)
    return {
        /**
         * Search AdminSurvey
         * @summary /v1/surveys/${id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurvey(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search AdminSurveys
         * @summary /v1/surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdminSurveys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Survey>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAdminSurveys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update survey data
         * @summary /surveys/${id}
         * @param {number} id 
         * @param {SurveyUpdateRequest} [surveyUpdateRequest] request body of admin survey update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAdminSurvey(id: number, surveyUpdateRequest?: SurveyUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAdminSurvey(id, surveyUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create survey
         * @summary /v1/surveys
         * @param {SurveyCreateRequest} [surveyCreateRequest] request body of survey create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSurvey(surveyCreateRequest?: SurveyCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Survey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSurvey(surveyCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat'} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showSurvey(id: 'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowSurveyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showSurvey(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SurveysApi - factory interface
 * @export
 */
export const SurveysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SurveysApiFp(configuration)
    return {
        /**
         * Search AdminSurvey
         * @summary /v1/surveys/${id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurvey(id: number, options?: any): AxiosPromise<SurveyResponse> {
            return localVarFp.getAdminSurvey(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Search AdminSurveys
         * @summary /v1/surveys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminSurveys(options?: any): AxiosPromise<Array<Survey>> {
            return localVarFp.getAdminSurveys(options).then((request) => request(axios, basePath));
        },
        /**
         * Update survey data
         * @summary /surveys/${id}
         * @param {number} id 
         * @param {SurveyUpdateRequest} [surveyUpdateRequest] request body of admin survey update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAdminSurvey(id: number, surveyUpdateRequest?: SurveyUpdateRequest, options?: any): AxiosPromise<SurveyResponse> {
            return localVarFp.patchAdminSurvey(id, surveyUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create survey
         * @summary /v1/surveys
         * @param {SurveyCreateRequest} [surveyCreateRequest] request body of survey create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSurvey(surveyCreateRequest?: SurveyCreateRequest, options?: any): AxiosPromise<Survey> {
            return localVarFp.postSurvey(surveyCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat'} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSurvey(id: 'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat', options?: any): AxiosPromise<ShowSurveyResponse> {
            return localVarFp.showSurvey(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SurveysApi - object-oriented interface
 * @export
 * @class SurveysApi
 * @extends {BaseAPI}
 */
export class SurveysApi extends BaseAPI {
    /**
     * Search AdminSurvey
     * @summary /v1/surveys/${id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public getAdminSurvey(id: number, options?: any) {
        return SurveysApiFp(this.configuration).getAdminSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search AdminSurveys
     * @summary /v1/surveys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public getAdminSurveys(options?: any) {
        return SurveysApiFp(this.configuration).getAdminSurveys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update survey data
     * @summary /surveys/${id}
     * @param {number} id 
     * @param {SurveyUpdateRequest} [surveyUpdateRequest] request body of admin survey update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public patchAdminSurvey(id: number, surveyUpdateRequest?: SurveyUpdateRequest, options?: any) {
        return SurveysApiFp(this.configuration).patchAdminSurvey(id, surveyUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create survey
     * @summary /v1/surveys
     * @param {SurveyCreateRequest} [surveyCreateRequest] request body of survey create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public postSurvey(surveyCreateRequest?: SurveyCreateRequest, options?: any) {
        return SurveysApiFp(this.configuration).postSurvey(surveyCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat'} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveysApi
     */
    public showSurvey(id: 'before_menter' | 'before_mentee' | 'after_menter' | 'after_mentee' | 'before_flat' | 'after_flat', options?: any) {
        return SurveysApiFp(this.configuration).showSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAppearanceSettingApi - axios parameter creator
 * @export
 */
export const UserAppearanceSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateUserAppearanceSetting} [createUserAppearanceSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAppearanceSetting: async (createUserAppearanceSetting?: CreateUserAppearanceSetting, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_appearance_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserAppearanceSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserAppearanceSetting: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_appearance_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAppearanceSettingApi - functional programming interface
 * @export
 */
export const UserAppearanceSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAppearanceSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateUserAppearanceSetting} [createUserAppearanceSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserAppearanceSetting(createUserAppearanceSetting?: CreateUserAppearanceSetting, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserAppearanceSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserAppearanceSetting(createUserAppearanceSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUserAppearanceSetting(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShowUserAppearanceSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showUserAppearanceSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAppearanceSettingApi - factory interface
 * @export
 */
export const UserAppearanceSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAppearanceSettingApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateUserAppearanceSetting} [createUserAppearanceSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserAppearanceSetting(createUserAppearanceSetting?: CreateUserAppearanceSetting, options?: any): AxiosPromise<CreateUserAppearanceSettingResponse> {
            return localVarFp.createUserAppearanceSetting(createUserAppearanceSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUserAppearanceSetting(options?: any): AxiosPromise<ShowUserAppearanceSettingResponse> {
            return localVarFp.showUserAppearanceSetting(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAppearanceSettingApi - object-oriented interface
 * @export
 * @class UserAppearanceSettingApi
 * @extends {BaseAPI}
 */
export class UserAppearanceSettingApi extends BaseAPI {
    /**
     * 
     * @param {CreateUserAppearanceSetting} [createUserAppearanceSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppearanceSettingApi
     */
    public createUserAppearanceSetting(createUserAppearanceSetting?: CreateUserAppearanceSetting, options?: any) {
        return UserAppearanceSettingApiFp(this.configuration).createUserAppearanceSetting(createUserAppearanceSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAppearanceSettingApi
     */
    public showUserAppearanceSetting(options?: any) {
        return UserAppearanceSettingApiFp(this.configuration).showUserAppearanceSetting(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAuthoritiesApi - axios parameter creator
 * @export
 */
export const UserAuthoritiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user authorities
         * @summary /v1/authorities/
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorities: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/authorities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAuthoritiesApi - functional programming interface
 * @export
 */
export const UserAuthoritiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAuthoritiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user authorities
         * @summary /v1/authorities/
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorities(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Authority>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAuthoritiesApi - factory interface
 * @export
 */
export const UserAuthoritiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAuthoritiesApiFp(configuration)
    return {
        /**
         * Get user authorities
         * @summary /v1/authorities/
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorities(options?: any): AxiosPromise<Array<Authority>> {
            return localVarFp.getAuthorities(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAuthoritiesApi - object-oriented interface
 * @export
 * @class UserAuthoritiesApi
 * @extends {BaseAPI}
 */
export class UserAuthoritiesApi extends BaseAPI {
    /**
     * Get user authorities
     * @summary /v1/authorities/
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAuthoritiesApi
     */
    public getAuthorities(options?: any) {
        return UserAuthoritiesApiFp(this.configuration).getAuthorities(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCsvsApi - axios parameter creator
 * @export
 */
export const UserCsvsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * getCsvFile
         * @summary /v1/company/{companyId}/users/{userId}/download_csv/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCsvFile', 'id', id)
            const localVarPath = `/v1/company/download_csv/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * getCsvImportHistories
         * @summary /v1/company/{companyId}/csv_import_histories
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvImportHistories: async (companyId: number, page: number, per?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCsvImportHistories', 'companyId', companyId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getCsvImportHistories', 'page', page)
            const localVarPath = `/v1/company/{companyId}/csv_import_histories`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (per !== undefined) {
                localVarQueryParameter['per'] = per;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate csv file for user importing
         * @summary /v1/company/{companyId}/csv_validation
         * @param {number} companyId 
         * @param {CsvValidationRequest} [csvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCsvValidation: async (companyId: number, csvValidationRequest?: CsvValidationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postCsvValidation', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/csv_validation`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(csvValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCsvsApi - functional programming interface
 * @export
 */
export const UserCsvsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCsvsApiAxiosParamCreator(configuration)
    return {
        /**
         * getCsvFile
         * @summary /v1/company/{companyId}/users/{userId}/download_csv/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsvFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsvFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * getCsvImportHistories
         * @summary /v1/company/{companyId}/csv_import_histories
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCsvImportHistories(companyId: number, page: number, per?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserImportHistories>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCsvImportHistories(companyId, page, per, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Validate csv file for user importing
         * @summary /v1/company/{companyId}/csv_validation
         * @param {number} companyId 
         * @param {CsvValidationRequest} [csvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCsvValidation(companyId: number, csvValidationRequest?: CsvValidationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCsvParseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCsvValidation(companyId, csvValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCsvsApi - factory interface
 * @export
 */
export const UserCsvsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCsvsApiFp(configuration)
    return {
        /**
         * getCsvFile
         * @summary /v1/company/{companyId}/users/{userId}/download_csv/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getCsvFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * getCsvImportHistories
         * @summary /v1/company/{companyId}/csv_import_histories
         * @param {number} companyId 
         * @param {number} page 
         * @param {number} [per] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCsvImportHistories(companyId: number, page: number, per?: number, options?: any): AxiosPromise<Array<UserImportHistories>> {
            return localVarFp.getCsvImportHistories(companyId, page, per, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate csv file for user importing
         * @summary /v1/company/{companyId}/csv_validation
         * @param {number} companyId 
         * @param {CsvValidationRequest} [csvValidationRequest] request body of csv validation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCsvValidation(companyId: number, csvValidationRequest?: CsvValidationRequest, options?: any): AxiosPromise<UserCsvParseResult> {
            return localVarFp.postCsvValidation(companyId, csvValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCsvsApi - object-oriented interface
 * @export
 * @class UserCsvsApi
 * @extends {BaseAPI}
 */
export class UserCsvsApi extends BaseAPI {
    /**
     * getCsvFile
     * @summary /v1/company/{companyId}/users/{userId}/download_csv/{id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCsvsApi
     */
    public getCsvFile(id: number, options?: any) {
        return UserCsvsApiFp(this.configuration).getCsvFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * getCsvImportHistories
     * @summary /v1/company/{companyId}/csv_import_histories
     * @param {number} companyId 
     * @param {number} page 
     * @param {number} [per] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCsvsApi
     */
    public getCsvImportHistories(companyId: number, page: number, per?: number, options?: any) {
        return UserCsvsApiFp(this.configuration).getCsvImportHistories(companyId, page, per, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate csv file for user importing
     * @summary /v1/company/{companyId}/csv_validation
     * @param {number} companyId 
     * @param {CsvValidationRequest} [csvValidationRequest] request body of csv validation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCsvsApi
     */
    public postCsvValidation(companyId: number, csvValidationRequest?: CsvValidationRequest, options?: any) {
        return UserCsvsApiFp(this.configuration).postCsvValidation(companyId, csvValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserInterviewsApi - axios parameter creator
 * @export
 */
export const UserInterviewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user interview data
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInterviewById: async (userId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInterviewById', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserInterviewById', 'id', id)
            const localVarPath = `/v1/user/{userId}/user_interviews/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s interviews
         * @summary /v1/user/{userId}/user_interviews
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInterviews: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInterviews', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/user_interviews`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update UserInterview
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {UserInterviewUpdateRequest} [userInterviewUpdateRequest] request body of user interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInterview: async (userId: number, id: number, userInterviewUpdateRequest?: UserInterviewUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserInterview', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserInterview', 'id', id)
            const localVarPath = `/v1/user/{userId}/user_interviews/{id}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInterviewUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInterviewsApi - functional programming interface
 * @export
 */
export const UserInterviewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInterviewsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user interview data
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInterviewById(userId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterviewShowResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInterviewById(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user\'s interviews
         * @summary /v1/user/{userId}/user_interviews
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInterviews(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserInterviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInterviews(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update UserInterview
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {UserInterviewUpdateRequest} [userInterviewUpdateRequest] request body of user interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInterview(userId: number, id: number, userInterviewUpdateRequest?: UserInterviewUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInterview>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserInterview(userId, id, userInterviewUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserInterviewsApi - factory interface
 * @export
 */
export const UserInterviewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInterviewsApiFp(configuration)
    return {
        /**
         * Get user interview data
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInterviewById(userId: number, id: number, options?: any): AxiosPromise<UserInterviewShowResponse> {
            return localVarFp.getUserInterviewById(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s interviews
         * @summary /v1/user/{userId}/user_interviews
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInterviews(userId: number, options?: any): AxiosPromise<Array<UserInterviewResponse>> {
            return localVarFp.getUserInterviews(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update UserInterview
         * @summary /v1/user/{userId}/user_interviews/{id}
         * @param {number} userId 
         * @param {number} id 
         * @param {UserInterviewUpdateRequest} [userInterviewUpdateRequest] request body of user interview update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInterview(userId: number, id: number, userInterviewUpdateRequest?: UserInterviewUpdateRequest, options?: any): AxiosPromise<UserInterview> {
            return localVarFp.updateUserInterview(userId, id, userInterviewUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInterviewsApi - object-oriented interface
 * @export
 * @class UserInterviewsApi
 * @extends {BaseAPI}
 */
export class UserInterviewsApi extends BaseAPI {
    /**
     * Get user interview data
     * @summary /v1/user/{userId}/user_interviews/{id}
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterviewsApi
     */
    public getUserInterviewById(userId: number, id: number, options?: any) {
        return UserInterviewsApiFp(this.configuration).getUserInterviewById(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s interviews
     * @summary /v1/user/{userId}/user_interviews
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterviewsApi
     */
    public getUserInterviews(userId: number, options?: any) {
        return UserInterviewsApiFp(this.configuration).getUserInterviews(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update UserInterview
     * @summary /v1/user/{userId}/user_interviews/{id}
     * @param {number} userId 
     * @param {number} id 
     * @param {UserInterviewUpdateRequest} [userInterviewUpdateRequest] request body of user interview update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInterviewsApi
     */
    public updateUserInterview(userId: number, id: number, userInterviewUpdateRequest?: UserInterviewUpdateRequest, options?: any) {
        return UserInterviewsApiFp(this.configuration).updateUserInterview(userId, id, userInterviewUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserLiveBackgroundImagesApi - axios parameter creator
 * @export
 */
export const UserLiveBackgroundImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete user live background image
         * @summary delete live background image
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLiveBackground: async (userId: number, id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserLiveBackground', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserLiveBackground', 'id', id)
            const localVarPath = `/v1/user/{user_id}/user_live_backgrounds/{id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user live background images
         * @summary /v1/user/{user_id}/user_live_backgrounds
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveBackgrounds: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserLiveBackgrounds', 'userId', userId)
            const localVarPath = `/v1/user/{user_id}/user_live_backgrounds`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * post background image file for live
         * @summary /v1/user/{userId}/user_live_backgrounds
         * @param {number} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiveBackgroundImage: async (userId: number, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('postLiveBackgroundImage', 'userId', userId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postLiveBackgroundImage', 'file', file)
            const localVarPath = `/v1/user/{userId}/user_live_backgrounds`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLiveBackgroundImagesApi - functional programming interface
 * @export
 */
export const UserLiveBackgroundImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLiveBackgroundImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete user live background image
         * @summary delete live background image
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserLiveBackground(userId: number, id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserLiveBackground(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get user live background images
         * @summary /v1/user/{user_id}/user_live_backgrounds
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLiveBackgrounds(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiveBackgroundImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLiveBackgrounds(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * post background image file for live
         * @summary /v1/user/{userId}/user_live_backgrounds
         * @param {number} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLiveBackgroundImage(userId: number, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiveBackgroundImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLiveBackgroundImage(userId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserLiveBackgroundImagesApi - factory interface
 * @export
 */
export const UserLiveBackgroundImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLiveBackgroundImagesApiFp(configuration)
    return {
        /**
         * Delete user live background image
         * @summary delete live background image
         * @param {number} userId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLiveBackground(userId: number, id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserLiveBackground(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * get user live background images
         * @summary /v1/user/{user_id}/user_live_backgrounds
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLiveBackgrounds(userId: number, options?: any): AxiosPromise<Array<LiveBackgroundImage>> {
            return localVarFp.getUserLiveBackgrounds(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * post background image file for live
         * @summary /v1/user/{userId}/user_live_backgrounds
         * @param {number} userId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLiveBackgroundImage(userId: number, file: any, options?: any): AxiosPromise<Array<LiveBackgroundImage>> {
            return localVarFp.postLiveBackgroundImage(userId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserLiveBackgroundImagesApi - object-oriented interface
 * @export
 * @class UserLiveBackgroundImagesApi
 * @extends {BaseAPI}
 */
export class UserLiveBackgroundImagesApi extends BaseAPI {
    /**
     * Delete user live background image
     * @summary delete live background image
     * @param {number} userId 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLiveBackgroundImagesApi
     */
    public deleteUserLiveBackground(userId: number, id: number, options?: any) {
        return UserLiveBackgroundImagesApiFp(this.configuration).deleteUserLiveBackground(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user live background images
     * @summary /v1/user/{user_id}/user_live_backgrounds
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLiveBackgroundImagesApi
     */
    public getUserLiveBackgrounds(userId: number, options?: any) {
        return UserLiveBackgroundImagesApiFp(this.configuration).getUserLiveBackgrounds(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * post background image file for live
     * @summary /v1/user/{userId}/user_live_backgrounds
     * @param {number} userId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLiveBackgroundImagesApi
     */
    public postLiveBackgroundImage(userId: number, file: any, options?: any) {
        return UserLiveBackgroundImagesApiFp(this.configuration).postLiveBackgroundImage(userId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserNotifiesApi - axios parameter creator
 * @export
 */
export const UserNotifiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifies: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_notifies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user notifies
         * @param {UserNotifies} [userNotifies] Update current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotifies: async (userNotifies?: UserNotifies, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_notifies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userNotifies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserNotifiesApi - functional programming interface
 * @export
 */
export const UserNotifiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserNotifiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotifies(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotifies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotifies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update current user notifies
         * @param {UserNotifies} [userNotifies] Update current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserNotifies(userNotifies?: UserNotifies, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotifies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserNotifies(userNotifies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserNotifiesApi - factory interface
 * @export
 */
export const UserNotifiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserNotifiesApiFp(configuration)
    return {
        /**
         * Get current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifies(options?: any): AxiosPromise<UserNotifies> {
            return localVarFp.getUserNotifies(options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user notifies
         * @param {UserNotifies} [userNotifies] Update current user notifies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotifies(userNotifies?: UserNotifies, options?: any): AxiosPromise<UserNotifies> {
            return localVarFp.updateUserNotifies(userNotifies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserNotifiesApi - object-oriented interface
 * @export
 * @class UserNotifiesApi
 * @extends {BaseAPI}
 */
export class UserNotifiesApi extends BaseAPI {
    /**
     * Get current user notifies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotifiesApi
     */
    public getUserNotifies(options?: any) {
        return UserNotifiesApiFp(this.configuration).getUserNotifies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user notifies
     * @param {UserNotifies} [userNotifies] Update current user notifies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotifiesApi
     */
    public updateUserNotifies(userNotifies?: UserNotifies, options?: any) {
        return UserNotifiesApiFp(this.configuration).updateUserNotifies(userNotifies, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserNotifySettingsApi - axios parameter creator
 * @export
 */
export const UserNotifySettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAdminCompanyUserNotifySettings: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('indexAdminCompanyUserNotifySettings', 'companyId', companyId)
            const localVarPath = `/v1/admin/companies/{companyId}/user_notify_settings`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUserNotifySettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user_notify_settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserNotifySettingsApi - functional programming interface
 * @export
 */
export const UserNotifySettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserNotifySettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexAdminCompanyUserNotifySettings(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexAdminCompanyUserNotifySettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexAdminCompanyUserNotifySettings(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexUserNotifySettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexUserNotifySettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexUserNotifySettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserNotifySettingsApi - factory interface
 * @export
 */
export const UserNotifySettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserNotifySettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexAdminCompanyUserNotifySettings(companyId: number, options?: any): AxiosPromise<IndexAdminCompanyUserNotifySettingsResponse> {
            return localVarFp.indexAdminCompanyUserNotifySettings(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUserNotifySettings(options?: any): AxiosPromise<IndexUserNotifySettingsResponse> {
            return localVarFp.indexUserNotifySettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserNotifySettingsApi - object-oriented interface
 * @export
 * @class UserNotifySettingsApi
 * @extends {BaseAPI}
 */
export class UserNotifySettingsApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotifySettingsApi
     */
    public indexAdminCompanyUserNotifySettings(companyId: number, options?: any) {
        return UserNotifySettingsApiFp(this.configuration).indexAdminCompanyUserNotifySettings(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserNotifySettingsApi
     */
    public indexUserNotifySettings(options?: any) {
        return UserNotifySettingsApiFp(this.configuration).indexUserNotifySettings(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRelationsApi - axios parameter creator
 * @export
 */
export const UserRelationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get logged in user\'s 1on1 relation
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelation: async (userId: number, relationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRelation', 'userId', userId)
            // verify required parameter 'relationId' is not null or undefined
            assertParamExists('getUserRelation', 'relationId', relationId)
            const localVarPath = `/v1/user/{userId}/user_relations/{relationId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"relationId"}}`, encodeURIComponent(String(relationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get logged in user\'s 1on1 relations
         * @summary /v1/user/{userId}/user_relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelations: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRelations', 'userId', userId)
            const localVarPath = `/v1/user/{userId}/user_relations`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Patch 1on1 relation\'s pin by owner
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {UserRelationUpdateRequest} [userRelationUpdateRequest] request body of user relation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRelation: async (userId: number, relationId: number, userRelationUpdateRequest?: UserRelationUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUserRelation', 'userId', userId)
            // verify required parameter 'relationId' is not null or undefined
            assertParamExists('patchUserRelation', 'relationId', relationId)
            const localVarPath = `/v1/user/{userId}/user_relations/{relationId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"relationId"}}`, encodeURIComponent(String(relationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRelationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRelationsApi - functional programming interface
 * @export
 */
export const UserRelationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRelationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get logged in user\'s 1on1 relation
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRelation(userId: number, relationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRelation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRelation(userId, relationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get logged in user\'s 1on1 relations
         * @summary /v1/user/{userId}/user_relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRelations(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRelationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRelations(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Patch 1on1 relation\'s pin by owner
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {UserRelationUpdateRequest} [userRelationUpdateRequest] request body of user relation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserRelation(userId: number, relationId: number, userRelationUpdateRequest?: UserRelationUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRelation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserRelation(userId, relationId, userRelationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRelationsApi - factory interface
 * @export
 */
export const UserRelationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRelationsApiFp(configuration)
    return {
        /**
         * Get logged in user\'s 1on1 relation
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelation(userId: number, relationId: number, options?: any): AxiosPromise<UserRelation> {
            return localVarFp.getUserRelation(userId, relationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get logged in user\'s 1on1 relations
         * @summary /v1/user/{userId}/user_relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRelations(userId: number, options?: any): AxiosPromise<UserRelationsResponse> {
            return localVarFp.getUserRelations(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch 1on1 relation\'s pin by owner
         * @summary /v1/user/{userId}/user_relations/{relationId}
         * @param {number} userId 
         * @param {number} relationId 
         * @param {UserRelationUpdateRequest} [userRelationUpdateRequest] request body of user relation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserRelation(userId: number, relationId: number, userRelationUpdateRequest?: UserRelationUpdateRequest, options?: any): AxiosPromise<UserRelation> {
            return localVarFp.patchUserRelation(userId, relationId, userRelationUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRelationsApi - object-oriented interface
 * @export
 * @class UserRelationsApi
 * @extends {BaseAPI}
 */
export class UserRelationsApi extends BaseAPI {
    /**
     * Get logged in user\'s 1on1 relation
     * @summary /v1/user/{userId}/user_relations/{relationId}
     * @param {number} userId 
     * @param {number} relationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRelationsApi
     */
    public getUserRelation(userId: number, relationId: number, options?: any) {
        return UserRelationsApiFp(this.configuration).getUserRelation(userId, relationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get logged in user\'s 1on1 relations
     * @summary /v1/user/{userId}/user_relations
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRelationsApi
     */
    public getUserRelations(userId: number, options?: any) {
        return UserRelationsApiFp(this.configuration).getUserRelations(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Patch 1on1 relation\'s pin by owner
     * @summary /v1/user/{userId}/user_relations/{relationId}
     * @param {number} userId 
     * @param {number} relationId 
     * @param {UserRelationUpdateRequest} [userRelationUpdateRequest] request body of user relation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRelationsApi
     */
    public patchUserRelation(userId: number, relationId: number, userRelationUpdateRequest?: UserRelationUpdateRequest, options?: any) {
        return UserRelationsApiFp(this.configuration).patchUserRelation(userId, relationId, userRelationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyUsers: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('adminCompanyUsers', 'companyId', companyId)
            const localVarPath = `/v1/admin/companies/{companyId}/users`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get User Information
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (companyId: number, userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserById', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/v1/company/{companyId}/users/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get User Import Histories
         * @summary /v1/users/{userId}/csv_import_histories
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImportHistories: async (companyId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getUserImportHistories', 'companyId', companyId)
            const localVarPath = `/v1/users/csv_import_histories`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get User Import History
         * @summary /v1/company/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImportHistory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserImportHistory', 'id', id)
            const localVarPath = `/v1/company/csv_import_history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get reset password url
         * @summary /v1/users/reset_password_url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserResetPasswordUrl: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users/reset_password_url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {UserUpdateRequest} [userUpdateRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (companyId: number, userId: number, userUpdateRequest?: UserUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('patchUser', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('patchUser', 'userId', userId)
            const localVarPath = `/v1/company/{companyId}/users/{userId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post user Information
         * @summary /v1/users/csv_import
         * @param {number} companyId 
         * @param {UserCsvPostRequest} [userCsvPostRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCsvFile: async (companyId: number, userCsvPostRequest?: UserCsvPostRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('postUserCsvFile', 'companyId', companyId)
            const localVarPath = `/v1/company/{companyId}/csv_import`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCsvPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCompanyUsers(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminCompanyUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCompanyUsers(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get User Information
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(companyId: number, userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get User Import Histories
         * @summary /v1/users/{userId}/csv_import_histories
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserImportHistories(companyId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserCsvImportHistory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserImportHistories(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get User Import History
         * @summary /v1/company/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserImportHistory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCsvImportHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserImportHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get reset password url
         * @summary /v1/users/reset_password_url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserResetPasswordUrl(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResetPasswordUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserResetPasswordUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {UserUpdateRequest} [userUpdateRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(companyId: number, userId: number, userUpdateRequest?: UserUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUser(companyId, userId, userUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post user Information
         * @summary /v1/users/csv_import
         * @param {number} companyId 
         * @param {UserCsvPostRequest} [userCsvPostRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserCsvFile(companyId: number, userCsvPostRequest?: UserCsvPostRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCsvImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserCsvFile(companyId, userCsvPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCompanyUsers(companyId: number, options?: any): AxiosPromise<AdminCompanyUsersResponse> {
            return localVarFp.adminCompanyUsers(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get User Information
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(companyId: number, userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get User Import Histories
         * @summary /v1/users/{userId}/csv_import_histories
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImportHistories(companyId: number, options?: any): AxiosPromise<Array<UserCsvImportHistory>> {
            return localVarFp.getUserImportHistories(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get User Import History
         * @summary /v1/company/csv_import_history/{id}
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImportHistory(id: number, options?: any): AxiosPromise<UserCsvImportHistory> {
            return localVarFp.getUserImportHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get reset password url
         * @summary /v1/users/reset_password_url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserResetPasswordUrl(options?: any): AxiosPromise<UserResetPasswordUrl> {
            return localVarFp.getUserResetPasswordUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexUsers(options?: any): AxiosPromise<IndexUsersResponse> {
            return localVarFp.indexUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user
         * @summary /v1/company/{companyId}/users/{userId}
         * @param {number} companyId 
         * @param {number} userId 
         * @param {UserUpdateRequest} [userUpdateRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(companyId: number, userId: number, userUpdateRequest?: UserUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchUser(companyId, userId, userUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Post user Information
         * @summary /v1/users/csv_import
         * @param {number} companyId 
         * @param {UserCsvPostRequest} [userCsvPostRequest] request body of user update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCsvFile(companyId: number, userCsvPostRequest?: UserCsvPostRequest, options?: any): AxiosPromise<UserCsvImportResponse> {
            return localVarFp.postUserCsvFile(companyId, userCsvPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public adminCompanyUsers(companyId: number, options?: any) {
        return UsersApiFp(this.configuration).adminCompanyUsers(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get User Information
     * @summary /v1/company/{companyId}/users/{userId}
     * @param {number} companyId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserById(companyId: number, userId: number, options?: any) {
        return UsersApiFp(this.configuration).getUserById(companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get User Import Histories
     * @summary /v1/users/{userId}/csv_import_histories
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserImportHistories(companyId: number, options?: any) {
        return UsersApiFp(this.configuration).getUserImportHistories(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get User Import History
     * @summary /v1/company/csv_import_history/{id}
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserImportHistory(id: number, options?: any) {
        return UsersApiFp(this.configuration).getUserImportHistory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get reset password url
     * @summary /v1/users/reset_password_url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserResetPasswordUrl(options?: any) {
        return UsersApiFp(this.configuration).getUserResetPasswordUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public indexUsers(options?: any) {
        return UsersApiFp(this.configuration).indexUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user
     * @summary /v1/company/{companyId}/users/{userId}
     * @param {number} companyId 
     * @param {number} userId 
     * @param {UserUpdateRequest} [userUpdateRequest] request body of user update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public patchUser(companyId: number, userId: number, userUpdateRequest?: UserUpdateRequest, options?: any) {
        return UsersApiFp(this.configuration).patchUser(companyId, userId, userUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post user Information
     * @summary /v1/users/csv_import
     * @param {number} companyId 
     * @param {UserCsvPostRequest} [userCsvPostRequest] request body of user update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public postUserCsvFile(companyId: number, userCsvPostRequest?: UserCsvPostRequest, options?: any) {
        return UsersApiFp(this.configuration).postUserCsvFile(companyId, userCsvPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WordcloudIgnoreTextApi - axios parameter creator
 * @export
 */
export const WordcloudIgnoreTextApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get text data to exclude wordcloud
         * @summary /v1/wordcloud_ignore_texts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIgnoreTexts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/wordcloud_ignore_texts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WordcloudIgnoreTextApi - functional programming interface
 * @export
 */
export const WordcloudIgnoreTextApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WordcloudIgnoreTextApiAxiosParamCreator(configuration)
    return {
        /**
         * Get text data to exclude wordcloud
         * @summary /v1/wordcloud_ignore_texts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIgnoreTexts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIgnoreTexts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WordcloudIgnoreTextApi - factory interface
 * @export
 */
export const WordcloudIgnoreTextApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WordcloudIgnoreTextApiFp(configuration)
    return {
        /**
         * Get text data to exclude wordcloud
         * @summary /v1/wordcloud_ignore_texts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIgnoreTexts(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getIgnoreTexts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WordcloudIgnoreTextApi - object-oriented interface
 * @export
 * @class WordcloudIgnoreTextApi
 * @extends {BaseAPI}
 */
export class WordcloudIgnoreTextApi extends BaseAPI {
    /**
     * Get text data to exclude wordcloud
     * @summary /v1/wordcloud_ignore_texts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WordcloudIgnoreTextApi
     */
    public getIgnoreTexts(options?: any) {
        return WordcloudIgnoreTextApiFp(this.configuration).getIgnoreTexts(options).then((request) => request(this.axios, this.basePath));
    }
}


